import * as d3 from "d3";
import { controller } from "../controller/controller";
import { LayerController } from "../controller/layerController";
import { DynLayer } from "../layers/DynLayer";
import { LayerBase } from "../layers/LayerBase";
import { SelectionLayer } from "../layers/SelectionLayer";
import { Rect } from "../math/Rect";
import { store } from "../store/store";
import { Tool, uiStore } from "../store/UIState";
import "./LinkAnim.css";
import { Shape, TextShape } from "../model/shape";
import { logger } from "../logging/logger";


export enum Layer {
    Dynamic = 0,
    Staic,
    Selection
}


export class BoundKonvaBoard {

    layers: LayerBase[] = []
    currentTool: Tool = uiStore.ui.tool

    transform = d3.zoomIdentity
    baseLayer: LayerBase;
    dynLayer: DynLayer;
    selLayer: SelectionLayer;
    layerController?: LayerController

    constructor(private ref: HTMLDivElement) {
        controller.setCanvasBoard(this)
        store.windowInnerHeight = window.innerHeight
        store.windowInnerWidth = window.innerWidth

        window.onorientationchange = () => {
            this.createLayers()
        }
        window.onresize = () => {
            this.createLayers()
            store.windowInnerHeight = window.innerHeight
            store.windowInnerWidth = window.innerWidth

        }
        this.baseLayer = new LayerBase(this.ref, new Rect(this.ref.getBoundingClientRect()))
        this.selLayer = new SelectionLayer(this.ref, new Rect(this.ref.getBoundingClientRect()))
        this.dynLayer = new DynLayer(this.ref, new Rect(this.ref.getBoundingClientRect()))
        this.createLayers()



    }

    zoom = d3.zoom<HTMLCanvasElement, unknown>();

    createLayers() {
        logger.info("createLayers")
        this.baseLayer.remove()
        this.selLayer.remove()
        this.dynLayer.remove()

        this.baseLayer = new LayerBase(this.ref, new Rect(this.ref.getBoundingClientRect()))
        this.selLayer = new SelectionLayer(this.ref, new Rect(this.ref.getBoundingClientRect()))
        this.dynLayer = new DynLayer(this.ref, new Rect(this.ref.getBoundingClientRect()))
        this.setLayers(this.baseLayer, this.selLayer, this.dynLayer)

        
        this.zoom = d3.zoom<HTMLCanvasElement, unknown>();

        if (uiStore.ui.tool !== Tool.Translate)
            this.zoom.filter(function () {
                switch (d3.event.type) {
                    case "mousedown": return d3.event.which === 3
                    case "wheel": return true
                    case "touchstart": return d3.event.touches.length === 2
                    case "touchmove": return d3.event.touches.length === 2
                    case "touchend": return true
                    default: return false
                }
            })
        this.zoom.on("zoom", () => {
            this.setTransform(d3.event.transform)
        }).on('end', () => {

        })


        let drag = d3.drag<HTMLCanvasElement, unknown>()
            .clickDistance(5)

            //.subject(dragsubject)
            .on("start", () => {
                let [x, y] = this.baseLayer.transform.invert([d3.event.x, d3.event.y])

                controller.layerController?.dragStart(x, y)
            })
            .on("drag", () => {
                let [x, y] = this.baseLayer.transform.invert([d3.event.x, d3.event.y])
                controller.layerController?.drag(x, y)

            })

            .on("start.render drag.render", () => {
                controller.layerController?.dragRender()
            })
            .on("end", () => {
                let [x, y] = this.baseLayer.transform.invert([d3.event.x, d3.event.y])
                controller.layerController?.dragEnd(x, y)

            })





        d3.select(this.dynLayer.canvas).call(this.zoom);
        d3.select(this.dynLayer.canvas).call(this.zoom.transform, d3.zoomIdentity.translate(this.transform.x, this.transform.y).scale(this.transform.k))
        d3.select(this.dynLayer.canvas).call(drag);


        d3.select(this.dynLayer.canvas).on("click", () => {
            if (store.boardUI.editedSticker) {
                store.boardUI.editedSticker = undefined
                store.clearEditedShape()
                this.dynLayer.hideTransformer()
                controller.layerController?.updateRenderedFigures()

                return
            }

            // if (store.editedShape) {
            //     controller.layerController?.clearSelection()
            //     return
            // }
            let [x, y] = this.baseLayer.transform.invert([d3.event.x, d3.event.y])
            controller.layerController?.click(x, y)
            // for (let layer of this.layers) {
            //     if (layer.click(x, y)) break;
            // }
            // for (let layer of this.layers) {
            //     layer.redraw()
            // }



        })

        this.baseLayer.drawGrid()
        this.baseLayer.render(store.shapes())
        this.selLayer.render(store.shapes())
        if (store.board.editedShape) {
            this.dynLayer.render([store.board.editedShape])
        }

    }

    onBoardResize = () => {
        this.onToolChange(uiStore.ui.tool)

    }

    removeLayers() {
        for (let layer of this.layers) layer.remove()
        this.layers = []
    }

    setTransform(transform: d3.ZoomTransform) {

        this.transform = transform
        store.board.layerTransform = transform
        for (let layer of this.layers) layer.transform = transform

        this.updateInputPosition()
        if (uiStore.ui.tool === Tool.Pen) {
            controller.layerController?.updateRenderedFigures()
        }

        for (let layer of this.layers) {
            layer.redraw()
        }
}


    setLayers(...layers: LayerBase[]) {
        this.removeLayers()
        this.layers = layers
        this.setTransform(this.transform)

    }

    updateInputPosition() {
        this.selLayer.updateInputPosition()
    }


    onInputChanged = (evt: Event) => {
        const elem = d3.select('#input-element').node() as HTMLInputElement
        if (store.boardUI.editedText) {
            store.boardUI.editedText.Content = elem.value
            controller.layerController?.redraw()
        }


    }

    onRemoveShape = (shape: Shape) => { for (let layer of this.layers) layer.redraw() }
    onClear = () => {
        this.onToolChange(uiStore.ui.tool)
    }

    onToolChange(tool: Tool) {
        if (this.currentTool === Tool.Translate || tool === Tool.Translate) {
            this.createLayers()
        }
        this.currentTool = tool
    }
    getViewBox = () => {
        let rect = this.ref.getBoundingClientRect()
        let p1 = { x: this.transform.invertX(0), y: this.transform.invertY(0) }
        let p2 = { x: this.transform.invertX(rect.width), y: this.transform.invertY(rect.height) }
        let rc = { x: p1.x, y: p1.y, width: p2.x - p1.x, height: p2.y - p1.y }

        return new Rect(rc)


    }
    getBoundingRect = (shape: Shape) => {
        if (this.layers.length > 0) {
            let rc = this.layers[0].getClientTextPoints(shape as TextShape)
            //rc = rc.map(point=>this.layers[0].transform.apply(point))
            return rc
        }
        return [[0, 0]]
    }

    updateLayer(layer: Layer) {
        if (layer === Layer.Dynamic)
            this.dynLayer.redraw()
        if (layer === Layer.Staic) {
            this.baseLayer.redraw()
            this.selLayer.redraw()
        }
    }


    transformer = () => { }


}