// import * as d3 from "d3";
import  { ZoomTransform } from "d3";
import * as d3 from "d3"
import { IComponent } from "ihti";
import Long from "long";
import { autorun, IReactionDisposer, makeObservable, observable, when } from "mobx";
import { Layer } from "../components/BoundKonvaBoard";
import { getFullToolbarConfig, getViewToolbarConfig, ToolbarConfig, ToolConfig } from "../components/ToolbarConfig";
import { ButtonId, tools } from "../components/Tools";
import { controller } from "../controller/controller";
import { AccessMode } from "../model/board";
import { ConnectionState } from "../model/connectionState";
import { Shape, ShapeType, StickerShape, StrokeShape, TextAreaShape, TextShape } from "../model/shape";
import { Tool } from "./UIState";

// type eventType = "render" | "position" | "finish"



export interface User {
    ID? : Long.Long 
    name : string
    group : string
}

export interface BoardStore {
    name : string
    accessMode : AccessMode
    ownerId : Long.Long

    shapes : Shape[]
    stickers: StickerShape[] ;
    highlighStrokes: StrokeShape[]
    dynamicStrokes: StrokeShape[] 
    layerTransform : ZoomTransform
    editedShape?: Shape

}

export interface BoardUIStore {
    editedText?: TextShape
    editedShape?: Shape;
    position : { width: number, height: number, transform: string };
    editedSticker?: StickerShape;

}

export interface PageStore {
    route : string 
    components : IComponent []

}


// export const DefaultStrokeParams = {
//     color: "black",
//     font: "Arial",
//     strokeWidth: 2,
//     smoothFactor: 0,
//     opacity: 0.5,
//     closedShape: false,
//     snap: false,
// };


export class Store {
    board : BoardStore = {
        name : "",
        accessMode : AccessMode.Collaboration,
        ownerId : Long.fromInt(0),
        shapes : [],
        dynamicStrokes : [],
        highlighStrokes : [],
        stickers : [],
        layerTransform : d3.zoomIdentity,
    }

    defaultStrokeParam = {
        color: "black",
        font: "Arial",
        strokeWidth: 2,
        smoothFactor: 0,
        opacity: 0.5,
        closedShape: false,
        snap: false,
    
    }

    boardUI : BoardUIStore = {
        position : {width : 0, height : 0, transform :''},
    }

    page : PageStore = {
        components : [],
        route : ""

    }

    user: User = {group : "", name : "", ID: Long.ZERO};

    windowInnerHeight = 0;
    windowInnerWidth = 0;

    toolbarConfig: ToolbarConfig;
    highlightFocusOthers = true;
    disposer?: IReactionDisposer;
    connectionState: ConnectionState = {
        BoardName : "",
        ConnectionID : 0
    };


    constructor() {
        //this.board is not observable!
        makeObservable(this, {
            user: observable,
            defaultStrokeParam : observable,
            boardUI : observable,
            page : observable,
            windowInnerHeight : observable,
            windowInnerWidth : observable,
            toolbarConfig : observable,
            highlightFocusOthers : observable,
            connectionState : observable

        });

        this.toolbarConfig = getFullToolbarConfig(true)
    }

    clear() {
        this.board.shapes = []
        // this.board.shapes = []
        // this.board.stickers = []
        this.board.stickers = []
        this.clearEditedShape()
        this.boardUI.editedSticker = undefined
        this.board.editedShape = undefined

    }



    setEditedShape(shape: Shape) {
        this.clearEditedShape()
        this.board.editedShape = shape
        this.boardUI.editedShape = shape
        this.disposer = undefined
        switch (shape.Type) {
            case ShapeType.Text:
                this.disposer = autorun(() => {
                    if (this.boardUI.editedShape) {

                        const shape = this.board.editedShape as TextShape
                        const uiShape = this.boardUI.editedShape as TextShape
                        if (shape.Content != uiShape.Content){
                            shape.Content = uiShape.Content
                            shape.Color = uiShape.Color
                            shape.Family = uiShape.Family
    
                            controller.board?.updateLayer(Layer.Dynamic)
                            controller.socketController?.updateText(shape)
                        }
                    }
                })
                controller.board?.updateInputPosition()
                break
            case ShapeType.TextArea:
                this.disposer = autorun(() => {
                    if (this.boardUI.editedShape) {
                        const shape = this.board.editedShape as TextAreaShape
                        const uiShape = this.boardUI.editedShape as TextAreaShape
                        shape.Content = uiShape.Content
                        shape.Color = uiShape.Color
                        shape.Family = uiShape.Family

                        controller.board?.updateLayer(Layer.Dynamic)
                        controller.socketController?.updateTextArea(shape)
                    }
                })
                controller.board?.updateInputPosition()
                break
            //TODO Reimplement Chart shape type
            // case "chart":
            //     this.disposer = autorun(() => {
            //         if (this.uiEditedShape) {
            //             const shape = this.editedShape as WChart
            //             const uiShape = this.uiEditedShape as WChart
            //             shape.definition = uiShape.definition
            //             shape.color = uiShape.color

            //             controller.board?.updateLayer(Layer.Dynamic)
            //             controller.socketController?.updateShape(shape)
            //         }
            //     })
            //     controller.board?.updateInputPosition()
            //     break


        }
        when(() => this.boardUI.editedShape === undefined, () => {
            if (this.disposer) this.disposer()
        })
    }

    shapes(excludeEdited = true) {
        if (excludeEdited)
            return this.board.shapes.filter(shape => shape !== this.board.editedShape)
        else {
            return this.board.shapes

        }
    }



    clearEditedShape() {
        if (this.boardUI.editedShape && this.boardUI.editedShape.Type === ShapeType.Text && this.board.editedShape) {
            if ((this.boardUI.editedShape as TextShape).Content.length === 0) {
                store.removeShape(this.board.editedShape)
            }
        }
        this.boardUI.editedShape = undefined
        this.board.editedShape = undefined

    }



    updateSticker(sticker: StickerShape) {
        let idx = this.board.stickers.findIndex(s => s.ID.equals( sticker.ID))
        if (idx > -1) this.board.stickers[idx] = sticker
        return idx
    }

    removeSticker(stickerId: Long.Long) {

        let idx = this.board.stickers.findIndex(s => s.ID.equals(stickerId))
        if (idx > -1) {
            this.board.stickers.splice(idx, 1)

        }


    }
    

    updateShape(shape: Shape) {
        if (shape.Type === ShapeType.Sticker) {
            this.updateSticker(shape as StickerShape)
        }

        let idx = this.board.shapes.findIndex(s => s.ID.equals( shape.ID))
        if (idx > -1) {
            if (store.board.editedShape && store.board.editedShape.ID.equals((this.board.shapes[idx] as Shape).ID)) {
                store.setEditedShape(shape)
                controller.board?.dynLayer.hideTransformer()
                controller.board?.dynLayer.showTransformer(shape)
                controller.layerController?.updateRenderedFigures()

            }
            this.board.shapes[idx] = shape
            //if ()
        }
    }



    userCanErase(userId: Long.Long) {
        return true
        // return (
        //     (this.boardAccessMode === AccessMode.Collaboration) ||
        //     (this.boardAccessMode === AccessMode.Tutor && store.user?.ID.equals( userId)) ||
        //     (this.board.OwnerId === store.user?.ID)
        // )
    }


    updateToolbarConfig() {
        // const isOwner = store.user?.ID.equals( this.boardOwnerId)
        //FIXME Check board owner
        if (true)
            this.toolbarConfig = getFullToolbarConfig(true)
        else {
            switch (store.board.accessMode) {
                case AccessMode.Collaboration:
                case AccessMode.Tutor:
                    {
                        store.toolbarConfig = getFullToolbarConfig(false)
                        break
                    }

                case AccessMode.Presentation:
                    {
                        store.toolbarConfig = getViewToolbarConfig()
                        controller.setTool((tools.get(ButtonId.TranslateTool) as ToolConfig).tool as Tool)
                        break

                    }
            }
        }
    }


    addShape(shape: Shape) {
        this.board.shapes.push(shape)
    }

    getShape(shapeID: Long.Long) {
        let idx = this.board.shapes.findIndex(s => s.ID .equals( shapeID))
        return store.board.shapes[idx]
    }

    removeShape(shape: Shape) {
        this.board.shapes = this.board.shapes.filter(s => s !== shape)
        if (this.board.editedShape?.ID.equals(shape.ID)) {
            this.boardUI.editedShape = undefined
            this.board.editedShape = undefined
        }
    }


}
export const store = new Store()