import { controller } from "../controller/controller";
import { store } from "./store";
import { WImage } from "../model/WImage";
import { Shape, ShapeType, ImageShape, StickerShape } from "../model/shape";
import Long from "long"
export enum ActionType {
    Create = 0,
    Remove,
    Update
}

export enum ObjectType {
    Undefine = 0,
    Shape,
    Sticker
}


function copyShape(shape: Shape): Shape {
    let newShape: Shape = { ...shape, transform: { ...shape.transform } }
    if (newShape.Type === ShapeType.Image) {
        const image = new WImage(newShape as ImageShape, (shape as WImage).htmlImg)
        image.Loaded = (shape as WImage).Loaded
        newShape = image
    }
    //FIXME Add chart support
    return newShape
}

function copySticker(sticker: StickerShape): StickerShape {
    const newSticker = { ...sticker, }
    return newSticker
}



export class WAction {
    constructor(public objectType: ObjectType, public actionType: ActionType) { }
}

export class ShapeAction extends WAction {
    constructor(actionType: ActionType, public before?: Shape, public after?: Shape) {
        super(ObjectType.Shape, actionType)
    }
}

export class StickerAction extends WAction {
    constructor(actionType: ActionType, public before?: StickerShape, public after?: StickerShape) {
        super(ObjectType.Sticker, actionType)
    }
}



export class UndoRedo {
    clear() {
        this._redo = []
        this._undo = []
    }
    _undo: WAction[] = []
    _redo: WAction[] = []
    sticker?: StickerShape
    shape?: Shape

    addUndo(action: WAction) {
        this._undo.push(action)
        this._redo = []
    }


    addShape(shape: Shape) {
        this.addUndo(new ShapeAction(ActionType.Create, undefined, shape))

    }
    removeShape(shape: Shape) {

        this.addUndo(new ShapeAction(ActionType.Remove, shape, undefined))
    }

    beginUpdateShape(shape: Shape) {
        this.shape = copyShape(shape)
    }

    endUpdateShape(shape: Shape) {
        if (this.shape) {
            this.addUndo(new ShapeAction(ActionType.Update, this.shape, copyShape(shape)))
        }
        this.shape = undefined
    }

    addSticker(sticker: StickerShape) {
        this.addUndo(new StickerAction(ActionType.Create, undefined, sticker))
    }
    removeSticker(sticker: StickerShape) {
        this.addUndo(new StickerAction(ActionType.Remove, sticker, undefined))
    }



    beginUpdateSticker(sticker: StickerShape) {
        this.sticker = copySticker(sticker)
    }

    endUpdateSticker(sticker: StickerShape) {
        if (this.sticker) {
            this.addUndo(new StickerAction(ActionType.Update, this.sticker, copySticker(sticker)))
        }
        this.sticker = undefined

    }

    undo() {
        if (this._undo.length > 0) {
            const action = this._undo.pop() as WAction
            if (action.objectType === ObjectType.Shape) this.undoShapeAction(action as ShapeAction)
            if (action.objectType === ObjectType.Sticker) this.undoStickerAction(action as StickerAction)
            this._redo.push(action)
        }
    }


    redo() {
        if (this._redo.length > 0) {
            const action = this._redo.pop() as WAction
            if (action.objectType === ObjectType.Shape) this.redoShapeAction(action as ShapeAction)
            if (action.objectType === ObjectType.Sticker) this.redoStickerAction(action as StickerAction)
            this._undo.push(action)

        }

    }
    redoStickerAction(action: StickerAction) {
        switch (action.actionType) {
            case ActionType.Create: {
                store.board.stickers.push(action.after as StickerShape)
                const sticker = action.after as StickerShape
                controller.socketController.addSticker(sticker, (id) => { sticker.ID = id })
                // controller.updateSelection()
                break
            }
            case ActionType.Remove: {

                store.removeSticker(action.before?.ID || Long.ZERO)
                controller.socketController.removeShape(action.before?.ID || Long.ZERO, "sticker")
                // controller.updateSelection()

                break

            }
            case ActionType.Update: {
                store.updateSticker(action.after as StickerShape)
                controller.socketController.updateShape(action.after as StickerShape)
                // controller.updateSelection()
                break
            }


        }
    }
    redoShapeAction(action: ShapeAction) {
        switch (action.actionType) {
            case ActionType.Create: {
                store.board.shapes.push(action.after as Shape)
                const shape = action.after as Shape
                controller.socketController.addShape(shape, id => shape.ID = id)
                controller.layerController?.updateRenderedFigures()
                break
            }
            case ActionType.Remove: {
                store.removeShape(action.before as Shape)
                const shape = action.before as Shape
                controller.socketController.removeShape(shape.ID, shape.Type)
                controller.layerController?.updateRenderedFigures()

                break

            }
            case ActionType.Update: {
                controller.updateShape(action.after as Shape)

                controller.socketController?.updateShape(action.after as Shape)
                controller.layerController?.updateRenderedFigures()
                break
            }


        }

    }


    undoStickerAction(action: StickerAction) {
        switch (action.actionType) {
            case ActionType.Create: {
                store.removeSticker(action.after?.ID || Long.ZERO)
                controller.socketController.removeShape(action.after?.ID || Long.ZERO, "sticker")
                // controller.updateSelection()
                break
            }
            case ActionType.Remove: {
                store.board.stickers.push(action.before as StickerShape)
                const sticker = action.before as StickerShape
                controller.socketController.addSticker(sticker, (id) => sticker.ID = id)

                break
            }

            case ActionType.Update: {
                store.updateSticker(action.before as StickerShape)
                controller.socketController.updateShape(action.before as StickerShape)
                // controller.updateSelection()

                break
            }

        }
    }
    undoShapeAction(action: ShapeAction) {
        switch (action.actionType) {
            case ActionType.Create: {
                const shape = action.after as Shape

                store.removeShape(shape)

                controller.socketController.removeShape(shape.ID, shape.Type)
                controller.layerController?.updateRenderedFigures()
                break
            }
            case ActionType.Remove: {
                const shape = action.before as Shape
                store.board.shapes.push(shape)
                controller.socketController.addShape(shape, id => shape.ID = id)
                controller.layerController?.updateRenderedFigures()

                break
            }

            case ActionType.Update: {
                controller.updateShape(action.before as Shape)
                //store.updateShape(action.before as Shape)
                controller.socketController?.updateShape(action.before as Shape)

                break
            }

        }

    }



}

export const undoredo = new UndoRedo()