import { action, makeAutoObservable, makeObservable, observable } from "mobx"
import { wjite } from "../proto/request"

const RECONNECT_TIMEOUT = 1000

export class SocketConnection {
    connectListeners: (() => void)[] = []
    disconnectListeners: (() => void)[] = []
    messageListeners: ((msg: wjite.message.IToClient) => void)[] = []

    websocket?: WebSocket
    connected = false
    connectInterval?: number

    constructor() {
        console.log("New connection")
        makeObservable(this, {
            connected:observable
        })

    }
    reconnect() {
        this.requestConnection()
    }

    @action
    setConnected(connected: boolean) {
        console.log("SetConnected", connected)
        this.connected = connected
    }

    requestConnection() {
        console.log("SocketConnection::requestConnection")
        if (this.websocket) return
        //export const WSOCKET_URL = 
        let url = "wss://o-whiteboard.com/ws"
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            url = "ws://localhost:8080/ws"
        }


        this.websocket = new WebSocket(url)
        this.websocket.binaryType = "arraybuffer"
        this.websocket.addEventListener("open", () => {
            console.log("Websocket open")
            this.setConnected(true);
            if (this.connectInterval) { clearInterval(this.connectInterval); this.connectInterval = undefined }
            this.connectListeners.forEach(callback => { callback() });
        })

        this.websocket.addEventListener("close", () => {
            console.log("Websocket close")

            this.websocket = undefined
            this.setConnected(false)
            this.disconnectListeners.forEach(callback => { callback() });
            this.connectInterval = setTimeout(() => { this.reconnect() }, RECONNECT_TIMEOUT)
        })

        this.websocket.addEventListener("error", (err) => {
            console.log("Websocket error")
        })

        this.websocket.addEventListener("message", (event) => {
            const clientMessage = wjite.message.ToClient.decode(new Uint8Array(event.data as ArrayBuffer))
            this.messageListeners.forEach(callback => callback(clientMessage))
        })
    }

    disconnect() {
        console.log("Disconnecting web socket")
        this.connected = false
        this.websocket?.close()
        this.connectInterval && clearInterval(this.connectInterval)
    }

    send(msg: wjite.message.IToServer) {
        if (this.websocket)
            this.websocket.send(wjite.message.ToServer.encode(msg).finish())
        else {
            throw new Error("Sending with invalid connection state")
        }
    }

    addEventListener(event: "connect" | "disconnect", listener: () => void) {
        if (event === "connect")
            this.connectListeners.push(listener)
        else if (event === "disconnect")
            this.disconnectListeners.push(listener)
    }

    addMessageListener(listener: (msg: wjite.message.IToClient) => void) {
        this.messageListeners.push(listener)
    }
}

export const socketConnection = new SocketConnection()