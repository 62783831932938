export class Rect {
    x: number
    y: number
    width: number
    height: number
    constructor(rect: { x: number, y: number, width: number, height: number }) {
        this.x = rect.x
        this.y = rect.y
        this.width = rect.width
        this.height = rect.height
    }
    inside(p: { x: number, y: number }): boolean {
        return ((p.x >= this.x) && (p.x <= this.x + this.width) && (p.y >= this.y) && (p.y <= this.y + this.height));
    }

    points() : [number, number][] {
        return [
            [this.x, this.y],
            [this.x + this.width, this.y],
            [this.x + this.width, this.y + this.height],
            [this.x, this.y + this.height]
        ]
    }

    extend(l  : number, t : number, r : number, b : number) {
        return new Rect({ x: this.x-l, y : this.y-l, width : this.width + r + l, height : this.height + t + b})

    }

}


export function getBoundingBox(points: number[][]): Rect {
    let x0 = points[0][0]
    let y0 = points[0][1]
    let x1 = points[0][0]
    let y1 = points[0][1]
    for (let point of points) {
        if (point[0] < x0) x0 = point[0]
        if (point[0] > x1) x1 = point[0]
        if (point[1] < y0) y0 = point[1]
        if (point[1] > y1) y1 = point[1]
    }

    return new Rect({ x: x0, y: y0, width: x1 - x0, height: y1 - y0 })


}
