import { observable, makeObservable } from "mobx";
import { CodeComponent } from "./ui/def";
import { StickerShape, StickerContentType, ShapeType, Transform } from "./shape";

export class CodeSticker implements StickerShape {
    ID: Long.Long
    //timestamp: Date
    transform: Transform
    Width: number;
    Height: number;
    Content: string;
    StickerContentType: StickerContentType.Code
    FontSize: number;
    Color: string;
    Type: ShapeType.Sticker;
    OwnerID : Long.Long
    result?: CodeComponent;
    error?: string;

    constructor(sticker: StickerShape) {
        makeObservable(this, {
            result: observable,
            error: observable
        });

        this.ID = sticker.ID
        this.OwnerID = sticker.OwnerID
        this.transform = sticker.transform
        this.Width = sticker.Width
        this.Height = sticker.Height
        this.Content = sticker.Content
        this.FontSize = sticker.FontSize
        this.Color = sticker.Color
        this.Type = sticker.Type
        this.StickerContentType = StickerContentType.Code
    }

    static split(content: string) {
        const definitions = content.split('!!!/')
        return definitions.map((definition, idx) => {
            if (idx === 0) return { path: "/", definition }
            else {
                const idx = definition.indexOf('\n')
                return { path: definition.substr(0, idx), definition: definition.substr(idx + 1) }
            }
        })

    }

    static join(pathDefinitions: { path: string, definition: string }[]) {
        const content = pathDefinitions.map((pathDefinition, idx) => {
            if (idx > 0) { return "!!!/" + pathDefinition.path + "\n" + pathDefinition.definition }
            else return pathDefinition.definition
        }).join("\n")
        return content
    }


}