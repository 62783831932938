import { Tool } from "../store/UIState";
import { ButtonId } from "./Tools";

export interface ToolConfig {
    tool?: Tool
    iconElement: JSX.Element
    textElement: JSX.Element
    onClick: () => void
}

export interface ToolLineConfig {
    buttonIds: ButtonId[]
    currentButtonId : ButtonId
    selectable?: boolean
}

export function createToolLine(tools : ButtonId[], selectable = true) : ToolLineConfig {
    return {
        buttonIds: tools, 
        currentButtonId : tools[0], 
        selectable
    }
} 

export interface ToolbarConfig {
    toolLines: ToolLineConfig[]
}


export function getViewToolbarConfig(): ToolbarConfig {
    return {
        toolLines: [
            createToolLine([ButtonId.TranslateTool],)
        ]
    }
}

export function getFullToolbarConfig(isOwner: boolean): ToolbarConfig {
    let height = window.innerHeight;
    if (height > 630) {
        return {
            toolLines: [
                createToolLine([ButtonId.SelectTool, ButtonId.HighlightTool]),
                createToolLine([ButtonId.TranslateTool]),
                createToolLine([ButtonId.PenTool, ButtonId.MarkerTool]),
                createToolLine([ButtonId.MultilineTool, ButtonId.SegmentTool, ButtonId.EllipseTool, ButtonId.RectagleTool]),
                createToolLine([ButtonId.EraseTool]),
                createToolLine([ButtonId.ImageTool]),
                createToolLine([ButtonId.TextTool, ButtonId.TextAreaTool]),
                createToolLine([ButtonId.UndoTool, ButtonId.RedoTool]),
                createToolLine([ButtonId.ClearTool]),
                createToolLine([ButtonId.ShareTool]),
            ]
        }
    } else {
        return {
            toolLines: [
                createToolLine([ButtonId.SelectTool, ButtonId.TranslateTool]),
                createToolLine([ButtonId.PenTool, ButtonId.MarkerTool, ButtonId.MultilineTool, ButtonId.SegmentTool, ButtonId.EllipseTool, ButtonId.RectagleTool]),
                createToolLine([ButtonId.EraseTool]),
                createToolLine( [ButtonId.ImageTool, ButtonId.TextTool, ButtonId.TextAreaTool, ButtonId.TextStickerTool, ButtonId.VideoStickerTool]),
                createToolLine([ButtonId.OptsTool, ButtonId.UndoTool, ButtonId.RedoTool, ButtonId.ClearTool, ButtonId.ShareTool])
            ]
        }
    }
}

