import * as d3 from "d3"
import Long from "long"
import ReactGA from "react-ga"
import { BoundKonvaBoard } from "../components/BoundKonvaBoard"
import { WChart } from "../helpers/WChart"
import { logger } from "../logging/logger"
import { getBoundingBox } from "../math/Rect"
import { AccessMode } from "../model/board"
import { ChartShape, ImageShape, Shape, ShapeType, StickerShape, StrokeShape } from "../model/shape"
import { WImage } from "../model/WImage"
import { store } from "../store/store"
import { Tool, uiStore } from '../store/UIState'
import { undoredo } from "../store/undoredo"
import { LayerController } from "./layerController"
import { SocketController } from './socketController'


export class Controller {
    board?: BoundKonvaBoard
    layerController?: LayerController
    stickerTransofrm = d3.zoomIdentity
    socketController: SocketController

    constructor() {
        this.socketController = new SocketController(this)
    }

    connect() {
        this.socketController.connect()
        
    }


    setBoardName(name?: string) {
        //this.socketController.setBoardName(name)
        store.connectionState.BoardName = name || ""
        // uiStore.boardName = name
    }

    setCanvasBoard(board?: BoundKonvaBoard) {
        // this.socketController.setBoardName(board?.boardName)
        this.board = board
        // uiStore.boardName = board?.boardName
        if (board)
            this.layerController = new LayerController(board)
        // if (uiStore.boardName)
        //     this.socketController.requestBoardContent(uiStore.boardName)
    }

    

    addHighlight(stroke: StrokeShape, focus : boolean) {
    // addHighlight(stroke: StrokeShape, transform?: { k: number, x: number, y: number }) {
        store.board.highlighStrokes.push(stroke)
        this.layerController?.renderHighlight()
        if (focus) {
            const bb = getBoundingBox(stroke.PointArray)
            const width = store.windowInnerWidth
            const height = store.windowInnerHeight
            const x0 = bb.x
            const x1 = bb.x + bb.width
            const y0 = bb.y
            const y1 = bb.y + bb.height
            const t = d3.zoomIdentity
                .translate(width / 2, height / 2)
                .scale(Math.min(8, 0.6 / Math.max((x1 - x0) / width, (y1 - y0) / height)))
                .translate(-(x0 + x1) / 2, -(y0 + y1) / 2);
            this.layerController?.setTransform(t)

        }
        // }
    }

    setAccessMode(accessMode: AccessMode) {
        store.board.accessMode = accessMode
        store.updateToolbarConfig()
    }


    // newMessage(msg: Message) {
    //     uiStore.ui.messages.push(msg)
    //     this.socketController.pushMessage(msg)

    // }

    closeAllPopup() {
        uiStore.ui.openToolLineIdx = undefined
        uiStore.ui.showChat = false
        uiStore.ui.showClearDialog = false
        uiStore.ui.showShareDlg = false

    }


    notifyUpdateUser() {
        if (store.user) {
            // this.socketController.updateUser(store.user)
        }
    }

    addNewImage(file: File | Blob, img: HTMLImageElement) {
        ReactGA.event({
            category: 'Image',
            action: 'Add image',
        });
        let rect = (this.board as BoundKonvaBoard).getViewBox()

        let sx = (rect.width / 3) / img.width
        let sy = (rect.height / 3) / img.height
        let s = Math.min(sx, sy)

        let transform = { sx: s, sy: s, tx: rect.x + rect.width / 3, ty: rect.y + rect.height / 3, r: 0 };
        let image: ImageShape = {
            // userId: uiStore.user?.id || 0,
            OwnerID: store.user?.ID || Long.NEG_ONE,
            ID: Long.NEG_ONE,

            Src: "",
            transform: transform,
            Type: ShapeType.Image,
            // timestamp: new Date()
        }

        const newImage: WImage = new WImage(image, img)


        store.board.shapes.push(newImage)


        this.layerController?.updateRenderedFigures()

        this.socketController.addImage(image, file, (id, src) => {
            newImage.ID = id
            newImage.Src = src
            undoredo.addShape(newImage)
        })






    }


    clear() {
        store.clear()
        this.socketController.clear()
        undoredo.clear()
        this.layerController?.updateRenderedFigures()
    }


    setBoard = (ownerId : Long, shapes: Shape[], stickers: StickerShape[]) => {
        logger.info({ message: "Controller::setBoard", data: { Nshapes: shapes.length } })
        store.board.accessMode = AccessMode.Collaboration 
        store.board.ownerId = ownerId
        store.board.shapes = shapes.map(shape => {

            if (shape.Type === ShapeType.Image) {
                const wimage = new WImage(shape as ImageShape)
                wimage.load().then(() => {
                    this.layerController?.updateRenderedFigures()

                })
                return wimage
            } else if (shape.Type === ShapeType.Chart) {
                const wchart = new WChart(shape as ChartShape)
                wchart.calcDefinition()
                return wchart
            } else {
                return shape
            }
        })

        store.board.stickers = stickers

        this.layerController?.updateRenderedFigures()
        store.updateToolbarConfig()

        // rpcController.connect()

    }


    init() {
        logger.info({ message: "Controller::init" })


    }






    setTool(tool: Tool) {
        logger.info({ message: "Controller::setTool", data: { Tool: tool } })

        if (uiStore.ui.tool === Tool.Pen) this.layerController?.updateRenderedFigures()



        uiStore.ui.tool = tool
        store.boardUI.editedSticker = undefined
        this.board?.onToolChange(tool)

    }

    setEditedShape(shape: Shape) {
    }


    deleteShape(shape: Shape) {
        logger.info({ message: "Controller::deleteShape", data: { ID: shape.ID } })

        //FIXME Add access wrigihts check
        // if (!store.userCanErase(shape.userId)) return false
        store.removeShape(shape)
        controller.socketController.removeShape(shape.ID, shape.Type)
        undoredo.removeShape(shape)
        this.layerController?.setSelection(undefined)
        this.layerController?.updateRenderedFigures()
        return true

    }

    updateShape(shape: Shape) {
        logger.info({ message: "Controller::updateShape", data: { ID: shape.ID } })
        store.updateShape(shape)
        this.layerController?.updateShape(shape)
    }

    updateImage(image: ImageShape) {
        const imageShape = store.getShape(image.ID)
        imageShape.transform = image.transform
        this.layerController?.updateShape(imageShape)
    }

}

export const controller = new Controller()


