import winston from 'winston'
const { combine, timestamp, label, printf } = winston.format
const myFormat = printf(({ level, message, timestamp, data }) => {
    if (data)
    // return `${timestamp}: ${message}\n${JSON.stringify(data,  undefined, 2)}`;
        return `== ${message}\n${JSON.stringify(data,  undefined, 2)}\n`;
    else
        return `== ${message}`;

});


export const logger = winston.createLogger(
    {
        format: combine(
            label({ label: 'right meow!' }),
            timestamp(),
            myFormat
        ),
        transports: [new winston.transports.Console()]
    }
)