import { Trans } from "@lingui/macro";
import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React, { ChangeEvent, createRef } from "react";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";
import ReactGA from "react-ga";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import { controller } from "../controller/controller";
import { LayerType } from "../controller/layerController";
import { WChart } from "../helpers/WChart";
import { ShapeType, TextAreaShape, TextShape } from "../model/shape";
import { store } from "../store/store";
import { uiStore } from "../store/UIState";
import { BoardComponent } from "./BoardComponent";
import { LineEdit } from "./LineEdit";
import { TextAreaEdit } from "./TextAreaEdit";
import { ToolComponent } from "./ToolComponent";





export const WorkingRegion = styled.div`
    position : absolute;
    left : 0px;
    width: 100%;
    height : 100%;
`

export const ToolbarRegion = styled.div`
    position : absolute;
    
    left : 0px;
    top : 30px;
    width: 50px;
    
`
export const ToolparamRegions = styled.div`
    position : absolute;
    top : 0px;
    left : 50px;
    z-index:1;

`

export const PositionButton = styled.div<{ x: number, y: number }>`
    position : absolute;
    top : ${props => props.y}px;
    left : ${props => props.x}px;
    z-index:1;

`


type BoardParam = {
    boardName: string; // parameters will always be a string (even if they are numerical)
};
type BoardParamProps = RouteComponentProps<BoardParam>;


export const _BoardApp = observer(class _BoardApp extends React.Component<BoardParamProps, {}> {
    ref: HTMLDivElement | null = null
    rect?: DOMRect;
    constructor(props: any) {
        super(props);

        makeObservable(this, {
            rect: observable
        });



        store.board.name = props.match.params.boardName
        //uiStore.boardName = store.board.name
        controller.connect()


        ReactGA.pageview("/board/" + props.match.params.boardName);


        window.addEventListener("resize", () => {
            this.rect = this.ref?.getBoundingClientRect()
            store.updateToolbarConfig()
        })

        window.addEventListener('orientationchange', () => {
            this.rect = this.ref?.getBoundingClientRect()
            store.updateToolbarConfig()

        })
    }

    componentDidMount() {
        if (this.ref) this.rect = this.ref.getBoundingClientRect()
    }


    onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            let errs: string[] = []
            const file = e.target.files[0]



            const types = ['image/png', 'image/jpeg', 'image/gif']

            // #2 Catching wrong file types on the client
            if (types.every(type => file.type !== type)) {
                errs.push(`'${file.type}' is not a supported format`)
            }

            // #3 Catching files that are too large on the client
            if (file.size > 1500000) {
                errs.push(`'${file.name}' is too large, please pick a smaller file`)
            }

            var img = new Image();

            img.onload = () => {
                controller.addNewImage(file, img)

            }

            img.src = URL.createObjectURL(e.target.files[0]);
            if (this.inputRef.current)
                this.inputRef.current.value = ''
        }
    }

    inputRef = createRef<HTMLInputElement>();


    render() {
        let ta: TextAreaShape | undefined = undefined;
        let ts: TextShape | undefined = undefined
        let chart: WChart | undefined = undefined
        if (store.boardUI.editedShape && store.boardUI.editedShape.Type === ShapeType.TextArea) {
            ta = store.boardUI.editedShape as TextAreaShape
        }
        if (store.boardUI.editedShape && store.boardUI.editedShape.Type === ShapeType.Text) {
            ts = store.boardUI.editedShape as TextShape
        }

        if (store.boardUI.editedShape && store.boardUI.editedShape.Type === ShapeType.Chart) {
            const st = store
            chart = store.boardUI.editedShape as WChart
        }

        return <div className="position-absolute w-100 h-100">

            <Modal
                show={uiStore.ui.showClearDialog}

                onHide={() => uiStore.ui.showClearDialog = false}

            >

                <Modal.Header closeButton>
                    <Modal.Title>
                        <Trans>Remove all?</Trans>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button onClick={() => { controller.clear(); uiStore.ui.showClearDialog = false; }}><Trans>Yes</Trans></Button><Button onClick={() => { uiStore.ui.showClearDialog = false; }}><Trans>No</Trans></Button>
                </Modal.Body>
            </Modal>



            <BoardComponent />
            {/* {socketConnection.connected ? <WorkingRegion onClick={(event) => { controller.closeAllPopup() }}><BoardComponent /></WorkingRegion>  : <div style={{padding:"120px"}}><Alert variant={"primary"}>Connecting</Alert></div>}   */}
            <ToolComponent />
            <input ref={this.inputRef} id="imageUploadPlaceholder" type="file" style={{ display: "none" }} onChange={this.onChange} />
            {ts && <LineEdit
                width={store.boardUI.position.width}
                height={store.boardUI.position.height}
                transform={store.boardUI.position.transform}
                fontSize={ts.Size}
                value={ts.Content}
                shape={ts}
                onChange={(value) => {
                    if (ts) ts.Content = value
                    controller.board?.updateInputPosition()
                    controller.board?.dynLayer.updateTransformerRect()
                    controller.layerController?.redraw([LayerType.Dynamic])
                    //                    controller.updateSelection()

                }}
            />}
            {ta &&
                <TextAreaEdit
                    width={store.boardUI.position.width}
                    height={store.boardUI.position.height}
                    transform={store.boardUI.position.transform}
                    fontSize={ta.Size}
                    value={ta.Content}
                    shape={ta}
                    onChange={(value) => {
                        if (ta)
                            ta.Content = value;
                    }}
                />}




        </div>
        // return <DivHorizontal className="App" ref={(ref) => { this.ref = ref }} >
        //     <ToolbarRegion><Toolbar toolbar={store.toolbarConfig} /></ToolbarRegion>
        //     <ToolparamRegions><Toolparam /></ToolparamRegions>
        //     




        //     
        //     {this.rect && !uiStore.ui.showChat && <PositionButton x={this.rect.x + this.rect.width - 60} y={this.rect.y + 10} onClick={() => { uiStore.ui.showChat = true }}><FontAwesomeIcon icon={faUsers} size="2x" /></PositionButton>}


        //     {chart && <ChartEdit
        //             width={store.position.width}
        //             height={store.position.height}
        //             transform={store.position.transform}
        //             chart={chart}
        //     />}

        // </DivHorizontal>
    }

});

export const BoardApp = withRouter(_BoardApp)