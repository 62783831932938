import { observer } from "mobx-react"
import React, { useState, useRef, useEffect } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Form from "react-bootstrap/Form"
import Alert from "react-bootstrap/Alert"

import { ControlledEditor } from '@monaco-editor/react';

import { controller } from "../../controller/controller"
// import { rpcController } from "../../controller/rpcController"
import { CodeSticker } from "../../model/CodeSticker"
import { CodeComponent } from "../../model/ui/def"
import { store } from "../../store/store"
import { Input, Btn } from '../../model/ui/def'
import { inputCSS } from "react-select/src/components/Input"
import Dropdown from "react-bootstrap/Dropdown"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import InputGroup from "react-bootstrap/InputGroup"
import FormControl from "react-bootstrap/FormControl"



const Editor: React.FC<{ definition: string, onChange: (definition: string) => void }> = (props) => {
    const [definition, setDefinition] = useState(props.definition)

    useEffect(()=> {
        setDefinition(props.definition)
    }, [props.definition])

    return <><div style={{ flexGrow: 2 }}>
        <ControlledEditor

            value={definition}
            onChange={(ev, value) => {
                if (value) {
                    setDefinition(value)
                }

            }}

            language="python"
        />
    </div>
    <Container><Row>
        <Button onClick={()=>props.onChange(definition)}>Save</Button>
        </Row></Container>
    </>

}

const PathDefinitions: React.FC<{ sticker: CodeSticker }> = (props) => {
    const [code, setCode] = useState(props.sticker.Content);
    // const [pathDefinitions, setPathDefinitions] = useState(CodeSticker.split(props.sticker.content))
    const pathDefinitions = CodeSticker.split(code)
    const [pathIdx, setPathIdx] = useState(0)
    const [path, setPath] = useState("")

    const [show, setShow] = useState(false);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const addPath = () => {
        pathDefinitions.push({ path, definition: "" })
        setCode(CodeSticker.join(pathDefinitions))
        setPathIdx(pathDefinitions.length - 1)
        setShow(false)

    }
    return <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Container>
            <Row>
                <Col>
                    <Dropdown>
                        <Dropdown.Toggle id="codeEditorPath">{pathDefinitions[pathIdx].path}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {pathDefinitions.map((item, idx) => <Dropdown.Item key={item.path} onClick={() => {
                                setPathIdx(idx)
                            }}>{item.path}</Dropdown.Item>)}

                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col>
                    <Button onClick={handleShow}>Add</Button>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add path</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="code-path-input">New component path</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            placeholder="Path"
                            aria-label="Path"
                            aria-describedby="code-path-input"
                            value={path}
                            onChange={evt => setPath(evt.target.value)}
                        />
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
          </Button>
                    <Button variant="primary" onClick={addPath}>
                        Add path
          </Button>
                </Modal.Footer>
            </Modal>
        </Container>
        <Editor definition={pathDefinitions[pathIdx].definition} onChange={(definition) => { 
            pathDefinitions[pathIdx].definition = definition
            const code = CodeSticker.join(pathDefinitions)
            setCode(code)
            props.sticker.Content = code
            controller.socketController.updateShape(props.sticker)
        }} />

    </div>
}


const ButtonControl: React.FC<{ button: Btn, onClick: () => void }> = (props) => {
    return <Button onClick={props.onClick}>{props.button.value}</Button>
}

const InputControl: React.FC<{ input: Input, onChange: (value: string) => void }> = (props) => {
    const [value, setValue] = useState(props.input.value || "")

    const ref = useRef<HTMLInputElement>(null)
    useEffect(() => {
        if (props.input.focused) ref.current?.focus()
    })
    let style = {}
    if (props.input.style) style = props.input.style
    return <Form.Control ref={ref} placeholder="Result" onChange={evt => setValue(evt.target.value)} value={value} onKeyPress={(evt: React.KeyboardEvent<HTMLInputElement>) => {
        if (evt.key === "Enter") {
            props.onChange(value)
        }


    }} style={{ backgroundColor: props.input.style?.backgroundColor || "white" }} />
}


const Renderer: React.FC<{ component?: CodeComponent, onChange: (elementId: string, event: string, value: string) => void
}> = observer((props) => {
    if (props.component) {
        switch (props.component.ctype) {
            case "p":
                return <p className="text-center">{props.component.text}</p>
            case "container":
                return <Container>{props.component.children && props.component.children.map((comp, idx) => <Renderer key={idx} component={comp} onChange={props.onChange} />)}</Container>
            case "row":
                return <Row>{props.component.children && props.component.children.map((comp, idx) => <Renderer key={idx} component={comp} onChange={props.onChange} />)}</Row>
            case "col":
                return <Col>{props.component.children && props.component.children.map((comp, idx) => <Renderer key={idx} component={comp} onChange={props.onChange} />)}</Col>
            case "input":
                const inputComponent = props.component
                return <InputControl input={inputComponent} onChange={(value) => props.onChange(inputComponent.uid || "", "change", value)} />
            case "button":
                const btn = props.component
                return <ButtonControl button={btn} onClick={()=>{
                    props.onChange(btn.uid || "", "click", "")
                }
                }/>
            default:
                return <h1>Component not implemented</h1>

        }

    } else {
        return <h1>Unevaluated</h1>
    }
})

const Viewer: React.FC<{ sticker: CodeSticker }> = observer((props) => {
    if (props.sticker.error) {
        return <Alert variant="danger">
            <Alert.Heading>Error in code execution</Alert.Heading>
            <pre>
            {props.sticker.error}
            </pre>
        </Alert>
    } else
        return <Renderer component={props.sticker.result} onChange={(elementId: string, event: string, value: string) => {
            // rpcController.update(props.sticker, elementId, event, value)

        }} />
})
export const StickerCode: React.FC<{ sticker: CodeSticker }> = observer((props) => {
    const callSticker = async () => {
        // await rpcController.init(props.sticker)
        // await rpcController.mount(props.sticker)

    }
    if (store.boardUI.editedSticker === props.sticker) {
        return <PathDefinitions sticker={props.sticker} />
    } else {
        callSticker()
        return <Viewer sticker={props.sticker} />
    }
})