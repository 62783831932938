import { Matrix, matrix, multiply, norm } from "mathjs";
import { Transform } from "../model/shape";


export function applyMatrixTransform(ctx: CanvasRenderingContext2D, m: Matrix) {
    ctx.transform(m.get([0, 0]), m.get([1, 0]), m.get([0, 1]), m.get([1, 1]), m.get([0, 2]), m.get([1, 2]))
}
export function applyTransform(ctx: CanvasRenderingContext2D, t: Transform) {
    const m = AffineTransform.transform2Matrix(t);
    applyMatrixTransform(ctx, m);
}

export function matrix2css(m: Matrix) {
    return `matrix(${m.get([0, 0])},${m.get([1, 0])}, ${m.get([0, 1])}, ${m.get([1, 1])}, ${m.get([0, 2])}, ${m.get([1, 2])})`
}


export function transform2css(t: Transform) {
    return matrix2css(AffineTransform.transform2Matrix(t))
}


export class AffineTransform {
    constructor(public transform: Transform) { }
    static translate(tx: number, ty: number) {
        return matrix([[1, 0, tx], [0, 1, ty], [0, 0, 1]])
    }
    static scale(sx: number, sy: number) {
        return matrix([[sx, 0, 0], [0, sy, 0], [0, 0, 1]])
    }
    static rotate(r: number) {
        return matrix([[Math.cos(r), -Math.sin(r), 0], [Math.sin(r), Math.cos(r), 0], [0, 0, 1]])
    }
    static multiply(m0: Matrix, ...m: Matrix[]) {
        m.splice(0, 0, m0);
        let r = m.pop() as Matrix
        while (m.length > 0) {
            let m1 = m.pop() as Matrix
            r = multiply(m1, r)
        }
        return r
    }

    static apply(m: Matrix, points: number[][]): [number, number][] {
        return points.map(point => {
            const p = multiply(m, [[point[0]], [point[1]], [1]]) as Matrix
            return [p.get([0, 0]), p.get([1, 0])]
        })
    }

    getRectTransformMatrix(w: number, h: number): Matrix {
        let m = AffineTransform.multiply(
            AffineTransform.translate(this.transform.tx, this.transform.ty),
            //AffineTransform.translate(w/2, h/2),
            AffineTransform.rotate(this.transform.r ),
            AffineTransform.scale(this.transform.sx, this.transform.sy),
            AffineTransform.translate(-w / 2, -h / 2),
        )
        return m

    }

    static transform2Matrix(t: Transform) {
        return AffineTransform.multiply(
            AffineTransform.translate(t.tx, t.ty),
            AffineTransform.rotate(t.r),
            AffineTransform.scale(t.sx, t.sy),
        )
    }

    static matrix2Transform(m: Matrix) {
        const Tx = m.get([0, 2]);
        const Ty = m.get([1, 2]);
        const Sx = norm([m.get([0, 0]), m.get([1, 0])]) as number;
        const Sy = norm([m.get([0, 1]), m.get([1, 1])]) as number;


        const Mr = matrix([[m.get([0, 0]) / Sx, m.get([0, 1]) / Sy, 0],
        [m.get([1, 0]) / Sx, m.get([1, 1]) / Sy, 0],
        [0, 0, 1],
        ]);

        const v0 = matrix([1, 0, 1]);
        const v1 = multiply(Mr, [1, 0, 1]);

        const R = Math.atan2(v1.get([1]), v1.get([0])) - Math.atan2(v0.get([1]), v0.get([0]))

        const t: Transform = {
            r: R,
            sx: Sx,
            sy: Sy,
            tx: Tx,
            ty: Ty
        }
        return t

    }




    getRectScaleTranslateMatrix(w: number, h: number): Matrix {
        let m = AffineTransform.multiply(
            AffineTransform.translate(this.transform.tx, this.transform.ty),
            //AffineTransform.translate(w/2, h/2),
            AffineTransform.scale(this.transform.sx, this.transform.sy),
            AffineTransform.translate(-w / 2, -h / 2),
        )
        return m
    }


}