/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const wjite = $root.wjite = (() => {

    /**
     * Namespace wjite.
     * @exports wjite
     * @namespace
     */
    const wjite = {};

    wjite.message = (function() {

        /**
         * Namespace message.
         * @memberof wjite
         * @namespace
         */
        const message = {};

        message.Transform = (function() {

            /**
             * Properties of a Transform.
             * @memberof wjite.message
             * @interface ITransform
             * @property {number|null} [sx] Transform sx
             * @property {number|null} [sy] Transform sy
             * @property {number|null} [tx] Transform tx
             * @property {number|null} [ty] Transform ty
             * @property {number|null} [r] Transform r
             */

            /**
             * Constructs a new Transform.
             * @memberof wjite.message
             * @classdesc Represents a Transform.
             * @implements ITransform
             * @constructor
             * @param {wjite.message.ITransform=} [properties] Properties to set
             */
            function Transform(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Transform sx.
             * @member {number} sx
             * @memberof wjite.message.Transform
             * @instance
             */
            Transform.prototype.sx = 0;

            /**
             * Transform sy.
             * @member {number} sy
             * @memberof wjite.message.Transform
             * @instance
             */
            Transform.prototype.sy = 0;

            /**
             * Transform tx.
             * @member {number} tx
             * @memberof wjite.message.Transform
             * @instance
             */
            Transform.prototype.tx = 0;

            /**
             * Transform ty.
             * @member {number} ty
             * @memberof wjite.message.Transform
             * @instance
             */
            Transform.prototype.ty = 0;

            /**
             * Transform r.
             * @member {number} r
             * @memberof wjite.message.Transform
             * @instance
             */
            Transform.prototype.r = 0;

            /**
             * Creates a new Transform instance using the specified properties.
             * @function create
             * @memberof wjite.message.Transform
             * @static
             * @param {wjite.message.ITransform=} [properties] Properties to set
             * @returns {wjite.message.Transform} Transform instance
             */
            Transform.create = function create(properties) {
                return new Transform(properties);
            };

            /**
             * Encodes the specified Transform message. Does not implicitly {@link wjite.message.Transform.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.Transform
             * @static
             * @param {wjite.message.ITransform} message Transform message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Transform.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.sx != null && Object.hasOwnProperty.call(message, "sx"))
                    writer.uint32(/* id 1, wireType 5 =*/13).float(message.sx);
                if (message.sy != null && Object.hasOwnProperty.call(message, "sy"))
                    writer.uint32(/* id 2, wireType 5 =*/21).float(message.sy);
                if (message.tx != null && Object.hasOwnProperty.call(message, "tx"))
                    writer.uint32(/* id 3, wireType 5 =*/29).float(message.tx);
                if (message.ty != null && Object.hasOwnProperty.call(message, "ty"))
                    writer.uint32(/* id 4, wireType 5 =*/37).float(message.ty);
                if (message.r != null && Object.hasOwnProperty.call(message, "r"))
                    writer.uint32(/* id 5, wireType 5 =*/45).float(message.r);
                return writer;
            };

            /**
             * Encodes the specified Transform message, length delimited. Does not implicitly {@link wjite.message.Transform.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.Transform
             * @static
             * @param {wjite.message.ITransform} message Transform message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Transform.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Transform message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.Transform
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.Transform} Transform
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Transform.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.Transform();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.sx = reader.float();
                        break;
                    case 2:
                        message.sy = reader.float();
                        break;
                    case 3:
                        message.tx = reader.float();
                        break;
                    case 4:
                        message.ty = reader.float();
                        break;
                    case 5:
                        message.r = reader.float();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Transform message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.Transform
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.Transform} Transform
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Transform.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Transform message.
             * @function verify
             * @memberof wjite.message.Transform
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Transform.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.sx != null && message.hasOwnProperty("sx"))
                    if (typeof message.sx !== "number")
                        return "sx: number expected";
                if (message.sy != null && message.hasOwnProperty("sy"))
                    if (typeof message.sy !== "number")
                        return "sy: number expected";
                if (message.tx != null && message.hasOwnProperty("tx"))
                    if (typeof message.tx !== "number")
                        return "tx: number expected";
                if (message.ty != null && message.hasOwnProperty("ty"))
                    if (typeof message.ty !== "number")
                        return "ty: number expected";
                if (message.r != null && message.hasOwnProperty("r"))
                    if (typeof message.r !== "number")
                        return "r: number expected";
                return null;
            };

            /**
             * Creates a Transform message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.Transform
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.Transform} Transform
             */
            Transform.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.Transform)
                    return object;
                let message = new $root.wjite.message.Transform();
                if (object.sx != null)
                    message.sx = Number(object.sx);
                if (object.sy != null)
                    message.sy = Number(object.sy);
                if (object.tx != null)
                    message.tx = Number(object.tx);
                if (object.ty != null)
                    message.ty = Number(object.ty);
                if (object.r != null)
                    message.r = Number(object.r);
                return message;
            };

            /**
             * Creates a plain object from a Transform message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.Transform
             * @static
             * @param {wjite.message.Transform} message Transform
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Transform.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.sx = 0;
                    object.sy = 0;
                    object.tx = 0;
                    object.ty = 0;
                    object.r = 0;
                }
                if (message.sx != null && message.hasOwnProperty("sx"))
                    object.sx = options.json && !isFinite(message.sx) ? String(message.sx) : message.sx;
                if (message.sy != null && message.hasOwnProperty("sy"))
                    object.sy = options.json && !isFinite(message.sy) ? String(message.sy) : message.sy;
                if (message.tx != null && message.hasOwnProperty("tx"))
                    object.tx = options.json && !isFinite(message.tx) ? String(message.tx) : message.tx;
                if (message.ty != null && message.hasOwnProperty("ty"))
                    object.ty = options.json && !isFinite(message.ty) ? String(message.ty) : message.ty;
                if (message.r != null && message.hasOwnProperty("r"))
                    object.r = options.json && !isFinite(message.r) ? String(message.r) : message.r;
                return object;
            };

            /**
             * Converts this Transform to JSON.
             * @function toJSON
             * @memberof wjite.message.Transform
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Transform.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Transform;
        })();

        message.Stroke = (function() {

            /**
             * Properties of a Stroke.
             * @memberof wjite.message
             * @interface IStroke
             * @property {Array.<wjite.message.Stroke.IPoint>|null} [points] Stroke points
             * @property {string|null} [color] Stroke color
             * @property {number|null} [width] Stroke width
             * @property {number|null} [opacity] Stroke opacity
             * @property {boolean|null} [smooth] Stroke smooth
             * @property {boolean|null} [closed] Stroke closed
             */

            /**
             * Constructs a new Stroke.
             * @memberof wjite.message
             * @classdesc Represents a Stroke.
             * @implements IStroke
             * @constructor
             * @param {wjite.message.IStroke=} [properties] Properties to set
             */
            function Stroke(properties) {
                this.points = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Stroke points.
             * @member {Array.<wjite.message.Stroke.IPoint>} points
             * @memberof wjite.message.Stroke
             * @instance
             */
            Stroke.prototype.points = $util.emptyArray;

            /**
             * Stroke color.
             * @member {string} color
             * @memberof wjite.message.Stroke
             * @instance
             */
            Stroke.prototype.color = "";

            /**
             * Stroke width.
             * @member {number} width
             * @memberof wjite.message.Stroke
             * @instance
             */
            Stroke.prototype.width = 0;

            /**
             * Stroke opacity.
             * @member {number} opacity
             * @memberof wjite.message.Stroke
             * @instance
             */
            Stroke.prototype.opacity = 0;

            /**
             * Stroke smooth.
             * @member {boolean} smooth
             * @memberof wjite.message.Stroke
             * @instance
             */
            Stroke.prototype.smooth = false;

            /**
             * Stroke closed.
             * @member {boolean} closed
             * @memberof wjite.message.Stroke
             * @instance
             */
            Stroke.prototype.closed = false;

            /**
             * Creates a new Stroke instance using the specified properties.
             * @function create
             * @memberof wjite.message.Stroke
             * @static
             * @param {wjite.message.IStroke=} [properties] Properties to set
             * @returns {wjite.message.Stroke} Stroke instance
             */
            Stroke.create = function create(properties) {
                return new Stroke(properties);
            };

            /**
             * Encodes the specified Stroke message. Does not implicitly {@link wjite.message.Stroke.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.Stroke
             * @static
             * @param {wjite.message.IStroke} message Stroke message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Stroke.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.points != null && message.points.length)
                    for (let i = 0; i < message.points.length; ++i)
                        $root.wjite.message.Stroke.Point.encode(message.points[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.color);
                if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.width);
                if (message.opacity != null && Object.hasOwnProperty.call(message, "opacity"))
                    writer.uint32(/* id 4, wireType 5 =*/37).float(message.opacity);
                if (message.smooth != null && Object.hasOwnProperty.call(message, "smooth"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.smooth);
                if (message.closed != null && Object.hasOwnProperty.call(message, "closed"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.closed);
                return writer;
            };

            /**
             * Encodes the specified Stroke message, length delimited. Does not implicitly {@link wjite.message.Stroke.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.Stroke
             * @static
             * @param {wjite.message.IStroke} message Stroke message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Stroke.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Stroke message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.Stroke
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.Stroke} Stroke
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Stroke.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.Stroke();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.points && message.points.length))
                            message.points = [];
                        message.points.push($root.wjite.message.Stroke.Point.decode(reader, reader.uint32()));
                        break;
                    case 2:
                        message.color = reader.string();
                        break;
                    case 3:
                        message.width = reader.uint32();
                        break;
                    case 4:
                        message.opacity = reader.float();
                        break;
                    case 5:
                        message.smooth = reader.bool();
                        break;
                    case 6:
                        message.closed = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Stroke message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.Stroke
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.Stroke} Stroke
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Stroke.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Stroke message.
             * @function verify
             * @memberof wjite.message.Stroke
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Stroke.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.points != null && message.hasOwnProperty("points")) {
                    if (!Array.isArray(message.points))
                        return "points: array expected";
                    for (let i = 0; i < message.points.length; ++i) {
                        let error = $root.wjite.message.Stroke.Point.verify(message.points[i]);
                        if (error)
                            return "points." + error;
                    }
                }
                if (message.color != null && message.hasOwnProperty("color"))
                    if (!$util.isString(message.color))
                        return "color: string expected";
                if (message.width != null && message.hasOwnProperty("width"))
                    if (!$util.isInteger(message.width))
                        return "width: integer expected";
                if (message.opacity != null && message.hasOwnProperty("opacity"))
                    if (typeof message.opacity !== "number")
                        return "opacity: number expected";
                if (message.smooth != null && message.hasOwnProperty("smooth"))
                    if (typeof message.smooth !== "boolean")
                        return "smooth: boolean expected";
                if (message.closed != null && message.hasOwnProperty("closed"))
                    if (typeof message.closed !== "boolean")
                        return "closed: boolean expected";
                return null;
            };

            /**
             * Creates a Stroke message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.Stroke
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.Stroke} Stroke
             */
            Stroke.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.Stroke)
                    return object;
                let message = new $root.wjite.message.Stroke();
                if (object.points) {
                    if (!Array.isArray(object.points))
                        throw TypeError(".wjite.message.Stroke.points: array expected");
                    message.points = [];
                    for (let i = 0; i < object.points.length; ++i) {
                        if (typeof object.points[i] !== "object")
                            throw TypeError(".wjite.message.Stroke.points: object expected");
                        message.points[i] = $root.wjite.message.Stroke.Point.fromObject(object.points[i]);
                    }
                }
                if (object.color != null)
                    message.color = String(object.color);
                if (object.width != null)
                    message.width = object.width >>> 0;
                if (object.opacity != null)
                    message.opacity = Number(object.opacity);
                if (object.smooth != null)
                    message.smooth = Boolean(object.smooth);
                if (object.closed != null)
                    message.closed = Boolean(object.closed);
                return message;
            };

            /**
             * Creates a plain object from a Stroke message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.Stroke
             * @static
             * @param {wjite.message.Stroke} message Stroke
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Stroke.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.points = [];
                if (options.defaults) {
                    object.color = "";
                    object.width = 0;
                    object.opacity = 0;
                    object.smooth = false;
                    object.closed = false;
                }
                if (message.points && message.points.length) {
                    object.points = [];
                    for (let j = 0; j < message.points.length; ++j)
                        object.points[j] = $root.wjite.message.Stroke.Point.toObject(message.points[j], options);
                }
                if (message.color != null && message.hasOwnProperty("color"))
                    object.color = message.color;
                if (message.width != null && message.hasOwnProperty("width"))
                    object.width = message.width;
                if (message.opacity != null && message.hasOwnProperty("opacity"))
                    object.opacity = options.json && !isFinite(message.opacity) ? String(message.opacity) : message.opacity;
                if (message.smooth != null && message.hasOwnProperty("smooth"))
                    object.smooth = message.smooth;
                if (message.closed != null && message.hasOwnProperty("closed"))
                    object.closed = message.closed;
                return object;
            };

            /**
             * Converts this Stroke to JSON.
             * @function toJSON
             * @memberof wjite.message.Stroke
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Stroke.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            Stroke.Point = (function() {

                /**
                 * Properties of a Point.
                 * @memberof wjite.message.Stroke
                 * @interface IPoint
                 * @property {number|null} [x] Point x
                 * @property {number|null} [y] Point y
                 */

                /**
                 * Constructs a new Point.
                 * @memberof wjite.message.Stroke
                 * @classdesc Represents a Point.
                 * @implements IPoint
                 * @constructor
                 * @param {wjite.message.Stroke.IPoint=} [properties] Properties to set
                 */
                function Point(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Point x.
                 * @member {number} x
                 * @memberof wjite.message.Stroke.Point
                 * @instance
                 */
                Point.prototype.x = 0;

                /**
                 * Point y.
                 * @member {number} y
                 * @memberof wjite.message.Stroke.Point
                 * @instance
                 */
                Point.prototype.y = 0;

                /**
                 * Creates a new Point instance using the specified properties.
                 * @function create
                 * @memberof wjite.message.Stroke.Point
                 * @static
                 * @param {wjite.message.Stroke.IPoint=} [properties] Properties to set
                 * @returns {wjite.message.Stroke.Point} Point instance
                 */
                Point.create = function create(properties) {
                    return new Point(properties);
                };

                /**
                 * Encodes the specified Point message. Does not implicitly {@link wjite.message.Stroke.Point.verify|verify} messages.
                 * @function encode
                 * @memberof wjite.message.Stroke.Point
                 * @static
                 * @param {wjite.message.Stroke.IPoint} message Point message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Point.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                        writer.uint32(/* id 1, wireType 5 =*/13).float(message.x);
                    if (message.y != null && Object.hasOwnProperty.call(message, "y"))
                        writer.uint32(/* id 2, wireType 5 =*/21).float(message.y);
                    return writer;
                };

                /**
                 * Encodes the specified Point message, length delimited. Does not implicitly {@link wjite.message.Stroke.Point.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof wjite.message.Stroke.Point
                 * @static
                 * @param {wjite.message.Stroke.IPoint} message Point message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Point.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Point message from the specified reader or buffer.
                 * @function decode
                 * @memberof wjite.message.Stroke.Point
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {wjite.message.Stroke.Point} Point
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Point.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.Stroke.Point();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.x = reader.float();
                            break;
                        case 2:
                            message.y = reader.float();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Point message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof wjite.message.Stroke.Point
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {wjite.message.Stroke.Point} Point
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Point.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Point message.
                 * @function verify
                 * @memberof wjite.message.Stroke.Point
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Point.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.x != null && message.hasOwnProperty("x"))
                        if (typeof message.x !== "number")
                            return "x: number expected";
                    if (message.y != null && message.hasOwnProperty("y"))
                        if (typeof message.y !== "number")
                            return "y: number expected";
                    return null;
                };

                /**
                 * Creates a Point message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof wjite.message.Stroke.Point
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {wjite.message.Stroke.Point} Point
                 */
                Point.fromObject = function fromObject(object) {
                    if (object instanceof $root.wjite.message.Stroke.Point)
                        return object;
                    let message = new $root.wjite.message.Stroke.Point();
                    if (object.x != null)
                        message.x = Number(object.x);
                    if (object.y != null)
                        message.y = Number(object.y);
                    return message;
                };

                /**
                 * Creates a plain object from a Point message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof wjite.message.Stroke.Point
                 * @static
                 * @param {wjite.message.Stroke.Point} message Point
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Point.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.x = 0;
                        object.y = 0;
                    }
                    if (message.x != null && message.hasOwnProperty("x"))
                        object.x = options.json && !isFinite(message.x) ? String(message.x) : message.x;
                    if (message.y != null && message.hasOwnProperty("y"))
                        object.y = options.json && !isFinite(message.y) ? String(message.y) : message.y;
                    return object;
                };

                /**
                 * Converts this Point to JSON.
                 * @function toJSON
                 * @memberof wjite.message.Stroke.Point
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Point.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Point;
            })();

            return Stroke;
        })();

        message.Figure = (function() {

            /**
             * Properties of a Figure.
             * @memberof wjite.message
             * @interface IFigure
             * @property {wjite.message.Figure.Type|null} [figureType] Figure figureType
             * @property {string|null} [color] Figure color
             * @property {number|null} [opacity] Figure opacity
             * @property {number|null} [width] Figure width
             * @property {number|null} [height] Figure height
             * @property {number|null} [strokeWidth] Figure strokeWidth
             * @property {boolean|null} [fill] Figure fill
             */

            /**
             * Constructs a new Figure.
             * @memberof wjite.message
             * @classdesc Represents a Figure.
             * @implements IFigure
             * @constructor
             * @param {wjite.message.IFigure=} [properties] Properties to set
             */
            function Figure(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Figure figureType.
             * @member {wjite.message.Figure.Type} figureType
             * @memberof wjite.message.Figure
             * @instance
             */
            Figure.prototype.figureType = 0;

            /**
             * Figure color.
             * @member {string} color
             * @memberof wjite.message.Figure
             * @instance
             */
            Figure.prototype.color = "";

            /**
             * Figure opacity.
             * @member {number} opacity
             * @memberof wjite.message.Figure
             * @instance
             */
            Figure.prototype.opacity = 0;

            /**
             * Figure width.
             * @member {number} width
             * @memberof wjite.message.Figure
             * @instance
             */
            Figure.prototype.width = 0;

            /**
             * Figure height.
             * @member {number} height
             * @memberof wjite.message.Figure
             * @instance
             */
            Figure.prototype.height = 0;

            /**
             * Figure strokeWidth.
             * @member {number} strokeWidth
             * @memberof wjite.message.Figure
             * @instance
             */
            Figure.prototype.strokeWidth = 0;

            /**
             * Figure fill.
             * @member {boolean} fill
             * @memberof wjite.message.Figure
             * @instance
             */
            Figure.prototype.fill = false;

            /**
             * Creates a new Figure instance using the specified properties.
             * @function create
             * @memberof wjite.message.Figure
             * @static
             * @param {wjite.message.IFigure=} [properties] Properties to set
             * @returns {wjite.message.Figure} Figure instance
             */
            Figure.create = function create(properties) {
                return new Figure(properties);
            };

            /**
             * Encodes the specified Figure message. Does not implicitly {@link wjite.message.Figure.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.Figure
             * @static
             * @param {wjite.message.IFigure} message Figure message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Figure.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.figureType != null && Object.hasOwnProperty.call(message, "figureType"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.figureType);
                if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.color);
                if (message.opacity != null && Object.hasOwnProperty.call(message, "opacity"))
                    writer.uint32(/* id 4, wireType 5 =*/37).float(message.opacity);
                if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                    writer.uint32(/* id 5, wireType 5 =*/45).float(message.width);
                if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                    writer.uint32(/* id 6, wireType 5 =*/53).float(message.height);
                if (message.strokeWidth != null && Object.hasOwnProperty.call(message, "strokeWidth"))
                    writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.strokeWidth);
                if (message.fill != null && Object.hasOwnProperty.call(message, "fill"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.fill);
                return writer;
            };

            /**
             * Encodes the specified Figure message, length delimited. Does not implicitly {@link wjite.message.Figure.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.Figure
             * @static
             * @param {wjite.message.IFigure} message Figure message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Figure.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Figure message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.Figure
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.Figure} Figure
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Figure.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.Figure();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2:
                        message.figureType = reader.int32();
                        break;
                    case 3:
                        message.color = reader.string();
                        break;
                    case 4:
                        message.opacity = reader.float();
                        break;
                    case 5:
                        message.width = reader.float();
                        break;
                    case 6:
                        message.height = reader.float();
                        break;
                    case 7:
                        message.strokeWidth = reader.uint32();
                        break;
                    case 8:
                        message.fill = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Figure message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.Figure
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.Figure} Figure
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Figure.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Figure message.
             * @function verify
             * @memberof wjite.message.Figure
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Figure.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.figureType != null && message.hasOwnProperty("figureType"))
                    switch (message.figureType) {
                    default:
                        return "figureType: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.color != null && message.hasOwnProperty("color"))
                    if (!$util.isString(message.color))
                        return "color: string expected";
                if (message.opacity != null && message.hasOwnProperty("opacity"))
                    if (typeof message.opacity !== "number")
                        return "opacity: number expected";
                if (message.width != null && message.hasOwnProperty("width"))
                    if (typeof message.width !== "number")
                        return "width: number expected";
                if (message.height != null && message.hasOwnProperty("height"))
                    if (typeof message.height !== "number")
                        return "height: number expected";
                if (message.strokeWidth != null && message.hasOwnProperty("strokeWidth"))
                    if (!$util.isInteger(message.strokeWidth))
                        return "strokeWidth: integer expected";
                if (message.fill != null && message.hasOwnProperty("fill"))
                    if (typeof message.fill !== "boolean")
                        return "fill: boolean expected";
                return null;
            };

            /**
             * Creates a Figure message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.Figure
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.Figure} Figure
             */
            Figure.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.Figure)
                    return object;
                let message = new $root.wjite.message.Figure();
                switch (object.figureType) {
                case "Rectangle":
                case 0:
                    message.figureType = 0;
                    break;
                case "Ellipse":
                case 1:
                    message.figureType = 1;
                    break;
                }
                if (object.color != null)
                    message.color = String(object.color);
                if (object.opacity != null)
                    message.opacity = Number(object.opacity);
                if (object.width != null)
                    message.width = Number(object.width);
                if (object.height != null)
                    message.height = Number(object.height);
                if (object.strokeWidth != null)
                    message.strokeWidth = object.strokeWidth >>> 0;
                if (object.fill != null)
                    message.fill = Boolean(object.fill);
                return message;
            };

            /**
             * Creates a plain object from a Figure message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.Figure
             * @static
             * @param {wjite.message.Figure} message Figure
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Figure.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.figureType = options.enums === String ? "Rectangle" : 0;
                    object.color = "";
                    object.opacity = 0;
                    object.width = 0;
                    object.height = 0;
                    object.strokeWidth = 0;
                    object.fill = false;
                }
                if (message.figureType != null && message.hasOwnProperty("figureType"))
                    object.figureType = options.enums === String ? $root.wjite.message.Figure.Type[message.figureType] : message.figureType;
                if (message.color != null && message.hasOwnProperty("color"))
                    object.color = message.color;
                if (message.opacity != null && message.hasOwnProperty("opacity"))
                    object.opacity = options.json && !isFinite(message.opacity) ? String(message.opacity) : message.opacity;
                if (message.width != null && message.hasOwnProperty("width"))
                    object.width = options.json && !isFinite(message.width) ? String(message.width) : message.width;
                if (message.height != null && message.hasOwnProperty("height"))
                    object.height = options.json && !isFinite(message.height) ? String(message.height) : message.height;
                if (message.strokeWidth != null && message.hasOwnProperty("strokeWidth"))
                    object.strokeWidth = message.strokeWidth;
                if (message.fill != null && message.hasOwnProperty("fill"))
                    object.fill = message.fill;
                return object;
            };

            /**
             * Converts this Figure to JSON.
             * @function toJSON
             * @memberof wjite.message.Figure
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Figure.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Type enum.
             * @name wjite.message.Figure.Type
             * @enum {number}
             * @property {number} Rectangle=0 Rectangle value
             * @property {number} Ellipse=1 Ellipse value
             */
            Figure.Type = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "Rectangle"] = 0;
                values[valuesById[1] = "Ellipse"] = 1;
                return values;
            })();

            return Figure;
        })();

        message.Text = (function() {

            /**
             * Properties of a Text.
             * @memberof wjite.message
             * @interface IText
             * @property {string|null} [content] Text content
             * @property {string|null} [color] Text color
             * @property {string|null} [family] Text family
             * @property {number|null} [size] Text size
             */

            /**
             * Constructs a new Text.
             * @memberof wjite.message
             * @classdesc Represents a Text.
             * @implements IText
             * @constructor
             * @param {wjite.message.IText=} [properties] Properties to set
             */
            function Text(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Text content.
             * @member {string} content
             * @memberof wjite.message.Text
             * @instance
             */
            Text.prototype.content = "";

            /**
             * Text color.
             * @member {string} color
             * @memberof wjite.message.Text
             * @instance
             */
            Text.prototype.color = "";

            /**
             * Text family.
             * @member {string} family
             * @memberof wjite.message.Text
             * @instance
             */
            Text.prototype.family = "";

            /**
             * Text size.
             * @member {number} size
             * @memberof wjite.message.Text
             * @instance
             */
            Text.prototype.size = 0;

            /**
             * Creates a new Text instance using the specified properties.
             * @function create
             * @memberof wjite.message.Text
             * @static
             * @param {wjite.message.IText=} [properties] Properties to set
             * @returns {wjite.message.Text} Text instance
             */
            Text.create = function create(properties) {
                return new Text(properties);
            };

            /**
             * Encodes the specified Text message. Does not implicitly {@link wjite.message.Text.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.Text
             * @static
             * @param {wjite.message.IText} message Text message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Text.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.content);
                if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.color);
                if (message.family != null && Object.hasOwnProperty.call(message, "family"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.family);
                if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.size);
                return writer;
            };

            /**
             * Encodes the specified Text message, length delimited. Does not implicitly {@link wjite.message.Text.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.Text
             * @static
             * @param {wjite.message.IText} message Text message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Text.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Text message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.Text
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.Text} Text
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Text.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.Text();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2:
                        message.content = reader.string();
                        break;
                    case 3:
                        message.color = reader.string();
                        break;
                    case 4:
                        message.family = reader.string();
                        break;
                    case 5:
                        message.size = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Text message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.Text
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.Text} Text
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Text.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Text message.
             * @function verify
             * @memberof wjite.message.Text
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Text.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.content != null && message.hasOwnProperty("content"))
                    if (!$util.isString(message.content))
                        return "content: string expected";
                if (message.color != null && message.hasOwnProperty("color"))
                    if (!$util.isString(message.color))
                        return "color: string expected";
                if (message.family != null && message.hasOwnProperty("family"))
                    if (!$util.isString(message.family))
                        return "family: string expected";
                if (message.size != null && message.hasOwnProperty("size"))
                    if (!$util.isInteger(message.size))
                        return "size: integer expected";
                return null;
            };

            /**
             * Creates a Text message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.Text
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.Text} Text
             */
            Text.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.Text)
                    return object;
                let message = new $root.wjite.message.Text();
                if (object.content != null)
                    message.content = String(object.content);
                if (object.color != null)
                    message.color = String(object.color);
                if (object.family != null)
                    message.family = String(object.family);
                if (object.size != null)
                    message.size = object.size >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a Text message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.Text
             * @static
             * @param {wjite.message.Text} message Text
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Text.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.content = "";
                    object.color = "";
                    object.family = "";
                    object.size = 0;
                }
                if (message.content != null && message.hasOwnProperty("content"))
                    object.content = message.content;
                if (message.color != null && message.hasOwnProperty("color"))
                    object.color = message.color;
                if (message.family != null && message.hasOwnProperty("family"))
                    object.family = message.family;
                if (message.size != null && message.hasOwnProperty("size"))
                    object.size = message.size;
                return object;
            };

            /**
             * Converts this Text to JSON.
             * @function toJSON
             * @memberof wjite.message.Text
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Text.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Text;
        })();

        message.TextArea = (function() {

            /**
             * Properties of a TextArea.
             * @memberof wjite.message
             * @interface ITextArea
             * @property {string|null} [content] TextArea content
             * @property {string|null} [family] TextArea family
             * @property {number|null} [size] TextArea size
             * @property {string|null} [style] TextArea style
             * @property {string|null} [color] TextArea color
             * @property {number|null} [width] TextArea width
             * @property {number|null} [height] TextArea height
             */

            /**
             * Constructs a new TextArea.
             * @memberof wjite.message
             * @classdesc Represents a TextArea.
             * @implements ITextArea
             * @constructor
             * @param {wjite.message.ITextArea=} [properties] Properties to set
             */
            function TextArea(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TextArea content.
             * @member {string} content
             * @memberof wjite.message.TextArea
             * @instance
             */
            TextArea.prototype.content = "";

            /**
             * TextArea family.
             * @member {string} family
             * @memberof wjite.message.TextArea
             * @instance
             */
            TextArea.prototype.family = "";

            /**
             * TextArea size.
             * @member {number} size
             * @memberof wjite.message.TextArea
             * @instance
             */
            TextArea.prototype.size = 0;

            /**
             * TextArea style.
             * @member {string} style
             * @memberof wjite.message.TextArea
             * @instance
             */
            TextArea.prototype.style = "";

            /**
             * TextArea color.
             * @member {string} color
             * @memberof wjite.message.TextArea
             * @instance
             */
            TextArea.prototype.color = "";

            /**
             * TextArea width.
             * @member {number} width
             * @memberof wjite.message.TextArea
             * @instance
             */
            TextArea.prototype.width = 0;

            /**
             * TextArea height.
             * @member {number} height
             * @memberof wjite.message.TextArea
             * @instance
             */
            TextArea.prototype.height = 0;

            /**
             * Creates a new TextArea instance using the specified properties.
             * @function create
             * @memberof wjite.message.TextArea
             * @static
             * @param {wjite.message.ITextArea=} [properties] Properties to set
             * @returns {wjite.message.TextArea} TextArea instance
             */
            TextArea.create = function create(properties) {
                return new TextArea(properties);
            };

            /**
             * Encodes the specified TextArea message. Does not implicitly {@link wjite.message.TextArea.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.TextArea
             * @static
             * @param {wjite.message.ITextArea} message TextArea message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TextArea.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.content);
                if (message.family != null && Object.hasOwnProperty.call(message, "family"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.family);
                if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.size);
                if (message.style != null && Object.hasOwnProperty.call(message, "style"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.style);
                if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.color);
                if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                    writer.uint32(/* id 7, wireType 5 =*/61).float(message.width);
                if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                    writer.uint32(/* id 8, wireType 5 =*/69).float(message.height);
                return writer;
            };

            /**
             * Encodes the specified TextArea message, length delimited. Does not implicitly {@link wjite.message.TextArea.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.TextArea
             * @static
             * @param {wjite.message.ITextArea} message TextArea message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TextArea.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TextArea message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.TextArea
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.TextArea} TextArea
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TextArea.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.TextArea();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2:
                        message.content = reader.string();
                        break;
                    case 3:
                        message.family = reader.string();
                        break;
                    case 4:
                        message.size = reader.uint32();
                        break;
                    case 5:
                        message.style = reader.string();
                        break;
                    case 6:
                        message.color = reader.string();
                        break;
                    case 7:
                        message.width = reader.float();
                        break;
                    case 8:
                        message.height = reader.float();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TextArea message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.TextArea
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.TextArea} TextArea
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TextArea.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TextArea message.
             * @function verify
             * @memberof wjite.message.TextArea
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TextArea.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.content != null && message.hasOwnProperty("content"))
                    if (!$util.isString(message.content))
                        return "content: string expected";
                if (message.family != null && message.hasOwnProperty("family"))
                    if (!$util.isString(message.family))
                        return "family: string expected";
                if (message.size != null && message.hasOwnProperty("size"))
                    if (!$util.isInteger(message.size))
                        return "size: integer expected";
                if (message.style != null && message.hasOwnProperty("style"))
                    if (!$util.isString(message.style))
                        return "style: string expected";
                if (message.color != null && message.hasOwnProperty("color"))
                    if (!$util.isString(message.color))
                        return "color: string expected";
                if (message.width != null && message.hasOwnProperty("width"))
                    if (typeof message.width !== "number")
                        return "width: number expected";
                if (message.height != null && message.hasOwnProperty("height"))
                    if (typeof message.height !== "number")
                        return "height: number expected";
                return null;
            };

            /**
             * Creates a TextArea message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.TextArea
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.TextArea} TextArea
             */
            TextArea.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.TextArea)
                    return object;
                let message = new $root.wjite.message.TextArea();
                if (object.content != null)
                    message.content = String(object.content);
                if (object.family != null)
                    message.family = String(object.family);
                if (object.size != null)
                    message.size = object.size >>> 0;
                if (object.style != null)
                    message.style = String(object.style);
                if (object.color != null)
                    message.color = String(object.color);
                if (object.width != null)
                    message.width = Number(object.width);
                if (object.height != null)
                    message.height = Number(object.height);
                return message;
            };

            /**
             * Creates a plain object from a TextArea message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.TextArea
             * @static
             * @param {wjite.message.TextArea} message TextArea
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TextArea.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.content = "";
                    object.family = "";
                    object.size = 0;
                    object.style = "";
                    object.color = "";
                    object.width = 0;
                    object.height = 0;
                }
                if (message.content != null && message.hasOwnProperty("content"))
                    object.content = message.content;
                if (message.family != null && message.hasOwnProperty("family"))
                    object.family = message.family;
                if (message.size != null && message.hasOwnProperty("size"))
                    object.size = message.size;
                if (message.style != null && message.hasOwnProperty("style"))
                    object.style = message.style;
                if (message.color != null && message.hasOwnProperty("color"))
                    object.color = message.color;
                if (message.width != null && message.hasOwnProperty("width"))
                    object.width = options.json && !isFinite(message.width) ? String(message.width) : message.width;
                if (message.height != null && message.hasOwnProperty("height"))
                    object.height = options.json && !isFinite(message.height) ? String(message.height) : message.height;
                return object;
            };

            /**
             * Converts this TextArea to JSON.
             * @function toJSON
             * @memberof wjite.message.TextArea
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TextArea.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TextArea;
        })();

        message.Sticker = (function() {

            /**
             * Properties of a Sticker.
             * @memberof wjite.message
             * @interface ISticker
             * @property {wjite.message.Sticker.Type|null} [contentType] Sticker contentType
             * @property {string|null} [content] Sticker content
             * @property {number|null} [width] Sticker width
             * @property {number|null} [height] Sticker height
             * @property {number|null} [fontSize] Sticker fontSize
             * @property {string|null} [color] Sticker color
             */

            /**
             * Constructs a new Sticker.
             * @memberof wjite.message
             * @classdesc Represents a Sticker.
             * @implements ISticker
             * @constructor
             * @param {wjite.message.ISticker=} [properties] Properties to set
             */
            function Sticker(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Sticker contentType.
             * @member {wjite.message.Sticker.Type} contentType
             * @memberof wjite.message.Sticker
             * @instance
             */
            Sticker.prototype.contentType = 0;

            /**
             * Sticker content.
             * @member {string} content
             * @memberof wjite.message.Sticker
             * @instance
             */
            Sticker.prototype.content = "";

            /**
             * Sticker width.
             * @member {number} width
             * @memberof wjite.message.Sticker
             * @instance
             */
            Sticker.prototype.width = 0;

            /**
             * Sticker height.
             * @member {number} height
             * @memberof wjite.message.Sticker
             * @instance
             */
            Sticker.prototype.height = 0;

            /**
             * Sticker fontSize.
             * @member {number} fontSize
             * @memberof wjite.message.Sticker
             * @instance
             */
            Sticker.prototype.fontSize = 0;

            /**
             * Sticker color.
             * @member {string} color
             * @memberof wjite.message.Sticker
             * @instance
             */
            Sticker.prototype.color = "";

            /**
             * Creates a new Sticker instance using the specified properties.
             * @function create
             * @memberof wjite.message.Sticker
             * @static
             * @param {wjite.message.ISticker=} [properties] Properties to set
             * @returns {wjite.message.Sticker} Sticker instance
             */
            Sticker.create = function create(properties) {
                return new Sticker(properties);
            };

            /**
             * Encodes the specified Sticker message. Does not implicitly {@link wjite.message.Sticker.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.Sticker
             * @static
             * @param {wjite.message.ISticker} message Sticker message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Sticker.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.contentType != null && Object.hasOwnProperty.call(message, "contentType"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.contentType);
                if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.content);
                if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                    writer.uint32(/* id 8, wireType 5 =*/69).float(message.width);
                if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                    writer.uint32(/* id 9, wireType 5 =*/77).float(message.height);
                if (message.fontSize != null && Object.hasOwnProperty.call(message, "fontSize"))
                    writer.uint32(/* id 10, wireType 0 =*/80).uint32(message.fontSize);
                if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.color);
                return writer;
            };

            /**
             * Encodes the specified Sticker message, length delimited. Does not implicitly {@link wjite.message.Sticker.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.Sticker
             * @static
             * @param {wjite.message.ISticker} message Sticker message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Sticker.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Sticker message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.Sticker
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.Sticker} Sticker
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Sticker.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.Sticker();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2:
                        message.contentType = reader.int32();
                        break;
                    case 3:
                        message.content = reader.string();
                        break;
                    case 8:
                        message.width = reader.float();
                        break;
                    case 9:
                        message.height = reader.float();
                        break;
                    case 10:
                        message.fontSize = reader.uint32();
                        break;
                    case 11:
                        message.color = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Sticker message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.Sticker
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.Sticker} Sticker
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Sticker.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Sticker message.
             * @function verify
             * @memberof wjite.message.Sticker
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Sticker.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.contentType != null && message.hasOwnProperty("contentType"))
                    switch (message.contentType) {
                    default:
                        return "contentType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.content != null && message.hasOwnProperty("content"))
                    if (!$util.isString(message.content))
                        return "content: string expected";
                if (message.width != null && message.hasOwnProperty("width"))
                    if (typeof message.width !== "number")
                        return "width: number expected";
                if (message.height != null && message.hasOwnProperty("height"))
                    if (typeof message.height !== "number")
                        return "height: number expected";
                if (message.fontSize != null && message.hasOwnProperty("fontSize"))
                    if (!$util.isInteger(message.fontSize))
                        return "fontSize: integer expected";
                if (message.color != null && message.hasOwnProperty("color"))
                    if (!$util.isString(message.color))
                        return "color: string expected";
                return null;
            };

            /**
             * Creates a Sticker message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.Sticker
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.Sticker} Sticker
             */
            Sticker.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.Sticker)
                    return object;
                let message = new $root.wjite.message.Sticker();
                switch (object.contentType) {
                case "Markdown":
                case 0:
                    message.contentType = 0;
                    break;
                case "Code":
                case 1:
                    message.contentType = 1;
                    break;
                case "Video":
                case 2:
                    message.contentType = 2;
                    break;
                }
                if (object.content != null)
                    message.content = String(object.content);
                if (object.width != null)
                    message.width = Number(object.width);
                if (object.height != null)
                    message.height = Number(object.height);
                if (object.fontSize != null)
                    message.fontSize = object.fontSize >>> 0;
                if (object.color != null)
                    message.color = String(object.color);
                return message;
            };

            /**
             * Creates a plain object from a Sticker message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.Sticker
             * @static
             * @param {wjite.message.Sticker} message Sticker
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Sticker.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.contentType = options.enums === String ? "Markdown" : 0;
                    object.content = "";
                    object.width = 0;
                    object.height = 0;
                    object.fontSize = 0;
                    object.color = "";
                }
                if (message.contentType != null && message.hasOwnProperty("contentType"))
                    object.contentType = options.enums === String ? $root.wjite.message.Sticker.Type[message.contentType] : message.contentType;
                if (message.content != null && message.hasOwnProperty("content"))
                    object.content = message.content;
                if (message.width != null && message.hasOwnProperty("width"))
                    object.width = options.json && !isFinite(message.width) ? String(message.width) : message.width;
                if (message.height != null && message.hasOwnProperty("height"))
                    object.height = options.json && !isFinite(message.height) ? String(message.height) : message.height;
                if (message.fontSize != null && message.hasOwnProperty("fontSize"))
                    object.fontSize = message.fontSize;
                if (message.color != null && message.hasOwnProperty("color"))
                    object.color = message.color;
                return object;
            };

            /**
             * Converts this Sticker to JSON.
             * @function toJSON
             * @memberof wjite.message.Sticker
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Sticker.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Type enum.
             * @name wjite.message.Sticker.Type
             * @enum {number}
             * @property {number} Markdown=0 Markdown value
             * @property {number} Code=1 Code value
             * @property {number} Video=2 Video value
             */
            Sticker.Type = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "Markdown"] = 0;
                values[valuesById[1] = "Code"] = 1;
                values[valuesById[2] = "Video"] = 2;
                return values;
            })();

            return Sticker;
        })();

        message.Image = (function() {

            /**
             * Properties of an Image.
             * @memberof wjite.message
             * @interface IImage
             * @property {string|null} [src] Image src
             * @property {Uint8Array|null} [blob] Image blob
             */

            /**
             * Constructs a new Image.
             * @memberof wjite.message
             * @classdesc Represents an Image.
             * @implements IImage
             * @constructor
             * @param {wjite.message.IImage=} [properties] Properties to set
             */
            function Image(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Image src.
             * @member {string} src
             * @memberof wjite.message.Image
             * @instance
             */
            Image.prototype.src = "";

            /**
             * Image blob.
             * @member {Uint8Array} blob
             * @memberof wjite.message.Image
             * @instance
             */
            Image.prototype.blob = $util.newBuffer([]);

            /**
             * Creates a new Image instance using the specified properties.
             * @function create
             * @memberof wjite.message.Image
             * @static
             * @param {wjite.message.IImage=} [properties] Properties to set
             * @returns {wjite.message.Image} Image instance
             */
            Image.create = function create(properties) {
                return new Image(properties);
            };

            /**
             * Encodes the specified Image message. Does not implicitly {@link wjite.message.Image.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.Image
             * @static
             * @param {wjite.message.IImage} message Image message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Image.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.src != null && Object.hasOwnProperty.call(message, "src"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.src);
                if (message.blob != null && Object.hasOwnProperty.call(message, "blob"))
                    writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.blob);
                return writer;
            };

            /**
             * Encodes the specified Image message, length delimited. Does not implicitly {@link wjite.message.Image.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.Image
             * @static
             * @param {wjite.message.IImage} message Image message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Image.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Image message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.Image
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.Image} Image
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Image.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.Image();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2:
                        message.src = reader.string();
                        break;
                    case 3:
                        message.blob = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Image message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.Image
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.Image} Image
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Image.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Image message.
             * @function verify
             * @memberof wjite.message.Image
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Image.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.src != null && message.hasOwnProperty("src"))
                    if (!$util.isString(message.src))
                        return "src: string expected";
                if (message.blob != null && message.hasOwnProperty("blob"))
                    if (!(message.blob && typeof message.blob.length === "number" || $util.isString(message.blob)))
                        return "blob: buffer expected";
                return null;
            };

            /**
             * Creates an Image message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.Image
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.Image} Image
             */
            Image.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.Image)
                    return object;
                let message = new $root.wjite.message.Image();
                if (object.src != null)
                    message.src = String(object.src);
                if (object.blob != null)
                    if (typeof object.blob === "string")
                        $util.base64.decode(object.blob, message.blob = $util.newBuffer($util.base64.length(object.blob)), 0);
                    else if (object.blob.length)
                        message.blob = object.blob;
                return message;
            };

            /**
             * Creates a plain object from an Image message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.Image
             * @static
             * @param {wjite.message.Image} message Image
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Image.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.src = "";
                    if (options.bytes === String)
                        object.blob = "";
                    else {
                        object.blob = [];
                        if (options.bytes !== Array)
                            object.blob = $util.newBuffer(object.blob);
                    }
                }
                if (message.src != null && message.hasOwnProperty("src"))
                    object.src = message.src;
                if (message.blob != null && message.hasOwnProperty("blob"))
                    object.blob = options.bytes === String ? $util.base64.encode(message.blob, 0, message.blob.length) : options.bytes === Array ? Array.prototype.slice.call(message.blob) : message.blob;
                return object;
            };

            /**
             * Converts this Image to JSON.
             * @function toJSON
             * @memberof wjite.message.Image
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Image.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Image;
        })();

        message.Shape = (function() {

            /**
             * Properties of a Shape.
             * @memberof wjite.message
             * @interface IShape
             * @property {Long|null} [ID] Shape ID
             * @property {Long|null} [ownerID] Shape ownerID
             * @property {wjite.message.ITransform|null} [transform] Shape transform
             * @property {wjite.message.IStroke|null} [stroke] Shape stroke
             * @property {wjite.message.IFigure|null} [figure] Shape figure
             * @property {wjite.message.IText|null} [text] Shape text
             * @property {wjite.message.ITextArea|null} [textArea] Shape textArea
             * @property {wjite.message.ISticker|null} [sticker] Shape sticker
             * @property {wjite.message.IImage|null} [image] Shape image
             */

            /**
             * Constructs a new Shape.
             * @memberof wjite.message
             * @classdesc Represents a Shape.
             * @implements IShape
             * @constructor
             * @param {wjite.message.IShape=} [properties] Properties to set
             */
            function Shape(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Shape ID.
             * @member {Long} ID
             * @memberof wjite.message.Shape
             * @instance
             */
            Shape.prototype.ID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Shape ownerID.
             * @member {Long} ownerID
             * @memberof wjite.message.Shape
             * @instance
             */
            Shape.prototype.ownerID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Shape transform.
             * @member {wjite.message.ITransform|null|undefined} transform
             * @memberof wjite.message.Shape
             * @instance
             */
            Shape.prototype.transform = null;

            /**
             * Shape stroke.
             * @member {wjite.message.IStroke|null|undefined} stroke
             * @memberof wjite.message.Shape
             * @instance
             */
            Shape.prototype.stroke = null;

            /**
             * Shape figure.
             * @member {wjite.message.IFigure|null|undefined} figure
             * @memberof wjite.message.Shape
             * @instance
             */
            Shape.prototype.figure = null;

            /**
             * Shape text.
             * @member {wjite.message.IText|null|undefined} text
             * @memberof wjite.message.Shape
             * @instance
             */
            Shape.prototype.text = null;

            /**
             * Shape textArea.
             * @member {wjite.message.ITextArea|null|undefined} textArea
             * @memberof wjite.message.Shape
             * @instance
             */
            Shape.prototype.textArea = null;

            /**
             * Shape sticker.
             * @member {wjite.message.ISticker|null|undefined} sticker
             * @memberof wjite.message.Shape
             * @instance
             */
            Shape.prototype.sticker = null;

            /**
             * Shape image.
             * @member {wjite.message.IImage|null|undefined} image
             * @memberof wjite.message.Shape
             * @instance
             */
            Shape.prototype.image = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * Shape shapeType.
             * @member {"stroke"|"figure"|"text"|"textArea"|"sticker"|"image"|undefined} shapeType
             * @memberof wjite.message.Shape
             * @instance
             */
            Object.defineProperty(Shape.prototype, "shapeType", {
                get: $util.oneOfGetter($oneOfFields = ["stroke", "figure", "text", "textArea", "sticker", "image"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new Shape instance using the specified properties.
             * @function create
             * @memberof wjite.message.Shape
             * @static
             * @param {wjite.message.IShape=} [properties] Properties to set
             * @returns {wjite.message.Shape} Shape instance
             */
            Shape.create = function create(properties) {
                return new Shape(properties);
            };

            /**
             * Encodes the specified Shape message. Does not implicitly {@link wjite.message.Shape.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.Shape
             * @static
             * @param {wjite.message.IShape} message Shape message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Shape.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.ID != null && Object.hasOwnProperty.call(message, "ID"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.ID);
                if (message.ownerID != null && Object.hasOwnProperty.call(message, "ownerID"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.ownerID);
                if (message.transform != null && Object.hasOwnProperty.call(message, "transform"))
                    $root.wjite.message.Transform.encode(message.transform, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.stroke != null && Object.hasOwnProperty.call(message, "stroke"))
                    $root.wjite.message.Stroke.encode(message.stroke, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.figure != null && Object.hasOwnProperty.call(message, "figure"))
                    $root.wjite.message.Figure.encode(message.figure, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                    $root.wjite.message.Text.encode(message.text, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.textArea != null && Object.hasOwnProperty.call(message, "textArea"))
                    $root.wjite.message.TextArea.encode(message.textArea, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.sticker != null && Object.hasOwnProperty.call(message, "sticker"))
                    $root.wjite.message.Sticker.encode(message.sticker, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                    $root.wjite.message.Image.encode(message.image, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Shape message, length delimited. Does not implicitly {@link wjite.message.Shape.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.Shape
             * @static
             * @param {wjite.message.IShape} message Shape message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Shape.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Shape message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.Shape
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.Shape} Shape
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Shape.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.Shape();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.ID = reader.uint64();
                        break;
                    case 2:
                        message.ownerID = reader.uint64();
                        break;
                    case 4:
                        message.transform = $root.wjite.message.Transform.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.stroke = $root.wjite.message.Stroke.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.figure = $root.wjite.message.Figure.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.text = $root.wjite.message.Text.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.textArea = $root.wjite.message.TextArea.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.sticker = $root.wjite.message.Sticker.decode(reader, reader.uint32());
                        break;
                    case 10:
                        message.image = $root.wjite.message.Image.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Shape message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.Shape
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.Shape} Shape
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Shape.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Shape message.
             * @function verify
             * @memberof wjite.message.Shape
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Shape.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.ID != null && message.hasOwnProperty("ID"))
                    if (!$util.isInteger(message.ID) && !(message.ID && $util.isInteger(message.ID.low) && $util.isInteger(message.ID.high)))
                        return "ID: integer|Long expected";
                if (message.ownerID != null && message.hasOwnProperty("ownerID"))
                    if (!$util.isInteger(message.ownerID) && !(message.ownerID && $util.isInteger(message.ownerID.low) && $util.isInteger(message.ownerID.high)))
                        return "ownerID: integer|Long expected";
                if (message.transform != null && message.hasOwnProperty("transform")) {
                    let error = $root.wjite.message.Transform.verify(message.transform);
                    if (error)
                        return "transform." + error;
                }
                if (message.stroke != null && message.hasOwnProperty("stroke")) {
                    properties.shapeType = 1;
                    {
                        let error = $root.wjite.message.Stroke.verify(message.stroke);
                        if (error)
                            return "stroke." + error;
                    }
                }
                if (message.figure != null && message.hasOwnProperty("figure")) {
                    if (properties.shapeType === 1)
                        return "shapeType: multiple values";
                    properties.shapeType = 1;
                    {
                        let error = $root.wjite.message.Figure.verify(message.figure);
                        if (error)
                            return "figure." + error;
                    }
                }
                if (message.text != null && message.hasOwnProperty("text")) {
                    if (properties.shapeType === 1)
                        return "shapeType: multiple values";
                    properties.shapeType = 1;
                    {
                        let error = $root.wjite.message.Text.verify(message.text);
                        if (error)
                            return "text." + error;
                    }
                }
                if (message.textArea != null && message.hasOwnProperty("textArea")) {
                    if (properties.shapeType === 1)
                        return "shapeType: multiple values";
                    properties.shapeType = 1;
                    {
                        let error = $root.wjite.message.TextArea.verify(message.textArea);
                        if (error)
                            return "textArea." + error;
                    }
                }
                if (message.sticker != null && message.hasOwnProperty("sticker")) {
                    if (properties.shapeType === 1)
                        return "shapeType: multiple values";
                    properties.shapeType = 1;
                    {
                        let error = $root.wjite.message.Sticker.verify(message.sticker);
                        if (error)
                            return "sticker." + error;
                    }
                }
                if (message.image != null && message.hasOwnProperty("image")) {
                    if (properties.shapeType === 1)
                        return "shapeType: multiple values";
                    properties.shapeType = 1;
                    {
                        let error = $root.wjite.message.Image.verify(message.image);
                        if (error)
                            return "image." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a Shape message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.Shape
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.Shape} Shape
             */
            Shape.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.Shape)
                    return object;
                let message = new $root.wjite.message.Shape();
                if (object.ID != null)
                    if ($util.Long)
                        (message.ID = $util.Long.fromValue(object.ID)).unsigned = true;
                    else if (typeof object.ID === "string")
                        message.ID = parseInt(object.ID, 10);
                    else if (typeof object.ID === "number")
                        message.ID = object.ID;
                    else if (typeof object.ID === "object")
                        message.ID = new $util.LongBits(object.ID.low >>> 0, object.ID.high >>> 0).toNumber(true);
                if (object.ownerID != null)
                    if ($util.Long)
                        (message.ownerID = $util.Long.fromValue(object.ownerID)).unsigned = true;
                    else if (typeof object.ownerID === "string")
                        message.ownerID = parseInt(object.ownerID, 10);
                    else if (typeof object.ownerID === "number")
                        message.ownerID = object.ownerID;
                    else if (typeof object.ownerID === "object")
                        message.ownerID = new $util.LongBits(object.ownerID.low >>> 0, object.ownerID.high >>> 0).toNumber(true);
                if (object.transform != null) {
                    if (typeof object.transform !== "object")
                        throw TypeError(".wjite.message.Shape.transform: object expected");
                    message.transform = $root.wjite.message.Transform.fromObject(object.transform);
                }
                if (object.stroke != null) {
                    if (typeof object.stroke !== "object")
                        throw TypeError(".wjite.message.Shape.stroke: object expected");
                    message.stroke = $root.wjite.message.Stroke.fromObject(object.stroke);
                }
                if (object.figure != null) {
                    if (typeof object.figure !== "object")
                        throw TypeError(".wjite.message.Shape.figure: object expected");
                    message.figure = $root.wjite.message.Figure.fromObject(object.figure);
                }
                if (object.text != null) {
                    if (typeof object.text !== "object")
                        throw TypeError(".wjite.message.Shape.text: object expected");
                    message.text = $root.wjite.message.Text.fromObject(object.text);
                }
                if (object.textArea != null) {
                    if (typeof object.textArea !== "object")
                        throw TypeError(".wjite.message.Shape.textArea: object expected");
                    message.textArea = $root.wjite.message.TextArea.fromObject(object.textArea);
                }
                if (object.sticker != null) {
                    if (typeof object.sticker !== "object")
                        throw TypeError(".wjite.message.Shape.sticker: object expected");
                    message.sticker = $root.wjite.message.Sticker.fromObject(object.sticker);
                }
                if (object.image != null) {
                    if (typeof object.image !== "object")
                        throw TypeError(".wjite.message.Shape.image: object expected");
                    message.image = $root.wjite.message.Image.fromObject(object.image);
                }
                return message;
            };

            /**
             * Creates a plain object from a Shape message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.Shape
             * @static
             * @param {wjite.message.Shape} message Shape
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Shape.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.ID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.ID = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.ownerID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.ownerID = options.longs === String ? "0" : 0;
                    object.transform = null;
                }
                if (message.ID != null && message.hasOwnProperty("ID"))
                    if (typeof message.ID === "number")
                        object.ID = options.longs === String ? String(message.ID) : message.ID;
                    else
                        object.ID = options.longs === String ? $util.Long.prototype.toString.call(message.ID) : options.longs === Number ? new $util.LongBits(message.ID.low >>> 0, message.ID.high >>> 0).toNumber(true) : message.ID;
                if (message.ownerID != null && message.hasOwnProperty("ownerID"))
                    if (typeof message.ownerID === "number")
                        object.ownerID = options.longs === String ? String(message.ownerID) : message.ownerID;
                    else
                        object.ownerID = options.longs === String ? $util.Long.prototype.toString.call(message.ownerID) : options.longs === Number ? new $util.LongBits(message.ownerID.low >>> 0, message.ownerID.high >>> 0).toNumber(true) : message.ownerID;
                if (message.transform != null && message.hasOwnProperty("transform"))
                    object.transform = $root.wjite.message.Transform.toObject(message.transform, options);
                if (message.stroke != null && message.hasOwnProperty("stroke")) {
                    object.stroke = $root.wjite.message.Stroke.toObject(message.stroke, options);
                    if (options.oneofs)
                        object.shapeType = "stroke";
                }
                if (message.figure != null && message.hasOwnProperty("figure")) {
                    object.figure = $root.wjite.message.Figure.toObject(message.figure, options);
                    if (options.oneofs)
                        object.shapeType = "figure";
                }
                if (message.text != null && message.hasOwnProperty("text")) {
                    object.text = $root.wjite.message.Text.toObject(message.text, options);
                    if (options.oneofs)
                        object.shapeType = "text";
                }
                if (message.textArea != null && message.hasOwnProperty("textArea")) {
                    object.textArea = $root.wjite.message.TextArea.toObject(message.textArea, options);
                    if (options.oneofs)
                        object.shapeType = "textArea";
                }
                if (message.sticker != null && message.hasOwnProperty("sticker")) {
                    object.sticker = $root.wjite.message.Sticker.toObject(message.sticker, options);
                    if (options.oneofs)
                        object.shapeType = "sticker";
                }
                if (message.image != null && message.hasOwnProperty("image")) {
                    object.image = $root.wjite.message.Image.toObject(message.image, options);
                    if (options.oneofs)
                        object.shapeType = "image";
                }
                return object;
            };

            /**
             * Converts this Shape to JSON.
             * @function toJSON
             * @memberof wjite.message.Shape
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Shape.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Shape;
        })();

        message.AddShape = (function() {

            /**
             * Properties of an AddShape.
             * @memberof wjite.message
             * @interface IAddShape
             * @property {wjite.message.IShape|null} [shape] AddShape shape
             * @property {Long|null} [tempId] AddShape tempId
             */

            /**
             * Constructs a new AddShape.
             * @memberof wjite.message
             * @classdesc Represents an AddShape.
             * @implements IAddShape
             * @constructor
             * @param {wjite.message.IAddShape=} [properties] Properties to set
             */
            function AddShape(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AddShape shape.
             * @member {wjite.message.IShape|null|undefined} shape
             * @memberof wjite.message.AddShape
             * @instance
             */
            AddShape.prototype.shape = null;

            /**
             * AddShape tempId.
             * @member {Long} tempId
             * @memberof wjite.message.AddShape
             * @instance
             */
            AddShape.prototype.tempId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new AddShape instance using the specified properties.
             * @function create
             * @memberof wjite.message.AddShape
             * @static
             * @param {wjite.message.IAddShape=} [properties] Properties to set
             * @returns {wjite.message.AddShape} AddShape instance
             */
            AddShape.create = function create(properties) {
                return new AddShape(properties);
            };

            /**
             * Encodes the specified AddShape message. Does not implicitly {@link wjite.message.AddShape.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.AddShape
             * @static
             * @param {wjite.message.IAddShape} message AddShape message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddShape.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.shape != null && Object.hasOwnProperty.call(message, "shape"))
                    $root.wjite.message.Shape.encode(message.shape, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.tempId != null && Object.hasOwnProperty.call(message, "tempId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.tempId);
                return writer;
            };

            /**
             * Encodes the specified AddShape message, length delimited. Does not implicitly {@link wjite.message.AddShape.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.AddShape
             * @static
             * @param {wjite.message.IAddShape} message AddShape message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddShape.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AddShape message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.AddShape
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.AddShape} AddShape
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddShape.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.AddShape();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.shape = $root.wjite.message.Shape.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.tempId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AddShape message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.AddShape
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.AddShape} AddShape
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddShape.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AddShape message.
             * @function verify
             * @memberof wjite.message.AddShape
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddShape.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.shape != null && message.hasOwnProperty("shape")) {
                    let error = $root.wjite.message.Shape.verify(message.shape);
                    if (error)
                        return "shape." + error;
                }
                if (message.tempId != null && message.hasOwnProperty("tempId"))
                    if (!$util.isInteger(message.tempId) && !(message.tempId && $util.isInteger(message.tempId.low) && $util.isInteger(message.tempId.high)))
                        return "tempId: integer|Long expected";
                return null;
            };

            /**
             * Creates an AddShape message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.AddShape
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.AddShape} AddShape
             */
            AddShape.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.AddShape)
                    return object;
                let message = new $root.wjite.message.AddShape();
                if (object.shape != null) {
                    if (typeof object.shape !== "object")
                        throw TypeError(".wjite.message.AddShape.shape: object expected");
                    message.shape = $root.wjite.message.Shape.fromObject(object.shape);
                }
                if (object.tempId != null)
                    if ($util.Long)
                        (message.tempId = $util.Long.fromValue(object.tempId)).unsigned = true;
                    else if (typeof object.tempId === "string")
                        message.tempId = parseInt(object.tempId, 10);
                    else if (typeof object.tempId === "number")
                        message.tempId = object.tempId;
                    else if (typeof object.tempId === "object")
                        message.tempId = new $util.LongBits(object.tempId.low >>> 0, object.tempId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from an AddShape message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.AddShape
             * @static
             * @param {wjite.message.AddShape} message AddShape
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddShape.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.shape = null;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.tempId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.tempId = options.longs === String ? "0" : 0;
                }
                if (message.shape != null && message.hasOwnProperty("shape"))
                    object.shape = $root.wjite.message.Shape.toObject(message.shape, options);
                if (message.tempId != null && message.hasOwnProperty("tempId"))
                    if (typeof message.tempId === "number")
                        object.tempId = options.longs === String ? String(message.tempId) : message.tempId;
                    else
                        object.tempId = options.longs === String ? $util.Long.prototype.toString.call(message.tempId) : options.longs === Number ? new $util.LongBits(message.tempId.low >>> 0, message.tempId.high >>> 0).toNumber(true) : message.tempId;
                return object;
            };

            /**
             * Converts this AddShape to JSON.
             * @function toJSON
             * @memberof wjite.message.AddShape
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddShape.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return AddShape;
        })();

        message.AddHighlight = (function() {

            /**
             * Properties of an AddHighlight.
             * @memberof wjite.message
             * @interface IAddHighlight
             * @property {wjite.message.IShape|null} [shape] AddHighlight shape
             * @property {boolean|null} [focus] AddHighlight focus
             */

            /**
             * Constructs a new AddHighlight.
             * @memberof wjite.message
             * @classdesc Represents an AddHighlight.
             * @implements IAddHighlight
             * @constructor
             * @param {wjite.message.IAddHighlight=} [properties] Properties to set
             */
            function AddHighlight(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AddHighlight shape.
             * @member {wjite.message.IShape|null|undefined} shape
             * @memberof wjite.message.AddHighlight
             * @instance
             */
            AddHighlight.prototype.shape = null;

            /**
             * AddHighlight focus.
             * @member {boolean} focus
             * @memberof wjite.message.AddHighlight
             * @instance
             */
            AddHighlight.prototype.focus = false;

            /**
             * Creates a new AddHighlight instance using the specified properties.
             * @function create
             * @memberof wjite.message.AddHighlight
             * @static
             * @param {wjite.message.IAddHighlight=} [properties] Properties to set
             * @returns {wjite.message.AddHighlight} AddHighlight instance
             */
            AddHighlight.create = function create(properties) {
                return new AddHighlight(properties);
            };

            /**
             * Encodes the specified AddHighlight message. Does not implicitly {@link wjite.message.AddHighlight.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.AddHighlight
             * @static
             * @param {wjite.message.IAddHighlight} message AddHighlight message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddHighlight.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.shape != null && Object.hasOwnProperty.call(message, "shape"))
                    $root.wjite.message.Shape.encode(message.shape, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.focus != null && Object.hasOwnProperty.call(message, "focus"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.focus);
                return writer;
            };

            /**
             * Encodes the specified AddHighlight message, length delimited. Does not implicitly {@link wjite.message.AddHighlight.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.AddHighlight
             * @static
             * @param {wjite.message.IAddHighlight} message AddHighlight message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddHighlight.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AddHighlight message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.AddHighlight
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.AddHighlight} AddHighlight
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddHighlight.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.AddHighlight();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.shape = $root.wjite.message.Shape.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.focus = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AddHighlight message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.AddHighlight
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.AddHighlight} AddHighlight
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddHighlight.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AddHighlight message.
             * @function verify
             * @memberof wjite.message.AddHighlight
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddHighlight.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.shape != null && message.hasOwnProperty("shape")) {
                    let error = $root.wjite.message.Shape.verify(message.shape);
                    if (error)
                        return "shape." + error;
                }
                if (message.focus != null && message.hasOwnProperty("focus"))
                    if (typeof message.focus !== "boolean")
                        return "focus: boolean expected";
                return null;
            };

            /**
             * Creates an AddHighlight message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.AddHighlight
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.AddHighlight} AddHighlight
             */
            AddHighlight.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.AddHighlight)
                    return object;
                let message = new $root.wjite.message.AddHighlight();
                if (object.shape != null) {
                    if (typeof object.shape !== "object")
                        throw TypeError(".wjite.message.AddHighlight.shape: object expected");
                    message.shape = $root.wjite.message.Shape.fromObject(object.shape);
                }
                if (object.focus != null)
                    message.focus = Boolean(object.focus);
                return message;
            };

            /**
             * Creates a plain object from an AddHighlight message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.AddHighlight
             * @static
             * @param {wjite.message.AddHighlight} message AddHighlight
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddHighlight.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.shape = null;
                    object.focus = false;
                }
                if (message.shape != null && message.hasOwnProperty("shape"))
                    object.shape = $root.wjite.message.Shape.toObject(message.shape, options);
                if (message.focus != null && message.hasOwnProperty("focus"))
                    object.focus = message.focus;
                return object;
            };

            /**
             * Converts this AddHighlight to JSON.
             * @function toJSON
             * @memberof wjite.message.AddHighlight
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddHighlight.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return AddHighlight;
        })();

        message.Notify = (function() {

            /**
             * Properties of a Notify.
             * @memberof wjite.message
             * @interface INotify
             * @property {wjite.message.IShape|null} [shape] Notify shape
             */

            /**
             * Constructs a new Notify.
             * @memberof wjite.message
             * @classdesc Represents a Notify.
             * @implements INotify
             * @constructor
             * @param {wjite.message.INotify=} [properties] Properties to set
             */
            function Notify(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Notify shape.
             * @member {wjite.message.IShape|null|undefined} shape
             * @memberof wjite.message.Notify
             * @instance
             */
            Notify.prototype.shape = null;

            /**
             * Creates a new Notify instance using the specified properties.
             * @function create
             * @memberof wjite.message.Notify
             * @static
             * @param {wjite.message.INotify=} [properties] Properties to set
             * @returns {wjite.message.Notify} Notify instance
             */
            Notify.create = function create(properties) {
                return new Notify(properties);
            };

            /**
             * Encodes the specified Notify message. Does not implicitly {@link wjite.message.Notify.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.Notify
             * @static
             * @param {wjite.message.INotify} message Notify message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Notify.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.shape != null && Object.hasOwnProperty.call(message, "shape"))
                    $root.wjite.message.Shape.encode(message.shape, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Notify message, length delimited. Does not implicitly {@link wjite.message.Notify.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.Notify
             * @static
             * @param {wjite.message.INotify} message Notify message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Notify.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Notify message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.Notify
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.Notify} Notify
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Notify.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.Notify();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.shape = $root.wjite.message.Shape.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Notify message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.Notify
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.Notify} Notify
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Notify.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Notify message.
             * @function verify
             * @memberof wjite.message.Notify
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Notify.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.shape != null && message.hasOwnProperty("shape")) {
                    let error = $root.wjite.message.Shape.verify(message.shape);
                    if (error)
                        return "shape." + error;
                }
                return null;
            };

            /**
             * Creates a Notify message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.Notify
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.Notify} Notify
             */
            Notify.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.Notify)
                    return object;
                let message = new $root.wjite.message.Notify();
                if (object.shape != null) {
                    if (typeof object.shape !== "object")
                        throw TypeError(".wjite.message.Notify.shape: object expected");
                    message.shape = $root.wjite.message.Shape.fromObject(object.shape);
                }
                return message;
            };

            /**
             * Creates a plain object from a Notify message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.Notify
             * @static
             * @param {wjite.message.Notify} message Notify
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Notify.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.shape = null;
                if (message.shape != null && message.hasOwnProperty("shape"))
                    object.shape = $root.wjite.message.Shape.toObject(message.shape, options);
                return object;
            };

            /**
             * Converts this Notify to JSON.
             * @function toJSON
             * @memberof wjite.message.Notify
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Notify.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Notify;
        })();

        message.UpdateShape = (function() {

            /**
             * Properties of an UpdateShape.
             * @memberof wjite.message
             * @interface IUpdateShape
             * @property {wjite.message.IShape|null} [shape] UpdateShape shape
             */

            /**
             * Constructs a new UpdateShape.
             * @memberof wjite.message
             * @classdesc Represents an UpdateShape.
             * @implements IUpdateShape
             * @constructor
             * @param {wjite.message.IUpdateShape=} [properties] Properties to set
             */
            function UpdateShape(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateShape shape.
             * @member {wjite.message.IShape|null|undefined} shape
             * @memberof wjite.message.UpdateShape
             * @instance
             */
            UpdateShape.prototype.shape = null;

            /**
             * Creates a new UpdateShape instance using the specified properties.
             * @function create
             * @memberof wjite.message.UpdateShape
             * @static
             * @param {wjite.message.IUpdateShape=} [properties] Properties to set
             * @returns {wjite.message.UpdateShape} UpdateShape instance
             */
            UpdateShape.create = function create(properties) {
                return new UpdateShape(properties);
            };

            /**
             * Encodes the specified UpdateShape message. Does not implicitly {@link wjite.message.UpdateShape.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.UpdateShape
             * @static
             * @param {wjite.message.IUpdateShape} message UpdateShape message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateShape.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.shape != null && Object.hasOwnProperty.call(message, "shape"))
                    $root.wjite.message.Shape.encode(message.shape, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified UpdateShape message, length delimited. Does not implicitly {@link wjite.message.UpdateShape.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.UpdateShape
             * @static
             * @param {wjite.message.IUpdateShape} message UpdateShape message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateShape.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdateShape message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.UpdateShape
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.UpdateShape} UpdateShape
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateShape.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.UpdateShape();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.shape = $root.wjite.message.Shape.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdateShape message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.UpdateShape
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.UpdateShape} UpdateShape
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateShape.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdateShape message.
             * @function verify
             * @memberof wjite.message.UpdateShape
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateShape.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.shape != null && message.hasOwnProperty("shape")) {
                    let error = $root.wjite.message.Shape.verify(message.shape);
                    if (error)
                        return "shape." + error;
                }
                return null;
            };

            /**
             * Creates an UpdateShape message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.UpdateShape
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.UpdateShape} UpdateShape
             */
            UpdateShape.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.UpdateShape)
                    return object;
                let message = new $root.wjite.message.UpdateShape();
                if (object.shape != null) {
                    if (typeof object.shape !== "object")
                        throw TypeError(".wjite.message.UpdateShape.shape: object expected");
                    message.shape = $root.wjite.message.Shape.fromObject(object.shape);
                }
                return message;
            };

            /**
             * Creates a plain object from an UpdateShape message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.UpdateShape
             * @static
             * @param {wjite.message.UpdateShape} message UpdateShape
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateShape.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.shape = null;
                if (message.shape != null && message.hasOwnProperty("shape"))
                    object.shape = $root.wjite.message.Shape.toObject(message.shape, options);
                return object;
            };

            /**
             * Converts this UpdateShape to JSON.
             * @function toJSON
             * @memberof wjite.message.UpdateShape
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateShape.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateShape;
        })();

        message.RemoveShape = (function() {

            /**
             * Properties of a RemoveShape.
             * @memberof wjite.message
             * @interface IRemoveShape
             * @property {Long|null} [shapeID] RemoveShape shapeID
             */

            /**
             * Constructs a new RemoveShape.
             * @memberof wjite.message
             * @classdesc Represents a RemoveShape.
             * @implements IRemoveShape
             * @constructor
             * @param {wjite.message.IRemoveShape=} [properties] Properties to set
             */
            function RemoveShape(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RemoveShape shapeID.
             * @member {Long} shapeID
             * @memberof wjite.message.RemoveShape
             * @instance
             */
            RemoveShape.prototype.shapeID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new RemoveShape instance using the specified properties.
             * @function create
             * @memberof wjite.message.RemoveShape
             * @static
             * @param {wjite.message.IRemoveShape=} [properties] Properties to set
             * @returns {wjite.message.RemoveShape} RemoveShape instance
             */
            RemoveShape.create = function create(properties) {
                return new RemoveShape(properties);
            };

            /**
             * Encodes the specified RemoveShape message. Does not implicitly {@link wjite.message.RemoveShape.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.RemoveShape
             * @static
             * @param {wjite.message.IRemoveShape} message RemoveShape message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RemoveShape.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.shapeID != null && Object.hasOwnProperty.call(message, "shapeID"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.shapeID);
                return writer;
            };

            /**
             * Encodes the specified RemoveShape message, length delimited. Does not implicitly {@link wjite.message.RemoveShape.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.RemoveShape
             * @static
             * @param {wjite.message.IRemoveShape} message RemoveShape message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RemoveShape.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RemoveShape message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.RemoveShape
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.RemoveShape} RemoveShape
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RemoveShape.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.RemoveShape();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.shapeID = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RemoveShape message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.RemoveShape
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.RemoveShape} RemoveShape
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RemoveShape.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RemoveShape message.
             * @function verify
             * @memberof wjite.message.RemoveShape
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RemoveShape.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.shapeID != null && message.hasOwnProperty("shapeID"))
                    if (!$util.isInteger(message.shapeID) && !(message.shapeID && $util.isInteger(message.shapeID.low) && $util.isInteger(message.shapeID.high)))
                        return "shapeID: integer|Long expected";
                return null;
            };

            /**
             * Creates a RemoveShape message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.RemoveShape
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.RemoveShape} RemoveShape
             */
            RemoveShape.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.RemoveShape)
                    return object;
                let message = new $root.wjite.message.RemoveShape();
                if (object.shapeID != null)
                    if ($util.Long)
                        (message.shapeID = $util.Long.fromValue(object.shapeID)).unsigned = true;
                    else if (typeof object.shapeID === "string")
                        message.shapeID = parseInt(object.shapeID, 10);
                    else if (typeof object.shapeID === "number")
                        message.shapeID = object.shapeID;
                    else if (typeof object.shapeID === "object")
                        message.shapeID = new $util.LongBits(object.shapeID.low >>> 0, object.shapeID.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a RemoveShape message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.RemoveShape
             * @static
             * @param {wjite.message.RemoveShape} message RemoveShape
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RemoveShape.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.shapeID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.shapeID = options.longs === String ? "0" : 0;
                if (message.shapeID != null && message.hasOwnProperty("shapeID"))
                    if (typeof message.shapeID === "number")
                        object.shapeID = options.longs === String ? String(message.shapeID) : message.shapeID;
                    else
                        object.shapeID = options.longs === String ? $util.Long.prototype.toString.call(message.shapeID) : options.longs === Number ? new $util.LongBits(message.shapeID.low >>> 0, message.shapeID.high >>> 0).toNumber(true) : message.shapeID;
                return object;
            };

            /**
             * Converts this RemoveShape to JSON.
             * @function toJSON
             * @memberof wjite.message.RemoveShape
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RemoveShape.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RemoveShape;
        })();

        message.Clear = (function() {

            /**
             * Properties of a Clear.
             * @memberof wjite.message
             * @interface IClear
             */

            /**
             * Constructs a new Clear.
             * @memberof wjite.message
             * @classdesc Represents a Clear.
             * @implements IClear
             * @constructor
             * @param {wjite.message.IClear=} [properties] Properties to set
             */
            function Clear(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new Clear instance using the specified properties.
             * @function create
             * @memberof wjite.message.Clear
             * @static
             * @param {wjite.message.IClear=} [properties] Properties to set
             * @returns {wjite.message.Clear} Clear instance
             */
            Clear.create = function create(properties) {
                return new Clear(properties);
            };

            /**
             * Encodes the specified Clear message. Does not implicitly {@link wjite.message.Clear.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.Clear
             * @static
             * @param {wjite.message.IClear} message Clear message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Clear.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified Clear message, length delimited. Does not implicitly {@link wjite.message.Clear.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.Clear
             * @static
             * @param {wjite.message.IClear} message Clear message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Clear.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Clear message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.Clear
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.Clear} Clear
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Clear.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.Clear();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Clear message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.Clear
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.Clear} Clear
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Clear.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Clear message.
             * @function verify
             * @memberof wjite.message.Clear
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Clear.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a Clear message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.Clear
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.Clear} Clear
             */
            Clear.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.Clear)
                    return object;
                return new $root.wjite.message.Clear();
            };

            /**
             * Creates a plain object from a Clear message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.Clear
             * @static
             * @param {wjite.message.Clear} message Clear
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Clear.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this Clear to JSON.
             * @function toJSON
             * @memberof wjite.message.Clear
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Clear.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Clear;
        })();

        message.ShapeID = (function() {

            /**
             * Properties of a ShapeID.
             * @memberof wjite.message
             * @interface IShapeID
             * @property {Long|null} [shapeID] ShapeID shapeID
             */

            /**
             * Constructs a new ShapeID.
             * @memberof wjite.message
             * @classdesc Represents a ShapeID.
             * @implements IShapeID
             * @constructor
             * @param {wjite.message.IShapeID=} [properties] Properties to set
             */
            function ShapeID(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ShapeID shapeID.
             * @member {Long} shapeID
             * @memberof wjite.message.ShapeID
             * @instance
             */
            ShapeID.prototype.shapeID = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new ShapeID instance using the specified properties.
             * @function create
             * @memberof wjite.message.ShapeID
             * @static
             * @param {wjite.message.IShapeID=} [properties] Properties to set
             * @returns {wjite.message.ShapeID} ShapeID instance
             */
            ShapeID.create = function create(properties) {
                return new ShapeID(properties);
            };

            /**
             * Encodes the specified ShapeID message. Does not implicitly {@link wjite.message.ShapeID.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.ShapeID
             * @static
             * @param {wjite.message.IShapeID} message ShapeID message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ShapeID.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.shapeID != null && Object.hasOwnProperty.call(message, "shapeID"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.shapeID);
                return writer;
            };

            /**
             * Encodes the specified ShapeID message, length delimited. Does not implicitly {@link wjite.message.ShapeID.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.ShapeID
             * @static
             * @param {wjite.message.IShapeID} message ShapeID message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ShapeID.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ShapeID message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.ShapeID
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.ShapeID} ShapeID
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ShapeID.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.ShapeID();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.shapeID = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ShapeID message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.ShapeID
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.ShapeID} ShapeID
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ShapeID.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ShapeID message.
             * @function verify
             * @memberof wjite.message.ShapeID
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ShapeID.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.shapeID != null && message.hasOwnProperty("shapeID"))
                    if (!$util.isInteger(message.shapeID) && !(message.shapeID && $util.isInteger(message.shapeID.low) && $util.isInteger(message.shapeID.high)))
                        return "shapeID: integer|Long expected";
                return null;
            };

            /**
             * Creates a ShapeID message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.ShapeID
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.ShapeID} ShapeID
             */
            ShapeID.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.ShapeID)
                    return object;
                let message = new $root.wjite.message.ShapeID();
                if (object.shapeID != null)
                    if ($util.Long)
                        (message.shapeID = $util.Long.fromValue(object.shapeID)).unsigned = true;
                    else if (typeof object.shapeID === "string")
                        message.shapeID = parseInt(object.shapeID, 10);
                    else if (typeof object.shapeID === "number")
                        message.shapeID = object.shapeID;
                    else if (typeof object.shapeID === "object")
                        message.shapeID = new $util.LongBits(object.shapeID.low >>> 0, object.shapeID.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a ShapeID message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.ShapeID
             * @static
             * @param {wjite.message.ShapeID} message ShapeID
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ShapeID.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.shapeID = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.shapeID = options.longs === String ? "0" : 0;
                if (message.shapeID != null && message.hasOwnProperty("shapeID"))
                    if (typeof message.shapeID === "number")
                        object.shapeID = options.longs === String ? String(message.shapeID) : message.shapeID;
                    else
                        object.shapeID = options.longs === String ? $util.Long.prototype.toString.call(message.shapeID) : options.longs === Number ? new $util.LongBits(message.shapeID.low >>> 0, message.shapeID.high >>> 0).toNumber(true) : message.shapeID;
                return object;
            };

            /**
             * Converts this ShapeID to JSON.
             * @function toJSON
             * @memberof wjite.message.ShapeID
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ShapeID.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ShapeID;
        })();

        message.ShapeUpdated = (function() {

            /**
             * Properties of a ShapeUpdated.
             * @memberof wjite.message
             * @interface IShapeUpdated
             */

            /**
             * Constructs a new ShapeUpdated.
             * @memberof wjite.message
             * @classdesc Represents a ShapeUpdated.
             * @implements IShapeUpdated
             * @constructor
             * @param {wjite.message.IShapeUpdated=} [properties] Properties to set
             */
            function ShapeUpdated(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new ShapeUpdated instance using the specified properties.
             * @function create
             * @memberof wjite.message.ShapeUpdated
             * @static
             * @param {wjite.message.IShapeUpdated=} [properties] Properties to set
             * @returns {wjite.message.ShapeUpdated} ShapeUpdated instance
             */
            ShapeUpdated.create = function create(properties) {
                return new ShapeUpdated(properties);
            };

            /**
             * Encodes the specified ShapeUpdated message. Does not implicitly {@link wjite.message.ShapeUpdated.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.ShapeUpdated
             * @static
             * @param {wjite.message.IShapeUpdated} message ShapeUpdated message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ShapeUpdated.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified ShapeUpdated message, length delimited. Does not implicitly {@link wjite.message.ShapeUpdated.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.ShapeUpdated
             * @static
             * @param {wjite.message.IShapeUpdated} message ShapeUpdated message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ShapeUpdated.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ShapeUpdated message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.ShapeUpdated
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.ShapeUpdated} ShapeUpdated
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ShapeUpdated.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.ShapeUpdated();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ShapeUpdated message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.ShapeUpdated
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.ShapeUpdated} ShapeUpdated
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ShapeUpdated.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ShapeUpdated message.
             * @function verify
             * @memberof wjite.message.ShapeUpdated
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ShapeUpdated.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a ShapeUpdated message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.ShapeUpdated
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.ShapeUpdated} ShapeUpdated
             */
            ShapeUpdated.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.ShapeUpdated)
                    return object;
                return new $root.wjite.message.ShapeUpdated();
            };

            /**
             * Creates a plain object from a ShapeUpdated message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.ShapeUpdated
             * @static
             * @param {wjite.message.ShapeUpdated} message ShapeUpdated
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ShapeUpdated.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this ShapeUpdated to JSON.
             * @function toJSON
             * @memberof wjite.message.ShapeUpdated
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ShapeUpdated.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ShapeUpdated;
        })();

        message.ShapeRemoved = (function() {

            /**
             * Properties of a ShapeRemoved.
             * @memberof wjite.message
             * @interface IShapeRemoved
             */

            /**
             * Constructs a new ShapeRemoved.
             * @memberof wjite.message
             * @classdesc Represents a ShapeRemoved.
             * @implements IShapeRemoved
             * @constructor
             * @param {wjite.message.IShapeRemoved=} [properties] Properties to set
             */
            function ShapeRemoved(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new ShapeRemoved instance using the specified properties.
             * @function create
             * @memberof wjite.message.ShapeRemoved
             * @static
             * @param {wjite.message.IShapeRemoved=} [properties] Properties to set
             * @returns {wjite.message.ShapeRemoved} ShapeRemoved instance
             */
            ShapeRemoved.create = function create(properties) {
                return new ShapeRemoved(properties);
            };

            /**
             * Encodes the specified ShapeRemoved message. Does not implicitly {@link wjite.message.ShapeRemoved.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.ShapeRemoved
             * @static
             * @param {wjite.message.IShapeRemoved} message ShapeRemoved message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ShapeRemoved.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified ShapeRemoved message, length delimited. Does not implicitly {@link wjite.message.ShapeRemoved.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.ShapeRemoved
             * @static
             * @param {wjite.message.IShapeRemoved} message ShapeRemoved message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ShapeRemoved.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ShapeRemoved message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.ShapeRemoved
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.ShapeRemoved} ShapeRemoved
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ShapeRemoved.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.ShapeRemoved();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ShapeRemoved message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.ShapeRemoved
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.ShapeRemoved} ShapeRemoved
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ShapeRemoved.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ShapeRemoved message.
             * @function verify
             * @memberof wjite.message.ShapeRemoved
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ShapeRemoved.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a ShapeRemoved message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.ShapeRemoved
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.ShapeRemoved} ShapeRemoved
             */
            ShapeRemoved.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.ShapeRemoved)
                    return object;
                return new $root.wjite.message.ShapeRemoved();
            };

            /**
             * Creates a plain object from a ShapeRemoved message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.ShapeRemoved
             * @static
             * @param {wjite.message.ShapeRemoved} message ShapeRemoved
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ShapeRemoved.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this ShapeRemoved to JSON.
             * @function toJSON
             * @memberof wjite.message.ShapeRemoved
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ShapeRemoved.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ShapeRemoved;
        })();

        message.BoardCleared = (function() {

            /**
             * Properties of a BoardCleared.
             * @memberof wjite.message
             * @interface IBoardCleared
             */

            /**
             * Constructs a new BoardCleared.
             * @memberof wjite.message
             * @classdesc Represents a BoardCleared.
             * @implements IBoardCleared
             * @constructor
             * @param {wjite.message.IBoardCleared=} [properties] Properties to set
             */
            function BoardCleared(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new BoardCleared instance using the specified properties.
             * @function create
             * @memberof wjite.message.BoardCleared
             * @static
             * @param {wjite.message.IBoardCleared=} [properties] Properties to set
             * @returns {wjite.message.BoardCleared} BoardCleared instance
             */
            BoardCleared.create = function create(properties) {
                return new BoardCleared(properties);
            };

            /**
             * Encodes the specified BoardCleared message. Does not implicitly {@link wjite.message.BoardCleared.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.BoardCleared
             * @static
             * @param {wjite.message.IBoardCleared} message BoardCleared message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BoardCleared.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified BoardCleared message, length delimited. Does not implicitly {@link wjite.message.BoardCleared.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.BoardCleared
             * @static
             * @param {wjite.message.IBoardCleared} message BoardCleared message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BoardCleared.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BoardCleared message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.BoardCleared
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.BoardCleared} BoardCleared
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BoardCleared.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.BoardCleared();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BoardCleared message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.BoardCleared
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.BoardCleared} BoardCleared
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BoardCleared.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BoardCleared message.
             * @function verify
             * @memberof wjite.message.BoardCleared
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BoardCleared.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a BoardCleared message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.BoardCleared
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.BoardCleared} BoardCleared
             */
            BoardCleared.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.BoardCleared)
                    return object;
                return new $root.wjite.message.BoardCleared();
            };

            /**
             * Creates a plain object from a BoardCleared message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.BoardCleared
             * @static
             * @param {wjite.message.BoardCleared} message BoardCleared
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BoardCleared.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this BoardCleared to JSON.
             * @function toJSON
             * @memberof wjite.message.BoardCleared
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BoardCleared.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return BoardCleared;
        })();

        message.Content = (function() {

            /**
             * Properties of a Content.
             * @memberof wjite.message
             * @interface IContent
             * @property {Array.<wjite.message.IShape>|null} [shapes] Content shapes
             */

            /**
             * Constructs a new Content.
             * @memberof wjite.message
             * @classdesc Represents a Content.
             * @implements IContent
             * @constructor
             * @param {wjite.message.IContent=} [properties] Properties to set
             */
            function Content(properties) {
                this.shapes = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Content shapes.
             * @member {Array.<wjite.message.IShape>} shapes
             * @memberof wjite.message.Content
             * @instance
             */
            Content.prototype.shapes = $util.emptyArray;

            /**
             * Creates a new Content instance using the specified properties.
             * @function create
             * @memberof wjite.message.Content
             * @static
             * @param {wjite.message.IContent=} [properties] Properties to set
             * @returns {wjite.message.Content} Content instance
             */
            Content.create = function create(properties) {
                return new Content(properties);
            };

            /**
             * Encodes the specified Content message. Does not implicitly {@link wjite.message.Content.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.Content
             * @static
             * @param {wjite.message.IContent} message Content message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Content.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.shapes != null && message.shapes.length)
                    for (let i = 0; i < message.shapes.length; ++i)
                        $root.wjite.message.Shape.encode(message.shapes[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Content message, length delimited. Does not implicitly {@link wjite.message.Content.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.Content
             * @static
             * @param {wjite.message.IContent} message Content message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Content.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Content message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.Content
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.Content} Content
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Content.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.Content();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.shapes && message.shapes.length))
                            message.shapes = [];
                        message.shapes.push($root.wjite.message.Shape.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Content message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.Content
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.Content} Content
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Content.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Content message.
             * @function verify
             * @memberof wjite.message.Content
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Content.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.shapes != null && message.hasOwnProperty("shapes")) {
                    if (!Array.isArray(message.shapes))
                        return "shapes: array expected";
                    for (let i = 0; i < message.shapes.length; ++i) {
                        let error = $root.wjite.message.Shape.verify(message.shapes[i]);
                        if (error)
                            return "shapes." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a Content message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.Content
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.Content} Content
             */
            Content.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.Content)
                    return object;
                let message = new $root.wjite.message.Content();
                if (object.shapes) {
                    if (!Array.isArray(object.shapes))
                        throw TypeError(".wjite.message.Content.shapes: array expected");
                    message.shapes = [];
                    for (let i = 0; i < object.shapes.length; ++i) {
                        if (typeof object.shapes[i] !== "object")
                            throw TypeError(".wjite.message.Content.shapes: object expected");
                        message.shapes[i] = $root.wjite.message.Shape.fromObject(object.shapes[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a Content message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.Content
             * @static
             * @param {wjite.message.Content} message Content
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Content.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.shapes = [];
                if (message.shapes && message.shapes.length) {
                    object.shapes = [];
                    for (let j = 0; j < message.shapes.length; ++j)
                        object.shapes[j] = $root.wjite.message.Shape.toObject(message.shapes[j], options);
                }
                return object;
            };

            /**
             * Converts this Content to JSON.
             * @function toJSON
             * @memberof wjite.message.Content
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Content.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Content;
        })();

        message.RequestBoard = (function() {

            /**
             * Properties of a RequestBoard.
             * @memberof wjite.message
             * @interface IRequestBoard
             * @property {string|null} [boardName] RequestBoard boardName
             * @property {string|null} [sessionID] RequestBoard sessionID
             */

            /**
             * Constructs a new RequestBoard.
             * @memberof wjite.message
             * @classdesc Represents a RequestBoard.
             * @implements IRequestBoard
             * @constructor
             * @param {wjite.message.IRequestBoard=} [properties] Properties to set
             */
            function RequestBoard(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RequestBoard boardName.
             * @member {string} boardName
             * @memberof wjite.message.RequestBoard
             * @instance
             */
            RequestBoard.prototype.boardName = "";

            /**
             * RequestBoard sessionID.
             * @member {string} sessionID
             * @memberof wjite.message.RequestBoard
             * @instance
             */
            RequestBoard.prototype.sessionID = "";

            /**
             * Creates a new RequestBoard instance using the specified properties.
             * @function create
             * @memberof wjite.message.RequestBoard
             * @static
             * @param {wjite.message.IRequestBoard=} [properties] Properties to set
             * @returns {wjite.message.RequestBoard} RequestBoard instance
             */
            RequestBoard.create = function create(properties) {
                return new RequestBoard(properties);
            };

            /**
             * Encodes the specified RequestBoard message. Does not implicitly {@link wjite.message.RequestBoard.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.RequestBoard
             * @static
             * @param {wjite.message.IRequestBoard} message RequestBoard message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RequestBoard.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.boardName != null && Object.hasOwnProperty.call(message, "boardName"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.boardName);
                if (message.sessionID != null && Object.hasOwnProperty.call(message, "sessionID"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.sessionID);
                return writer;
            };

            /**
             * Encodes the specified RequestBoard message, length delimited. Does not implicitly {@link wjite.message.RequestBoard.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.RequestBoard
             * @static
             * @param {wjite.message.IRequestBoard} message RequestBoard message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RequestBoard.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RequestBoard message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.RequestBoard
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.RequestBoard} RequestBoard
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RequestBoard.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.RequestBoard();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.boardName = reader.string();
                        break;
                    case 2:
                        message.sessionID = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RequestBoard message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.RequestBoard
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.RequestBoard} RequestBoard
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RequestBoard.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RequestBoard message.
             * @function verify
             * @memberof wjite.message.RequestBoard
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RequestBoard.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.boardName != null && message.hasOwnProperty("boardName"))
                    if (!$util.isString(message.boardName))
                        return "boardName: string expected";
                if (message.sessionID != null && message.hasOwnProperty("sessionID"))
                    if (!$util.isString(message.sessionID))
                        return "sessionID: string expected";
                return null;
            };

            /**
             * Creates a RequestBoard message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.RequestBoard
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.RequestBoard} RequestBoard
             */
            RequestBoard.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.RequestBoard)
                    return object;
                let message = new $root.wjite.message.RequestBoard();
                if (object.boardName != null)
                    message.boardName = String(object.boardName);
                if (object.sessionID != null)
                    message.sessionID = String(object.sessionID);
                return message;
            };

            /**
             * Creates a plain object from a RequestBoard message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.RequestBoard
             * @static
             * @param {wjite.message.RequestBoard} message RequestBoard
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RequestBoard.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.boardName = "";
                    object.sessionID = "";
                }
                if (message.boardName != null && message.hasOwnProperty("boardName"))
                    object.boardName = message.boardName;
                if (message.sessionID != null && message.hasOwnProperty("sessionID"))
                    object.sessionID = message.sessionID;
                return object;
            };

            /**
             * Converts this RequestBoard to JSON.
             * @function toJSON
             * @memberof wjite.message.RequestBoard
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RequestBoard.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RequestBoard;
        })();

        message.Ping = (function() {

            /**
             * Properties of a Ping.
             * @memberof wjite.message
             * @interface IPing
             */

            /**
             * Constructs a new Ping.
             * @memberof wjite.message
             * @classdesc Represents a Ping.
             * @implements IPing
             * @constructor
             * @param {wjite.message.IPing=} [properties] Properties to set
             */
            function Ping(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new Ping instance using the specified properties.
             * @function create
             * @memberof wjite.message.Ping
             * @static
             * @param {wjite.message.IPing=} [properties] Properties to set
             * @returns {wjite.message.Ping} Ping instance
             */
            Ping.create = function create(properties) {
                return new Ping(properties);
            };

            /**
             * Encodes the specified Ping message. Does not implicitly {@link wjite.message.Ping.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.Ping
             * @static
             * @param {wjite.message.IPing} message Ping message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Ping.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified Ping message, length delimited. Does not implicitly {@link wjite.message.Ping.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.Ping
             * @static
             * @param {wjite.message.IPing} message Ping message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Ping.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Ping message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.Ping
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.Ping} Ping
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Ping.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.Ping();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Ping message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.Ping
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.Ping} Ping
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Ping.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Ping message.
             * @function verify
             * @memberof wjite.message.Ping
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Ping.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a Ping message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.Ping
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.Ping} Ping
             */
            Ping.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.Ping)
                    return object;
                return new $root.wjite.message.Ping();
            };

            /**
             * Creates a plain object from a Ping message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.Ping
             * @static
             * @param {wjite.message.Ping} message Ping
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Ping.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this Ping to JSON.
             * @function toJSON
             * @memberof wjite.message.Ping
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Ping.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Ping;
        })();

        message.Pong = (function() {

            /**
             * Properties of a Pong.
             * @memberof wjite.message
             * @interface IPong
             */

            /**
             * Constructs a new Pong.
             * @memberof wjite.message
             * @classdesc Represents a Pong.
             * @implements IPong
             * @constructor
             * @param {wjite.message.IPong=} [properties] Properties to set
             */
            function Pong(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new Pong instance using the specified properties.
             * @function create
             * @memberof wjite.message.Pong
             * @static
             * @param {wjite.message.IPong=} [properties] Properties to set
             * @returns {wjite.message.Pong} Pong instance
             */
            Pong.create = function create(properties) {
                return new Pong(properties);
            };

            /**
             * Encodes the specified Pong message. Does not implicitly {@link wjite.message.Pong.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.Pong
             * @static
             * @param {wjite.message.IPong} message Pong message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Pong.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified Pong message, length delimited. Does not implicitly {@link wjite.message.Pong.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.Pong
             * @static
             * @param {wjite.message.IPong} message Pong message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Pong.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Pong message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.Pong
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.Pong} Pong
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Pong.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.Pong();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Pong message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.Pong
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.Pong} Pong
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Pong.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Pong message.
             * @function verify
             * @memberof wjite.message.Pong
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Pong.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a Pong message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.Pong
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.Pong} Pong
             */
            Pong.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.Pong)
                    return object;
                return new $root.wjite.message.Pong();
            };

            /**
             * Creates a plain object from a Pong message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.Pong
             * @static
             * @param {wjite.message.Pong} message Pong
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Pong.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this Pong to JSON.
             * @function toJSON
             * @memberof wjite.message.Pong
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Pong.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Pong;
        })();

        message.UpdateShapeID = (function() {

            /**
             * Properties of an UpdateShapeID.
             * @memberof wjite.message
             * @interface IUpdateShapeID
             * @property {Long|null} [tempId] UpdateShapeID tempId
             * @property {Long|null} [newId] UpdateShapeID newId
             */

            /**
             * Constructs a new UpdateShapeID.
             * @memberof wjite.message
             * @classdesc Represents an UpdateShapeID.
             * @implements IUpdateShapeID
             * @constructor
             * @param {wjite.message.IUpdateShapeID=} [properties] Properties to set
             */
            function UpdateShapeID(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateShapeID tempId.
             * @member {Long} tempId
             * @memberof wjite.message.UpdateShapeID
             * @instance
             */
            UpdateShapeID.prototype.tempId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateShapeID newId.
             * @member {Long} newId
             * @memberof wjite.message.UpdateShapeID
             * @instance
             */
            UpdateShapeID.prototype.newId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new UpdateShapeID instance using the specified properties.
             * @function create
             * @memberof wjite.message.UpdateShapeID
             * @static
             * @param {wjite.message.IUpdateShapeID=} [properties] Properties to set
             * @returns {wjite.message.UpdateShapeID} UpdateShapeID instance
             */
            UpdateShapeID.create = function create(properties) {
                return new UpdateShapeID(properties);
            };

            /**
             * Encodes the specified UpdateShapeID message. Does not implicitly {@link wjite.message.UpdateShapeID.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.UpdateShapeID
             * @static
             * @param {wjite.message.IUpdateShapeID} message UpdateShapeID message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateShapeID.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.tempId != null && Object.hasOwnProperty.call(message, "tempId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.tempId);
                if (message.newId != null && Object.hasOwnProperty.call(message, "newId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.newId);
                return writer;
            };

            /**
             * Encodes the specified UpdateShapeID message, length delimited. Does not implicitly {@link wjite.message.UpdateShapeID.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.UpdateShapeID
             * @static
             * @param {wjite.message.IUpdateShapeID} message UpdateShapeID message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateShapeID.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdateShapeID message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.UpdateShapeID
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.UpdateShapeID} UpdateShapeID
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateShapeID.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.UpdateShapeID();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.tempId = reader.uint64();
                        break;
                    case 2:
                        message.newId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdateShapeID message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.UpdateShapeID
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.UpdateShapeID} UpdateShapeID
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateShapeID.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdateShapeID message.
             * @function verify
             * @memberof wjite.message.UpdateShapeID
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateShapeID.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.tempId != null && message.hasOwnProperty("tempId"))
                    if (!$util.isInteger(message.tempId) && !(message.tempId && $util.isInteger(message.tempId.low) && $util.isInteger(message.tempId.high)))
                        return "tempId: integer|Long expected";
                if (message.newId != null && message.hasOwnProperty("newId"))
                    if (!$util.isInteger(message.newId) && !(message.newId && $util.isInteger(message.newId.low) && $util.isInteger(message.newId.high)))
                        return "newId: integer|Long expected";
                return null;
            };

            /**
             * Creates an UpdateShapeID message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.UpdateShapeID
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.UpdateShapeID} UpdateShapeID
             */
            UpdateShapeID.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.UpdateShapeID)
                    return object;
                let message = new $root.wjite.message.UpdateShapeID();
                if (object.tempId != null)
                    if ($util.Long)
                        (message.tempId = $util.Long.fromValue(object.tempId)).unsigned = true;
                    else if (typeof object.tempId === "string")
                        message.tempId = parseInt(object.tempId, 10);
                    else if (typeof object.tempId === "number")
                        message.tempId = object.tempId;
                    else if (typeof object.tempId === "object")
                        message.tempId = new $util.LongBits(object.tempId.low >>> 0, object.tempId.high >>> 0).toNumber(true);
                if (object.newId != null)
                    if ($util.Long)
                        (message.newId = $util.Long.fromValue(object.newId)).unsigned = true;
                    else if (typeof object.newId === "string")
                        message.newId = parseInt(object.newId, 10);
                    else if (typeof object.newId === "number")
                        message.newId = object.newId;
                    else if (typeof object.newId === "object")
                        message.newId = new $util.LongBits(object.newId.low >>> 0, object.newId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from an UpdateShapeID message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.UpdateShapeID
             * @static
             * @param {wjite.message.UpdateShapeID} message UpdateShapeID
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateShapeID.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.tempId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.tempId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.newId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.newId = options.longs === String ? "0" : 0;
                }
                if (message.tempId != null && message.hasOwnProperty("tempId"))
                    if (typeof message.tempId === "number")
                        object.tempId = options.longs === String ? String(message.tempId) : message.tempId;
                    else
                        object.tempId = options.longs === String ? $util.Long.prototype.toString.call(message.tempId) : options.longs === Number ? new $util.LongBits(message.tempId.low >>> 0, message.tempId.high >>> 0).toNumber(true) : message.tempId;
                if (message.newId != null && message.hasOwnProperty("newId"))
                    if (typeof message.newId === "number")
                        object.newId = options.longs === String ? String(message.newId) : message.newId;
                    else
                        object.newId = options.longs === String ? $util.Long.prototype.toString.call(message.newId) : options.longs === Number ? new $util.LongBits(message.newId.low >>> 0, message.newId.high >>> 0).toNumber(true) : message.newId;
                return object;
            };

            /**
             * Converts this UpdateShapeID to JSON.
             * @function toJSON
             * @memberof wjite.message.UpdateShapeID
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateShapeID.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateShapeID;
        })();

        message.Event = (function() {

            /**
             * Properties of an Event.
             * @memberof wjite.message
             * @interface IEvent
             * @property {Long|null} [componentId] Event componentId
             * @property {string|null} [elementId] Event elementId
             * @property {string|null} [eventName] Event eventName
             * @property {google.protobuf.IStruct|null} [eventData] Event eventData
             */

            /**
             * Constructs a new Event.
             * @memberof wjite.message
             * @classdesc Represents an Event.
             * @implements IEvent
             * @constructor
             * @param {wjite.message.IEvent=} [properties] Properties to set
             */
            function Event(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Event componentId.
             * @member {Long} componentId
             * @memberof wjite.message.Event
             * @instance
             */
            Event.prototype.componentId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Event elementId.
             * @member {string} elementId
             * @memberof wjite.message.Event
             * @instance
             */
            Event.prototype.elementId = "";

            /**
             * Event eventName.
             * @member {string} eventName
             * @memberof wjite.message.Event
             * @instance
             */
            Event.prototype.eventName = "";

            /**
             * Event eventData.
             * @member {google.protobuf.IStruct|null|undefined} eventData
             * @memberof wjite.message.Event
             * @instance
             */
            Event.prototype.eventData = null;

            /**
             * Creates a new Event instance using the specified properties.
             * @function create
             * @memberof wjite.message.Event
             * @static
             * @param {wjite.message.IEvent=} [properties] Properties to set
             * @returns {wjite.message.Event} Event instance
             */
            Event.create = function create(properties) {
                return new Event(properties);
            };

            /**
             * Encodes the specified Event message. Does not implicitly {@link wjite.message.Event.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.Event
             * @static
             * @param {wjite.message.IEvent} message Event message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Event.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.componentId != null && Object.hasOwnProperty.call(message, "componentId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.componentId);
                if (message.elementId != null && Object.hasOwnProperty.call(message, "elementId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.elementId);
                if (message.eventName != null && Object.hasOwnProperty.call(message, "eventName"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.eventName);
                if (message.eventData != null && Object.hasOwnProperty.call(message, "eventData"))
                    $root.google.protobuf.Struct.encode(message.eventData, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Event message, length delimited. Does not implicitly {@link wjite.message.Event.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.Event
             * @static
             * @param {wjite.message.IEvent} message Event message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Event.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Event message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.Event
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.Event} Event
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Event.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.Event();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2:
                        message.componentId = reader.uint64();
                        break;
                    case 4:
                        message.elementId = reader.string();
                        break;
                    case 5:
                        message.eventName = reader.string();
                        break;
                    case 6:
                        message.eventData = $root.google.protobuf.Struct.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Event message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.Event
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.Event} Event
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Event.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Event message.
             * @function verify
             * @memberof wjite.message.Event
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Event.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.componentId != null && message.hasOwnProperty("componentId"))
                    if (!$util.isInteger(message.componentId) && !(message.componentId && $util.isInteger(message.componentId.low) && $util.isInteger(message.componentId.high)))
                        return "componentId: integer|Long expected";
                if (message.elementId != null && message.hasOwnProperty("elementId"))
                    if (!$util.isString(message.elementId))
                        return "elementId: string expected";
                if (message.eventName != null && message.hasOwnProperty("eventName"))
                    if (!$util.isString(message.eventName))
                        return "eventName: string expected";
                if (message.eventData != null && message.hasOwnProperty("eventData")) {
                    let error = $root.google.protobuf.Struct.verify(message.eventData);
                    if (error)
                        return "eventData." + error;
                }
                return null;
            };

            /**
             * Creates an Event message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.Event
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.Event} Event
             */
            Event.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.Event)
                    return object;
                let message = new $root.wjite.message.Event();
                if (object.componentId != null)
                    if ($util.Long)
                        (message.componentId = $util.Long.fromValue(object.componentId)).unsigned = true;
                    else if (typeof object.componentId === "string")
                        message.componentId = parseInt(object.componentId, 10);
                    else if (typeof object.componentId === "number")
                        message.componentId = object.componentId;
                    else if (typeof object.componentId === "object")
                        message.componentId = new $util.LongBits(object.componentId.low >>> 0, object.componentId.high >>> 0).toNumber(true);
                if (object.elementId != null)
                    message.elementId = String(object.elementId);
                if (object.eventName != null)
                    message.eventName = String(object.eventName);
                if (object.eventData != null) {
                    if (typeof object.eventData !== "object")
                        throw TypeError(".wjite.message.Event.eventData: object expected");
                    message.eventData = $root.google.protobuf.Struct.fromObject(object.eventData);
                }
                return message;
            };

            /**
             * Creates a plain object from an Event message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.Event
             * @static
             * @param {wjite.message.Event} message Event
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Event.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.componentId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.componentId = options.longs === String ? "0" : 0;
                    object.elementId = "";
                    object.eventName = "";
                    object.eventData = null;
                }
                if (message.componentId != null && message.hasOwnProperty("componentId"))
                    if (typeof message.componentId === "number")
                        object.componentId = options.longs === String ? String(message.componentId) : message.componentId;
                    else
                        object.componentId = options.longs === String ? $util.Long.prototype.toString.call(message.componentId) : options.longs === Number ? new $util.LongBits(message.componentId.low >>> 0, message.componentId.high >>> 0).toNumber(true) : message.componentId;
                if (message.elementId != null && message.hasOwnProperty("elementId"))
                    object.elementId = message.elementId;
                if (message.eventName != null && message.hasOwnProperty("eventName"))
                    object.eventName = message.eventName;
                if (message.eventData != null && message.hasOwnProperty("eventData"))
                    object.eventData = $root.google.protobuf.Struct.toObject(message.eventData, options);
                return object;
            };

            /**
             * Converts this Event to JSON.
             * @function toJSON
             * @memberof wjite.message.Event
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Event.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Event;
        })();

        message.User = (function() {

            /**
             * Properties of a User.
             * @memberof wjite.message
             * @interface IUser
             * @property {string|null} [name] User name
             * @property {string|null} [role] User role
             */

            /**
             * Constructs a new User.
             * @memberof wjite.message
             * @classdesc Represents a User.
             * @implements IUser
             * @constructor
             * @param {wjite.message.IUser=} [properties] Properties to set
             */
            function User(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * User name.
             * @member {string} name
             * @memberof wjite.message.User
             * @instance
             */
            User.prototype.name = "";

            /**
             * User role.
             * @member {string} role
             * @memberof wjite.message.User
             * @instance
             */
            User.prototype.role = "";

            /**
             * Creates a new User instance using the specified properties.
             * @function create
             * @memberof wjite.message.User
             * @static
             * @param {wjite.message.IUser=} [properties] Properties to set
             * @returns {wjite.message.User} User instance
             */
            User.create = function create(properties) {
                return new User(properties);
            };

            /**
             * Encodes the specified User message. Does not implicitly {@link wjite.message.User.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.User
             * @static
             * @param {wjite.message.IUser} message User message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            User.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.role);
                return writer;
            };

            /**
             * Encodes the specified User message, length delimited. Does not implicitly {@link wjite.message.User.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.User
             * @static
             * @param {wjite.message.IUser} message User message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            User.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a User message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.User
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.User} User
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            User.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.User();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.role = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a User message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.User
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.User} User
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            User.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a User message.
             * @function verify
             * @memberof wjite.message.User
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            User.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.role != null && message.hasOwnProperty("role"))
                    if (!$util.isString(message.role))
                        return "role: string expected";
                return null;
            };

            /**
             * Creates a User message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.User
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.User} User
             */
            User.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.User)
                    return object;
                let message = new $root.wjite.message.User();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.role != null)
                    message.role = String(object.role);
                return message;
            };

            /**
             * Creates a plain object from a User message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.User
             * @static
             * @param {wjite.message.User} message User
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            User.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.role = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.role != null && message.hasOwnProperty("role"))
                    object.role = message.role;
                return object;
            };

            /**
             * Converts this User to JSON.
             * @function toJSON
             * @memberof wjite.message.User
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            User.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return User;
        })();

        message.ServerGet = (function() {

            /**
             * Properties of a ServerGet.
             * @memberof wjite.message
             * @interface IServerGet
             * @property {string|null} [route] ServerGet route
             * @property {string|null} [board] ServerGet board
             */

            /**
             * Constructs a new ServerGet.
             * @memberof wjite.message
             * @classdesc Represents a ServerGet.
             * @implements IServerGet
             * @constructor
             * @param {wjite.message.IServerGet=} [properties] Properties to set
             */
            function ServerGet(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServerGet route.
             * @member {string} route
             * @memberof wjite.message.ServerGet
             * @instance
             */
            ServerGet.prototype.route = "";

            /**
             * ServerGet board.
             * @member {string} board
             * @memberof wjite.message.ServerGet
             * @instance
             */
            ServerGet.prototype.board = "";

            /**
             * Creates a new ServerGet instance using the specified properties.
             * @function create
             * @memberof wjite.message.ServerGet
             * @static
             * @param {wjite.message.IServerGet=} [properties] Properties to set
             * @returns {wjite.message.ServerGet} ServerGet instance
             */
            ServerGet.create = function create(properties) {
                return new ServerGet(properties);
            };

            /**
             * Encodes the specified ServerGet message. Does not implicitly {@link wjite.message.ServerGet.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.ServerGet
             * @static
             * @param {wjite.message.IServerGet} message ServerGet message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServerGet.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.route != null && Object.hasOwnProperty.call(message, "route"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.route);
                if (message.board != null && Object.hasOwnProperty.call(message, "board"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.board);
                return writer;
            };

            /**
             * Encodes the specified ServerGet message, length delimited. Does not implicitly {@link wjite.message.ServerGet.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.ServerGet
             * @static
             * @param {wjite.message.IServerGet} message ServerGet message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServerGet.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ServerGet message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.ServerGet
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.ServerGet} ServerGet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServerGet.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.ServerGet();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.route = reader.string();
                        break;
                    case 2:
                        message.board = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ServerGet message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.ServerGet
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.ServerGet} ServerGet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServerGet.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ServerGet message.
             * @function verify
             * @memberof wjite.message.ServerGet
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServerGet.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.route != null && message.hasOwnProperty("route"))
                    if (!$util.isString(message.route))
                        return "route: string expected";
                if (message.board != null && message.hasOwnProperty("board"))
                    if (!$util.isString(message.board))
                        return "board: string expected";
                return null;
            };

            /**
             * Creates a ServerGet message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.ServerGet
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.ServerGet} ServerGet
             */
            ServerGet.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.ServerGet)
                    return object;
                let message = new $root.wjite.message.ServerGet();
                if (object.route != null)
                    message.route = String(object.route);
                if (object.board != null)
                    message.board = String(object.board);
                return message;
            };

            /**
             * Creates a plain object from a ServerGet message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.ServerGet
             * @static
             * @param {wjite.message.ServerGet} message ServerGet
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServerGet.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.route = "";
                    object.board = "";
                }
                if (message.route != null && message.hasOwnProperty("route"))
                    object.route = message.route;
                if (message.board != null && message.hasOwnProperty("board"))
                    object.board = message.board;
                return object;
            };

            /**
             * Converts this ServerGet to JSON.
             * @function toJSON
             * @memberof wjite.message.ServerGet
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServerGet.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ServerGet;
        })();

        message.ServerPut = (function() {

            /**
             * Properties of a ServerPut.
             * @memberof wjite.message
             * @interface IServerPut
             * @property {string|null} [sessionId] ServerPut sessionId
             */

            /**
             * Constructs a new ServerPut.
             * @memberof wjite.message
             * @classdesc Represents a ServerPut.
             * @implements IServerPut
             * @constructor
             * @param {wjite.message.IServerPut=} [properties] Properties to set
             */
            function ServerPut(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServerPut sessionId.
             * @member {string} sessionId
             * @memberof wjite.message.ServerPut
             * @instance
             */
            ServerPut.prototype.sessionId = "";

            /**
             * Creates a new ServerPut instance using the specified properties.
             * @function create
             * @memberof wjite.message.ServerPut
             * @static
             * @param {wjite.message.IServerPut=} [properties] Properties to set
             * @returns {wjite.message.ServerPut} ServerPut instance
             */
            ServerPut.create = function create(properties) {
                return new ServerPut(properties);
            };

            /**
             * Encodes the specified ServerPut message. Does not implicitly {@link wjite.message.ServerPut.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.ServerPut
             * @static
             * @param {wjite.message.IServerPut} message ServerPut message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServerPut.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
                return writer;
            };

            /**
             * Encodes the specified ServerPut message, length delimited. Does not implicitly {@link wjite.message.ServerPut.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.ServerPut
             * @static
             * @param {wjite.message.IServerPut} message ServerPut message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServerPut.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ServerPut message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.ServerPut
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.ServerPut} ServerPut
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServerPut.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.ServerPut();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.sessionId = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ServerPut message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.ServerPut
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.ServerPut} ServerPut
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServerPut.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ServerPut message.
             * @function verify
             * @memberof wjite.message.ServerPut
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServerPut.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                    if (!$util.isString(message.sessionId))
                        return "sessionId: string expected";
                return null;
            };

            /**
             * Creates a ServerPut message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.ServerPut
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.ServerPut} ServerPut
             */
            ServerPut.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.ServerPut)
                    return object;
                let message = new $root.wjite.message.ServerPut();
                if (object.sessionId != null)
                    message.sessionId = String(object.sessionId);
                return message;
            };

            /**
             * Creates a plain object from a ServerPut message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.ServerPut
             * @static
             * @param {wjite.message.ServerPut} message ServerPut
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServerPut.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.sessionId = "";
                if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                    object.sessionId = message.sessionId;
                return object;
            };

            /**
             * Converts this ServerPut to JSON.
             * @function toJSON
             * @memberof wjite.message.ServerPut
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServerPut.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ServerPut;
        })();

        message.ClientPut = (function() {

            /**
             * Properties of a ClientPut.
             * @memberof wjite.message
             * @interface IClientPut
             */

            /**
             * Constructs a new ClientPut.
             * @memberof wjite.message
             * @classdesc Represents a ClientPut.
             * @implements IClientPut
             * @constructor
             * @param {wjite.message.IClientPut=} [properties] Properties to set
             */
            function ClientPut(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new ClientPut instance using the specified properties.
             * @function create
             * @memberof wjite.message.ClientPut
             * @static
             * @param {wjite.message.IClientPut=} [properties] Properties to set
             * @returns {wjite.message.ClientPut} ClientPut instance
             */
            ClientPut.create = function create(properties) {
                return new ClientPut(properties);
            };

            /**
             * Encodes the specified ClientPut message. Does not implicitly {@link wjite.message.ClientPut.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.ClientPut
             * @static
             * @param {wjite.message.IClientPut} message ClientPut message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPut.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified ClientPut message, length delimited. Does not implicitly {@link wjite.message.ClientPut.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.ClientPut
             * @static
             * @param {wjite.message.IClientPut} message ClientPut message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientPut.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ClientPut message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.ClientPut
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.ClientPut} ClientPut
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPut.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.ClientPut();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ClientPut message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.ClientPut
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.ClientPut} ClientPut
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientPut.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ClientPut message.
             * @function verify
             * @memberof wjite.message.ClientPut
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientPut.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a ClientPut message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.ClientPut
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.ClientPut} ClientPut
             */
            ClientPut.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.ClientPut)
                    return object;
                return new $root.wjite.message.ClientPut();
            };

            /**
             * Creates a plain object from a ClientPut message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.ClientPut
             * @static
             * @param {wjite.message.ClientPut} message ClientPut
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientPut.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this ClientPut to JSON.
             * @function toJSON
             * @memberof wjite.message.ClientPut
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientPut.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ClientPut;
        })();

        message.ToServer = (function() {

            /**
             * Properties of a ToServer.
             * @memberof wjite.message
             * @interface IToServer
             * @property {string|null} [requestBoard] ToServer requestBoard
             * @property {string|null} [requestRoute] ToServer requestRoute
             * @property {wjite.message.IAddShape|null} [addShape] ToServer addShape
             * @property {wjite.message.IUpdateShape|null} [updateShape] ToServer updateShape
             * @property {wjite.message.IRemoveShape|null} [removeShape] ToServer removeShape
             * @property {wjite.message.IClear|null} [clear] ToServer clear
             * @property {wjite.message.IPing|null} [ping] ToServer ping
             * @property {wjite.message.IPong|null} [pong] ToServer pong
             * @property {wjite.message.IContent|null} [boardContent] ToServer boardContent
             * @property {wjite.message.IUpdateShapeID|null} [updateShapeId] ToServer updateShapeId
             * @property {wjite.message.IEvent|null} [event] ToServer event
             * @property {wjite.message.IUser|null} [user] ToServer user
             * @property {string|null} [sessionId] ToServer sessionId
             * @property {wjite.message.IAddHighlight|null} [addHighlight] ToServer addHighlight
             */

            /**
             * Constructs a new ToServer.
             * @memberof wjite.message
             * @classdesc Represents a ToServer.
             * @implements IToServer
             * @constructor
             * @param {wjite.message.IToServer=} [properties] Properties to set
             */
            function ToServer(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ToServer requestBoard.
             * @member {string} requestBoard
             * @memberof wjite.message.ToServer
             * @instance
             */
            ToServer.prototype.requestBoard = "";

            /**
             * ToServer requestRoute.
             * @member {string} requestRoute
             * @memberof wjite.message.ToServer
             * @instance
             */
            ToServer.prototype.requestRoute = "";

            /**
             * ToServer addShape.
             * @member {wjite.message.IAddShape|null|undefined} addShape
             * @memberof wjite.message.ToServer
             * @instance
             */
            ToServer.prototype.addShape = null;

            /**
             * ToServer updateShape.
             * @member {wjite.message.IUpdateShape|null|undefined} updateShape
             * @memberof wjite.message.ToServer
             * @instance
             */
            ToServer.prototype.updateShape = null;

            /**
             * ToServer removeShape.
             * @member {wjite.message.IRemoveShape|null|undefined} removeShape
             * @memberof wjite.message.ToServer
             * @instance
             */
            ToServer.prototype.removeShape = null;

            /**
             * ToServer clear.
             * @member {wjite.message.IClear|null|undefined} clear
             * @memberof wjite.message.ToServer
             * @instance
             */
            ToServer.prototype.clear = null;

            /**
             * ToServer ping.
             * @member {wjite.message.IPing|null|undefined} ping
             * @memberof wjite.message.ToServer
             * @instance
             */
            ToServer.prototype.ping = null;

            /**
             * ToServer pong.
             * @member {wjite.message.IPong|null|undefined} pong
             * @memberof wjite.message.ToServer
             * @instance
             */
            ToServer.prototype.pong = null;

            /**
             * ToServer boardContent.
             * @member {wjite.message.IContent|null|undefined} boardContent
             * @memberof wjite.message.ToServer
             * @instance
             */
            ToServer.prototype.boardContent = null;

            /**
             * ToServer updateShapeId.
             * @member {wjite.message.IUpdateShapeID|null|undefined} updateShapeId
             * @memberof wjite.message.ToServer
             * @instance
             */
            ToServer.prototype.updateShapeId = null;

            /**
             * ToServer event.
             * @member {wjite.message.IEvent|null|undefined} event
             * @memberof wjite.message.ToServer
             * @instance
             */
            ToServer.prototype.event = null;

            /**
             * ToServer user.
             * @member {wjite.message.IUser|null|undefined} user
             * @memberof wjite.message.ToServer
             * @instance
             */
            ToServer.prototype.user = null;

            /**
             * ToServer sessionId.
             * @member {string} sessionId
             * @memberof wjite.message.ToServer
             * @instance
             */
            ToServer.prototype.sessionId = "";

            /**
             * ToServer addHighlight.
             * @member {wjite.message.IAddHighlight|null|undefined} addHighlight
             * @memberof wjite.message.ToServer
             * @instance
             */
            ToServer.prototype.addHighlight = null;

            /**
             * Creates a new ToServer instance using the specified properties.
             * @function create
             * @memberof wjite.message.ToServer
             * @static
             * @param {wjite.message.IToServer=} [properties] Properties to set
             * @returns {wjite.message.ToServer} ToServer instance
             */
            ToServer.create = function create(properties) {
                return new ToServer(properties);
            };

            /**
             * Encodes the specified ToServer message. Does not implicitly {@link wjite.message.ToServer.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.ToServer
             * @static
             * @param {wjite.message.IToServer} message ToServer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ToServer.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.requestBoard != null && Object.hasOwnProperty.call(message, "requestBoard"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.requestBoard);
                if (message.requestRoute != null && Object.hasOwnProperty.call(message, "requestRoute"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.requestRoute);
                if (message.addShape != null && Object.hasOwnProperty.call(message, "addShape"))
                    $root.wjite.message.AddShape.encode(message.addShape, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.updateShape != null && Object.hasOwnProperty.call(message, "updateShape"))
                    $root.wjite.message.UpdateShape.encode(message.updateShape, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.removeShape != null && Object.hasOwnProperty.call(message, "removeShape"))
                    $root.wjite.message.RemoveShape.encode(message.removeShape, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.clear != null && Object.hasOwnProperty.call(message, "clear"))
                    $root.wjite.message.Clear.encode(message.clear, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.ping != null && Object.hasOwnProperty.call(message, "ping"))
                    $root.wjite.message.Ping.encode(message.ping, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.pong != null && Object.hasOwnProperty.call(message, "pong"))
                    $root.wjite.message.Pong.encode(message.pong, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.boardContent != null && Object.hasOwnProperty.call(message, "boardContent"))
                    $root.wjite.message.Content.encode(message.boardContent, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.updateShapeId != null && Object.hasOwnProperty.call(message, "updateShapeId"))
                    $root.wjite.message.UpdateShapeID.encode(message.updateShapeId, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.event != null && Object.hasOwnProperty.call(message, "event"))
                    $root.wjite.message.Event.encode(message.event, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                    $root.wjite.message.User.encode(message.user, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                    writer.uint32(/* id 14, wireType 2 =*/114).string(message.sessionId);
                if (message.addHighlight != null && Object.hasOwnProperty.call(message, "addHighlight"))
                    $root.wjite.message.AddHighlight.encode(message.addHighlight, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ToServer message, length delimited. Does not implicitly {@link wjite.message.ToServer.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.ToServer
             * @static
             * @param {wjite.message.IToServer} message ToServer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ToServer.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ToServer message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.ToServer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.ToServer} ToServer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ToServer.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.ToServer();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.requestBoard = reader.string();
                        break;
                    case 2:
                        message.requestRoute = reader.string();
                        break;
                    case 3:
                        message.addShape = $root.wjite.message.AddShape.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.updateShape = $root.wjite.message.UpdateShape.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.removeShape = $root.wjite.message.RemoveShape.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.clear = $root.wjite.message.Clear.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.ping = $root.wjite.message.Ping.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.pong = $root.wjite.message.Pong.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.boardContent = $root.wjite.message.Content.decode(reader, reader.uint32());
                        break;
                    case 10:
                        message.updateShapeId = $root.wjite.message.UpdateShapeID.decode(reader, reader.uint32());
                        break;
                    case 12:
                        message.event = $root.wjite.message.Event.decode(reader, reader.uint32());
                        break;
                    case 13:
                        message.user = $root.wjite.message.User.decode(reader, reader.uint32());
                        break;
                    case 14:
                        message.sessionId = reader.string();
                        break;
                    case 15:
                        message.addHighlight = $root.wjite.message.AddHighlight.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ToServer message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.ToServer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.ToServer} ToServer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ToServer.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ToServer message.
             * @function verify
             * @memberof wjite.message.ToServer
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ToServer.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.requestBoard != null && message.hasOwnProperty("requestBoard"))
                    if (!$util.isString(message.requestBoard))
                        return "requestBoard: string expected";
                if (message.requestRoute != null && message.hasOwnProperty("requestRoute"))
                    if (!$util.isString(message.requestRoute))
                        return "requestRoute: string expected";
                if (message.addShape != null && message.hasOwnProperty("addShape")) {
                    let error = $root.wjite.message.AddShape.verify(message.addShape);
                    if (error)
                        return "addShape." + error;
                }
                if (message.updateShape != null && message.hasOwnProperty("updateShape")) {
                    let error = $root.wjite.message.UpdateShape.verify(message.updateShape);
                    if (error)
                        return "updateShape." + error;
                }
                if (message.removeShape != null && message.hasOwnProperty("removeShape")) {
                    let error = $root.wjite.message.RemoveShape.verify(message.removeShape);
                    if (error)
                        return "removeShape." + error;
                }
                if (message.clear != null && message.hasOwnProperty("clear")) {
                    let error = $root.wjite.message.Clear.verify(message.clear);
                    if (error)
                        return "clear." + error;
                }
                if (message.ping != null && message.hasOwnProperty("ping")) {
                    let error = $root.wjite.message.Ping.verify(message.ping);
                    if (error)
                        return "ping." + error;
                }
                if (message.pong != null && message.hasOwnProperty("pong")) {
                    let error = $root.wjite.message.Pong.verify(message.pong);
                    if (error)
                        return "pong." + error;
                }
                if (message.boardContent != null && message.hasOwnProperty("boardContent")) {
                    let error = $root.wjite.message.Content.verify(message.boardContent);
                    if (error)
                        return "boardContent." + error;
                }
                if (message.updateShapeId != null && message.hasOwnProperty("updateShapeId")) {
                    let error = $root.wjite.message.UpdateShapeID.verify(message.updateShapeId);
                    if (error)
                        return "updateShapeId." + error;
                }
                if (message.event != null && message.hasOwnProperty("event")) {
                    let error = $root.wjite.message.Event.verify(message.event);
                    if (error)
                        return "event." + error;
                }
                if (message.user != null && message.hasOwnProperty("user")) {
                    let error = $root.wjite.message.User.verify(message.user);
                    if (error)
                        return "user." + error;
                }
                if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                    if (!$util.isString(message.sessionId))
                        return "sessionId: string expected";
                if (message.addHighlight != null && message.hasOwnProperty("addHighlight")) {
                    let error = $root.wjite.message.AddHighlight.verify(message.addHighlight);
                    if (error)
                        return "addHighlight." + error;
                }
                return null;
            };

            /**
             * Creates a ToServer message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.ToServer
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.ToServer} ToServer
             */
            ToServer.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.ToServer)
                    return object;
                let message = new $root.wjite.message.ToServer();
                if (object.requestBoard != null)
                    message.requestBoard = String(object.requestBoard);
                if (object.requestRoute != null)
                    message.requestRoute = String(object.requestRoute);
                if (object.addShape != null) {
                    if (typeof object.addShape !== "object")
                        throw TypeError(".wjite.message.ToServer.addShape: object expected");
                    message.addShape = $root.wjite.message.AddShape.fromObject(object.addShape);
                }
                if (object.updateShape != null) {
                    if (typeof object.updateShape !== "object")
                        throw TypeError(".wjite.message.ToServer.updateShape: object expected");
                    message.updateShape = $root.wjite.message.UpdateShape.fromObject(object.updateShape);
                }
                if (object.removeShape != null) {
                    if (typeof object.removeShape !== "object")
                        throw TypeError(".wjite.message.ToServer.removeShape: object expected");
                    message.removeShape = $root.wjite.message.RemoveShape.fromObject(object.removeShape);
                }
                if (object.clear != null) {
                    if (typeof object.clear !== "object")
                        throw TypeError(".wjite.message.ToServer.clear: object expected");
                    message.clear = $root.wjite.message.Clear.fromObject(object.clear);
                }
                if (object.ping != null) {
                    if (typeof object.ping !== "object")
                        throw TypeError(".wjite.message.ToServer.ping: object expected");
                    message.ping = $root.wjite.message.Ping.fromObject(object.ping);
                }
                if (object.pong != null) {
                    if (typeof object.pong !== "object")
                        throw TypeError(".wjite.message.ToServer.pong: object expected");
                    message.pong = $root.wjite.message.Pong.fromObject(object.pong);
                }
                if (object.boardContent != null) {
                    if (typeof object.boardContent !== "object")
                        throw TypeError(".wjite.message.ToServer.boardContent: object expected");
                    message.boardContent = $root.wjite.message.Content.fromObject(object.boardContent);
                }
                if (object.updateShapeId != null) {
                    if (typeof object.updateShapeId !== "object")
                        throw TypeError(".wjite.message.ToServer.updateShapeId: object expected");
                    message.updateShapeId = $root.wjite.message.UpdateShapeID.fromObject(object.updateShapeId);
                }
                if (object.event != null) {
                    if (typeof object.event !== "object")
                        throw TypeError(".wjite.message.ToServer.event: object expected");
                    message.event = $root.wjite.message.Event.fromObject(object.event);
                }
                if (object.user != null) {
                    if (typeof object.user !== "object")
                        throw TypeError(".wjite.message.ToServer.user: object expected");
                    message.user = $root.wjite.message.User.fromObject(object.user);
                }
                if (object.sessionId != null)
                    message.sessionId = String(object.sessionId);
                if (object.addHighlight != null) {
                    if (typeof object.addHighlight !== "object")
                        throw TypeError(".wjite.message.ToServer.addHighlight: object expected");
                    message.addHighlight = $root.wjite.message.AddHighlight.fromObject(object.addHighlight);
                }
                return message;
            };

            /**
             * Creates a plain object from a ToServer message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.ToServer
             * @static
             * @param {wjite.message.ToServer} message ToServer
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ToServer.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.requestBoard = "";
                    object.requestRoute = "";
                    object.addShape = null;
                    object.updateShape = null;
                    object.removeShape = null;
                    object.clear = null;
                    object.ping = null;
                    object.pong = null;
                    object.boardContent = null;
                    object.updateShapeId = null;
                    object.event = null;
                    object.user = null;
                    object.sessionId = "";
                    object.addHighlight = null;
                }
                if (message.requestBoard != null && message.hasOwnProperty("requestBoard"))
                    object.requestBoard = message.requestBoard;
                if (message.requestRoute != null && message.hasOwnProperty("requestRoute"))
                    object.requestRoute = message.requestRoute;
                if (message.addShape != null && message.hasOwnProperty("addShape"))
                    object.addShape = $root.wjite.message.AddShape.toObject(message.addShape, options);
                if (message.updateShape != null && message.hasOwnProperty("updateShape"))
                    object.updateShape = $root.wjite.message.UpdateShape.toObject(message.updateShape, options);
                if (message.removeShape != null && message.hasOwnProperty("removeShape"))
                    object.removeShape = $root.wjite.message.RemoveShape.toObject(message.removeShape, options);
                if (message.clear != null && message.hasOwnProperty("clear"))
                    object.clear = $root.wjite.message.Clear.toObject(message.clear, options);
                if (message.ping != null && message.hasOwnProperty("ping"))
                    object.ping = $root.wjite.message.Ping.toObject(message.ping, options);
                if (message.pong != null && message.hasOwnProperty("pong"))
                    object.pong = $root.wjite.message.Pong.toObject(message.pong, options);
                if (message.boardContent != null && message.hasOwnProperty("boardContent"))
                    object.boardContent = $root.wjite.message.Content.toObject(message.boardContent, options);
                if (message.updateShapeId != null && message.hasOwnProperty("updateShapeId"))
                    object.updateShapeId = $root.wjite.message.UpdateShapeID.toObject(message.updateShapeId, options);
                if (message.event != null && message.hasOwnProperty("event"))
                    object.event = $root.wjite.message.Event.toObject(message.event, options);
                if (message.user != null && message.hasOwnProperty("user"))
                    object.user = $root.wjite.message.User.toObject(message.user, options);
                if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                    object.sessionId = message.sessionId;
                if (message.addHighlight != null && message.hasOwnProperty("addHighlight"))
                    object.addHighlight = $root.wjite.message.AddHighlight.toObject(message.addHighlight, options);
                return object;
            };

            /**
             * Converts this ToServer to JSON.
             * @function toJSON
             * @memberof wjite.message.ToServer
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ToServer.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ToServer;
        })();

        message.ToClient = (function() {

            /**
             * Properties of a ToClient.
             * @memberof wjite.message
             * @interface IToClient
             * @property {string|null} [sessionId] ToClient sessionId
             * @property {string|null} [route] ToClient route
             * @property {wjite.message.IAddShape|null} [addShape] ToClient addShape
             * @property {wjite.message.IUpdateShape|null} [updateShape] ToClient updateShape
             * @property {wjite.message.IRemoveShape|null} [removeShape] ToClient removeShape
             * @property {wjite.message.IClear|null} [clear] ToClient clear
             * @property {wjite.message.IPing|null} [ping] ToClient ping
             * @property {wjite.message.IPong|null} [pong] ToClient pong
             * @property {wjite.message.IContent|null} [boardContent] ToClient boardContent
             * @property {wjite.message.IUpdateShapeID|null} [updateShapeId] ToClient updateShapeId
             * @property {wjite.message.IEvent|null} [event] ToClient event
             * @property {wjite.message.IUser|null} [user] ToClient user
             * @property {Long|null} [connectionId] ToClient connectionId
             * @property {wjite.message.IAddHighlight|null} [addHighlight] ToClient addHighlight
             */

            /**
             * Constructs a new ToClient.
             * @memberof wjite.message
             * @classdesc Represents a ToClient.
             * @implements IToClient
             * @constructor
             * @param {wjite.message.IToClient=} [properties] Properties to set
             */
            function ToClient(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ToClient sessionId.
             * @member {string} sessionId
             * @memberof wjite.message.ToClient
             * @instance
             */
            ToClient.prototype.sessionId = "";

            /**
             * ToClient route.
             * @member {string} route
             * @memberof wjite.message.ToClient
             * @instance
             */
            ToClient.prototype.route = "";

            /**
             * ToClient addShape.
             * @member {wjite.message.IAddShape|null|undefined} addShape
             * @memberof wjite.message.ToClient
             * @instance
             */
            ToClient.prototype.addShape = null;

            /**
             * ToClient updateShape.
             * @member {wjite.message.IUpdateShape|null|undefined} updateShape
             * @memberof wjite.message.ToClient
             * @instance
             */
            ToClient.prototype.updateShape = null;

            /**
             * ToClient removeShape.
             * @member {wjite.message.IRemoveShape|null|undefined} removeShape
             * @memberof wjite.message.ToClient
             * @instance
             */
            ToClient.prototype.removeShape = null;

            /**
             * ToClient clear.
             * @member {wjite.message.IClear|null|undefined} clear
             * @memberof wjite.message.ToClient
             * @instance
             */
            ToClient.prototype.clear = null;

            /**
             * ToClient ping.
             * @member {wjite.message.IPing|null|undefined} ping
             * @memberof wjite.message.ToClient
             * @instance
             */
            ToClient.prototype.ping = null;

            /**
             * ToClient pong.
             * @member {wjite.message.IPong|null|undefined} pong
             * @memberof wjite.message.ToClient
             * @instance
             */
            ToClient.prototype.pong = null;

            /**
             * ToClient boardContent.
             * @member {wjite.message.IContent|null|undefined} boardContent
             * @memberof wjite.message.ToClient
             * @instance
             */
            ToClient.prototype.boardContent = null;

            /**
             * ToClient updateShapeId.
             * @member {wjite.message.IUpdateShapeID|null|undefined} updateShapeId
             * @memberof wjite.message.ToClient
             * @instance
             */
            ToClient.prototype.updateShapeId = null;

            /**
             * ToClient event.
             * @member {wjite.message.IEvent|null|undefined} event
             * @memberof wjite.message.ToClient
             * @instance
             */
            ToClient.prototype.event = null;

            /**
             * ToClient user.
             * @member {wjite.message.IUser|null|undefined} user
             * @memberof wjite.message.ToClient
             * @instance
             */
            ToClient.prototype.user = null;

            /**
             * ToClient connectionId.
             * @member {Long} connectionId
             * @memberof wjite.message.ToClient
             * @instance
             */
            ToClient.prototype.connectionId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * ToClient addHighlight.
             * @member {wjite.message.IAddHighlight|null|undefined} addHighlight
             * @memberof wjite.message.ToClient
             * @instance
             */
            ToClient.prototype.addHighlight = null;

            /**
             * Creates a new ToClient instance using the specified properties.
             * @function create
             * @memberof wjite.message.ToClient
             * @static
             * @param {wjite.message.IToClient=} [properties] Properties to set
             * @returns {wjite.message.ToClient} ToClient instance
             */
            ToClient.create = function create(properties) {
                return new ToClient(properties);
            };

            /**
             * Encodes the specified ToClient message. Does not implicitly {@link wjite.message.ToClient.verify|verify} messages.
             * @function encode
             * @memberof wjite.message.ToClient
             * @static
             * @param {wjite.message.IToClient} message ToClient message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ToClient.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
                if (message.route != null && Object.hasOwnProperty.call(message, "route"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.route);
                if (message.addShape != null && Object.hasOwnProperty.call(message, "addShape"))
                    $root.wjite.message.AddShape.encode(message.addShape, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.updateShape != null && Object.hasOwnProperty.call(message, "updateShape"))
                    $root.wjite.message.UpdateShape.encode(message.updateShape, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.removeShape != null && Object.hasOwnProperty.call(message, "removeShape"))
                    $root.wjite.message.RemoveShape.encode(message.removeShape, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.clear != null && Object.hasOwnProperty.call(message, "clear"))
                    $root.wjite.message.Clear.encode(message.clear, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.ping != null && Object.hasOwnProperty.call(message, "ping"))
                    $root.wjite.message.Ping.encode(message.ping, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.pong != null && Object.hasOwnProperty.call(message, "pong"))
                    $root.wjite.message.Pong.encode(message.pong, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.boardContent != null && Object.hasOwnProperty.call(message, "boardContent"))
                    $root.wjite.message.Content.encode(message.boardContent, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.updateShapeId != null && Object.hasOwnProperty.call(message, "updateShapeId"))
                    $root.wjite.message.UpdateShapeID.encode(message.updateShapeId, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.event != null && Object.hasOwnProperty.call(message, "event"))
                    $root.wjite.message.Event.encode(message.event, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                    $root.wjite.message.User.encode(message.user, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.connectionId != null && Object.hasOwnProperty.call(message, "connectionId"))
                    writer.uint32(/* id 14, wireType 0 =*/112).uint64(message.connectionId);
                if (message.addHighlight != null && Object.hasOwnProperty.call(message, "addHighlight"))
                    $root.wjite.message.AddHighlight.encode(message.addHighlight, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ToClient message, length delimited. Does not implicitly {@link wjite.message.ToClient.verify|verify} messages.
             * @function encodeDelimited
             * @memberof wjite.message.ToClient
             * @static
             * @param {wjite.message.IToClient} message ToClient message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ToClient.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ToClient message from the specified reader or buffer.
             * @function decode
             * @memberof wjite.message.ToClient
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {wjite.message.ToClient} ToClient
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ToClient.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wjite.message.ToClient();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.sessionId = reader.string();
                        break;
                    case 2:
                        message.route = reader.string();
                        break;
                    case 3:
                        message.addShape = $root.wjite.message.AddShape.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.updateShape = $root.wjite.message.UpdateShape.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.removeShape = $root.wjite.message.RemoveShape.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.clear = $root.wjite.message.Clear.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.ping = $root.wjite.message.Ping.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.pong = $root.wjite.message.Pong.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.boardContent = $root.wjite.message.Content.decode(reader, reader.uint32());
                        break;
                    case 10:
                        message.updateShapeId = $root.wjite.message.UpdateShapeID.decode(reader, reader.uint32());
                        break;
                    case 12:
                        message.event = $root.wjite.message.Event.decode(reader, reader.uint32());
                        break;
                    case 13:
                        message.user = $root.wjite.message.User.decode(reader, reader.uint32());
                        break;
                    case 14:
                        message.connectionId = reader.uint64();
                        break;
                    case 15:
                        message.addHighlight = $root.wjite.message.AddHighlight.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ToClient message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof wjite.message.ToClient
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {wjite.message.ToClient} ToClient
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ToClient.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ToClient message.
             * @function verify
             * @memberof wjite.message.ToClient
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ToClient.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                    if (!$util.isString(message.sessionId))
                        return "sessionId: string expected";
                if (message.route != null && message.hasOwnProperty("route"))
                    if (!$util.isString(message.route))
                        return "route: string expected";
                if (message.addShape != null && message.hasOwnProperty("addShape")) {
                    let error = $root.wjite.message.AddShape.verify(message.addShape);
                    if (error)
                        return "addShape." + error;
                }
                if (message.updateShape != null && message.hasOwnProperty("updateShape")) {
                    let error = $root.wjite.message.UpdateShape.verify(message.updateShape);
                    if (error)
                        return "updateShape." + error;
                }
                if (message.removeShape != null && message.hasOwnProperty("removeShape")) {
                    let error = $root.wjite.message.RemoveShape.verify(message.removeShape);
                    if (error)
                        return "removeShape." + error;
                }
                if (message.clear != null && message.hasOwnProperty("clear")) {
                    let error = $root.wjite.message.Clear.verify(message.clear);
                    if (error)
                        return "clear." + error;
                }
                if (message.ping != null && message.hasOwnProperty("ping")) {
                    let error = $root.wjite.message.Ping.verify(message.ping);
                    if (error)
                        return "ping." + error;
                }
                if (message.pong != null && message.hasOwnProperty("pong")) {
                    let error = $root.wjite.message.Pong.verify(message.pong);
                    if (error)
                        return "pong." + error;
                }
                if (message.boardContent != null && message.hasOwnProperty("boardContent")) {
                    let error = $root.wjite.message.Content.verify(message.boardContent);
                    if (error)
                        return "boardContent." + error;
                }
                if (message.updateShapeId != null && message.hasOwnProperty("updateShapeId")) {
                    let error = $root.wjite.message.UpdateShapeID.verify(message.updateShapeId);
                    if (error)
                        return "updateShapeId." + error;
                }
                if (message.event != null && message.hasOwnProperty("event")) {
                    let error = $root.wjite.message.Event.verify(message.event);
                    if (error)
                        return "event." + error;
                }
                if (message.user != null && message.hasOwnProperty("user")) {
                    let error = $root.wjite.message.User.verify(message.user);
                    if (error)
                        return "user." + error;
                }
                if (message.connectionId != null && message.hasOwnProperty("connectionId"))
                    if (!$util.isInteger(message.connectionId) && !(message.connectionId && $util.isInteger(message.connectionId.low) && $util.isInteger(message.connectionId.high)))
                        return "connectionId: integer|Long expected";
                if (message.addHighlight != null && message.hasOwnProperty("addHighlight")) {
                    let error = $root.wjite.message.AddHighlight.verify(message.addHighlight);
                    if (error)
                        return "addHighlight." + error;
                }
                return null;
            };

            /**
             * Creates a ToClient message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof wjite.message.ToClient
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {wjite.message.ToClient} ToClient
             */
            ToClient.fromObject = function fromObject(object) {
                if (object instanceof $root.wjite.message.ToClient)
                    return object;
                let message = new $root.wjite.message.ToClient();
                if (object.sessionId != null)
                    message.sessionId = String(object.sessionId);
                if (object.route != null)
                    message.route = String(object.route);
                if (object.addShape != null) {
                    if (typeof object.addShape !== "object")
                        throw TypeError(".wjite.message.ToClient.addShape: object expected");
                    message.addShape = $root.wjite.message.AddShape.fromObject(object.addShape);
                }
                if (object.updateShape != null) {
                    if (typeof object.updateShape !== "object")
                        throw TypeError(".wjite.message.ToClient.updateShape: object expected");
                    message.updateShape = $root.wjite.message.UpdateShape.fromObject(object.updateShape);
                }
                if (object.removeShape != null) {
                    if (typeof object.removeShape !== "object")
                        throw TypeError(".wjite.message.ToClient.removeShape: object expected");
                    message.removeShape = $root.wjite.message.RemoveShape.fromObject(object.removeShape);
                }
                if (object.clear != null) {
                    if (typeof object.clear !== "object")
                        throw TypeError(".wjite.message.ToClient.clear: object expected");
                    message.clear = $root.wjite.message.Clear.fromObject(object.clear);
                }
                if (object.ping != null) {
                    if (typeof object.ping !== "object")
                        throw TypeError(".wjite.message.ToClient.ping: object expected");
                    message.ping = $root.wjite.message.Ping.fromObject(object.ping);
                }
                if (object.pong != null) {
                    if (typeof object.pong !== "object")
                        throw TypeError(".wjite.message.ToClient.pong: object expected");
                    message.pong = $root.wjite.message.Pong.fromObject(object.pong);
                }
                if (object.boardContent != null) {
                    if (typeof object.boardContent !== "object")
                        throw TypeError(".wjite.message.ToClient.boardContent: object expected");
                    message.boardContent = $root.wjite.message.Content.fromObject(object.boardContent);
                }
                if (object.updateShapeId != null) {
                    if (typeof object.updateShapeId !== "object")
                        throw TypeError(".wjite.message.ToClient.updateShapeId: object expected");
                    message.updateShapeId = $root.wjite.message.UpdateShapeID.fromObject(object.updateShapeId);
                }
                if (object.event != null) {
                    if (typeof object.event !== "object")
                        throw TypeError(".wjite.message.ToClient.event: object expected");
                    message.event = $root.wjite.message.Event.fromObject(object.event);
                }
                if (object.user != null) {
                    if (typeof object.user !== "object")
                        throw TypeError(".wjite.message.ToClient.user: object expected");
                    message.user = $root.wjite.message.User.fromObject(object.user);
                }
                if (object.connectionId != null)
                    if ($util.Long)
                        (message.connectionId = $util.Long.fromValue(object.connectionId)).unsigned = true;
                    else if (typeof object.connectionId === "string")
                        message.connectionId = parseInt(object.connectionId, 10);
                    else if (typeof object.connectionId === "number")
                        message.connectionId = object.connectionId;
                    else if (typeof object.connectionId === "object")
                        message.connectionId = new $util.LongBits(object.connectionId.low >>> 0, object.connectionId.high >>> 0).toNumber(true);
                if (object.addHighlight != null) {
                    if (typeof object.addHighlight !== "object")
                        throw TypeError(".wjite.message.ToClient.addHighlight: object expected");
                    message.addHighlight = $root.wjite.message.AddHighlight.fromObject(object.addHighlight);
                }
                return message;
            };

            /**
             * Creates a plain object from a ToClient message. Also converts values to other types if specified.
             * @function toObject
             * @memberof wjite.message.ToClient
             * @static
             * @param {wjite.message.ToClient} message ToClient
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ToClient.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.sessionId = "";
                    object.route = "";
                    object.addShape = null;
                    object.updateShape = null;
                    object.removeShape = null;
                    object.clear = null;
                    object.ping = null;
                    object.pong = null;
                    object.boardContent = null;
                    object.updateShapeId = null;
                    object.event = null;
                    object.user = null;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.connectionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.connectionId = options.longs === String ? "0" : 0;
                    object.addHighlight = null;
                }
                if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                    object.sessionId = message.sessionId;
                if (message.route != null && message.hasOwnProperty("route"))
                    object.route = message.route;
                if (message.addShape != null && message.hasOwnProperty("addShape"))
                    object.addShape = $root.wjite.message.AddShape.toObject(message.addShape, options);
                if (message.updateShape != null && message.hasOwnProperty("updateShape"))
                    object.updateShape = $root.wjite.message.UpdateShape.toObject(message.updateShape, options);
                if (message.removeShape != null && message.hasOwnProperty("removeShape"))
                    object.removeShape = $root.wjite.message.RemoveShape.toObject(message.removeShape, options);
                if (message.clear != null && message.hasOwnProperty("clear"))
                    object.clear = $root.wjite.message.Clear.toObject(message.clear, options);
                if (message.ping != null && message.hasOwnProperty("ping"))
                    object.ping = $root.wjite.message.Ping.toObject(message.ping, options);
                if (message.pong != null && message.hasOwnProperty("pong"))
                    object.pong = $root.wjite.message.Pong.toObject(message.pong, options);
                if (message.boardContent != null && message.hasOwnProperty("boardContent"))
                    object.boardContent = $root.wjite.message.Content.toObject(message.boardContent, options);
                if (message.updateShapeId != null && message.hasOwnProperty("updateShapeId"))
                    object.updateShapeId = $root.wjite.message.UpdateShapeID.toObject(message.updateShapeId, options);
                if (message.event != null && message.hasOwnProperty("event"))
                    object.event = $root.wjite.message.Event.toObject(message.event, options);
                if (message.user != null && message.hasOwnProperty("user"))
                    object.user = $root.wjite.message.User.toObject(message.user, options);
                if (message.connectionId != null && message.hasOwnProperty("connectionId"))
                    if (typeof message.connectionId === "number")
                        object.connectionId = options.longs === String ? String(message.connectionId) : message.connectionId;
                    else
                        object.connectionId = options.longs === String ? $util.Long.prototype.toString.call(message.connectionId) : options.longs === Number ? new $util.LongBits(message.connectionId.low >>> 0, message.connectionId.high >>> 0).toNumber(true) : message.connectionId;
                if (message.addHighlight != null && message.hasOwnProperty("addHighlight"))
                    object.addHighlight = $root.wjite.message.AddHighlight.toObject(message.addHighlight, options);
                return object;
            };

            /**
             * Converts this ToClient to JSON.
             * @function toJSON
             * @memberof wjite.message.ToClient
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ToClient.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ToClient;
        })();

        return message;
    })();

    return wjite;
})();

export const google = $root.google = (() => {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    const google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        const protobuf = {};

        protobuf.Struct = (function() {

            /**
             * Properties of a Struct.
             * @memberof google.protobuf
             * @interface IStruct
             * @property {Object.<string,google.protobuf.IValue>|null} [fields] Struct fields
             */

            /**
             * Constructs a new Struct.
             * @memberof google.protobuf
             * @classdesc Represents a Struct.
             * @implements IStruct
             * @constructor
             * @param {google.protobuf.IStruct=} [properties] Properties to set
             */
            function Struct(properties) {
                this.fields = {};
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Struct fields.
             * @member {Object.<string,google.protobuf.IValue>} fields
             * @memberof google.protobuf.Struct
             * @instance
             */
            Struct.prototype.fields = $util.emptyObject;

            /**
             * Creates a new Struct instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Struct
             * @static
             * @param {google.protobuf.IStruct=} [properties] Properties to set
             * @returns {google.protobuf.Struct} Struct instance
             */
            Struct.create = function create(properties) {
                return new Struct(properties);
            };

            /**
             * Encodes the specified Struct message. Does not implicitly {@link google.protobuf.Struct.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Struct
             * @static
             * @param {google.protobuf.IStruct} message Struct message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Struct.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.fields != null && Object.hasOwnProperty.call(message, "fields"))
                    for (let keys = Object.keys(message.fields), i = 0; i < keys.length; ++i) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                        $root.google.protobuf.Value.encode(message.fields[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                    }
                return writer;
            };

            /**
             * Encodes the specified Struct message, length delimited. Does not implicitly {@link google.protobuf.Struct.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Struct
             * @static
             * @param {google.protobuf.IStruct} message Struct message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Struct.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Struct message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Struct
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Struct} Struct
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Struct.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Struct(), key, value;
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (message.fields === $util.emptyObject)
                            message.fields = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = null;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = $root.google.protobuf.Value.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.fields[key] = value;
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Struct message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Struct
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Struct} Struct
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Struct.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Struct message.
             * @function verify
             * @memberof google.protobuf.Struct
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Struct.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.fields != null && message.hasOwnProperty("fields")) {
                    if (!$util.isObject(message.fields))
                        return "fields: object expected";
                    let key = Object.keys(message.fields);
                    for (let i = 0; i < key.length; ++i) {
                        let error = $root.google.protobuf.Value.verify(message.fields[key[i]]);
                        if (error)
                            return "fields." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a Struct message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Struct
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Struct} Struct
             */
            Struct.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Struct)
                    return object;
                let message = new $root.google.protobuf.Struct();
                if (object.fields) {
                    if (typeof object.fields !== "object")
                        throw TypeError(".google.protobuf.Struct.fields: object expected");
                    message.fields = {};
                    for (let keys = Object.keys(object.fields), i = 0; i < keys.length; ++i) {
                        if (typeof object.fields[keys[i]] !== "object")
                            throw TypeError(".google.protobuf.Struct.fields: object expected");
                        message.fields[keys[i]] = $root.google.protobuf.Value.fromObject(object.fields[keys[i]]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a Struct message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Struct
             * @static
             * @param {google.protobuf.Struct} message Struct
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Struct.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.objects || options.defaults)
                    object.fields = {};
                let keys2;
                if (message.fields && (keys2 = Object.keys(message.fields)).length) {
                    object.fields = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.fields[keys2[j]] = $root.google.protobuf.Value.toObject(message.fields[keys2[j]], options);
                }
                return object;
            };

            /**
             * Converts this Struct to JSON.
             * @function toJSON
             * @memberof google.protobuf.Struct
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Struct.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Struct;
        })();

        protobuf.Value = (function() {

            /**
             * Properties of a Value.
             * @memberof google.protobuf
             * @interface IValue
             * @property {google.protobuf.NullValue|null} [nullValue] Value nullValue
             * @property {number|null} [numberValue] Value numberValue
             * @property {string|null} [stringValue] Value stringValue
             * @property {boolean|null} [boolValue] Value boolValue
             * @property {google.protobuf.IStruct|null} [structValue] Value structValue
             * @property {google.protobuf.IListValue|null} [listValue] Value listValue
             */

            /**
             * Constructs a new Value.
             * @memberof google.protobuf
             * @classdesc Represents a Value.
             * @implements IValue
             * @constructor
             * @param {google.protobuf.IValue=} [properties] Properties to set
             */
            function Value(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Value nullValue.
             * @member {google.protobuf.NullValue} nullValue
             * @memberof google.protobuf.Value
             * @instance
             */
            Value.prototype.nullValue = 0;

            /**
             * Value numberValue.
             * @member {number} numberValue
             * @memberof google.protobuf.Value
             * @instance
             */
            Value.prototype.numberValue = 0;

            /**
             * Value stringValue.
             * @member {string} stringValue
             * @memberof google.protobuf.Value
             * @instance
             */
            Value.prototype.stringValue = "";

            /**
             * Value boolValue.
             * @member {boolean} boolValue
             * @memberof google.protobuf.Value
             * @instance
             */
            Value.prototype.boolValue = false;

            /**
             * Value structValue.
             * @member {google.protobuf.IStruct|null|undefined} structValue
             * @memberof google.protobuf.Value
             * @instance
             */
            Value.prototype.structValue = null;

            /**
             * Value listValue.
             * @member {google.protobuf.IListValue|null|undefined} listValue
             * @memberof google.protobuf.Value
             * @instance
             */
            Value.prototype.listValue = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * Value kind.
             * @member {"nullValue"|"numberValue"|"stringValue"|"boolValue"|"structValue"|"listValue"|undefined} kind
             * @memberof google.protobuf.Value
             * @instance
             */
            Object.defineProperty(Value.prototype, "kind", {
                get: $util.oneOfGetter($oneOfFields = ["nullValue", "numberValue", "stringValue", "boolValue", "structValue", "listValue"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new Value instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Value
             * @static
             * @param {google.protobuf.IValue=} [properties] Properties to set
             * @returns {google.protobuf.Value} Value instance
             */
            Value.create = function create(properties) {
                return new Value(properties);
            };

            /**
             * Encodes the specified Value message. Does not implicitly {@link google.protobuf.Value.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Value
             * @static
             * @param {google.protobuf.IValue} message Value message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Value.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.nullValue != null && Object.hasOwnProperty.call(message, "nullValue"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.nullValue);
                if (message.numberValue != null && Object.hasOwnProperty.call(message, "numberValue"))
                    writer.uint32(/* id 2, wireType 1 =*/17).double(message.numberValue);
                if (message.stringValue != null && Object.hasOwnProperty.call(message, "stringValue"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.stringValue);
                if (message.boolValue != null && Object.hasOwnProperty.call(message, "boolValue"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.boolValue);
                if (message.structValue != null && Object.hasOwnProperty.call(message, "structValue"))
                    $root.google.protobuf.Struct.encode(message.structValue, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.listValue != null && Object.hasOwnProperty.call(message, "listValue"))
                    $root.google.protobuf.ListValue.encode(message.listValue, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Value message, length delimited. Does not implicitly {@link google.protobuf.Value.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Value
             * @static
             * @param {google.protobuf.IValue} message Value message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Value.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Value message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Value} Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Value.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Value();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.nullValue = reader.int32();
                        break;
                    case 2:
                        message.numberValue = reader.double();
                        break;
                    case 3:
                        message.stringValue = reader.string();
                        break;
                    case 4:
                        message.boolValue = reader.bool();
                        break;
                    case 5:
                        message.structValue = $root.google.protobuf.Struct.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.listValue = $root.google.protobuf.ListValue.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Value message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Value} Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Value.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Value message.
             * @function verify
             * @memberof google.protobuf.Value
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Value.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.nullValue != null && message.hasOwnProperty("nullValue")) {
                    properties.kind = 1;
                    switch (message.nullValue) {
                    default:
                        return "nullValue: enum value expected";
                    case 0:
                        break;
                    }
                }
                if (message.numberValue != null && message.hasOwnProperty("numberValue")) {
                    if (properties.kind === 1)
                        return "kind: multiple values";
                    properties.kind = 1;
                    if (typeof message.numberValue !== "number")
                        return "numberValue: number expected";
                }
                if (message.stringValue != null && message.hasOwnProperty("stringValue")) {
                    if (properties.kind === 1)
                        return "kind: multiple values";
                    properties.kind = 1;
                    if (!$util.isString(message.stringValue))
                        return "stringValue: string expected";
                }
                if (message.boolValue != null && message.hasOwnProperty("boolValue")) {
                    if (properties.kind === 1)
                        return "kind: multiple values";
                    properties.kind = 1;
                    if (typeof message.boolValue !== "boolean")
                        return "boolValue: boolean expected";
                }
                if (message.structValue != null && message.hasOwnProperty("structValue")) {
                    if (properties.kind === 1)
                        return "kind: multiple values";
                    properties.kind = 1;
                    {
                        let error = $root.google.protobuf.Struct.verify(message.structValue);
                        if (error)
                            return "structValue." + error;
                    }
                }
                if (message.listValue != null && message.hasOwnProperty("listValue")) {
                    if (properties.kind === 1)
                        return "kind: multiple values";
                    properties.kind = 1;
                    {
                        let error = $root.google.protobuf.ListValue.verify(message.listValue);
                        if (error)
                            return "listValue." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a Value message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Value
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Value} Value
             */
            Value.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Value)
                    return object;
                let message = new $root.google.protobuf.Value();
                switch (object.nullValue) {
                case "NULL_VALUE":
                case 0:
                    message.nullValue = 0;
                    break;
                }
                if (object.numberValue != null)
                    message.numberValue = Number(object.numberValue);
                if (object.stringValue != null)
                    message.stringValue = String(object.stringValue);
                if (object.boolValue != null)
                    message.boolValue = Boolean(object.boolValue);
                if (object.structValue != null) {
                    if (typeof object.structValue !== "object")
                        throw TypeError(".google.protobuf.Value.structValue: object expected");
                    message.structValue = $root.google.protobuf.Struct.fromObject(object.structValue);
                }
                if (object.listValue != null) {
                    if (typeof object.listValue !== "object")
                        throw TypeError(".google.protobuf.Value.listValue: object expected");
                    message.listValue = $root.google.protobuf.ListValue.fromObject(object.listValue);
                }
                return message;
            };

            /**
             * Creates a plain object from a Value message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Value
             * @static
             * @param {google.protobuf.Value} message Value
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Value.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (message.nullValue != null && message.hasOwnProperty("nullValue")) {
                    object.nullValue = options.enums === String ? $root.google.protobuf.NullValue[message.nullValue] : message.nullValue;
                    if (options.oneofs)
                        object.kind = "nullValue";
                }
                if (message.numberValue != null && message.hasOwnProperty("numberValue")) {
                    object.numberValue = options.json && !isFinite(message.numberValue) ? String(message.numberValue) : message.numberValue;
                    if (options.oneofs)
                        object.kind = "numberValue";
                }
                if (message.stringValue != null && message.hasOwnProperty("stringValue")) {
                    object.stringValue = message.stringValue;
                    if (options.oneofs)
                        object.kind = "stringValue";
                }
                if (message.boolValue != null && message.hasOwnProperty("boolValue")) {
                    object.boolValue = message.boolValue;
                    if (options.oneofs)
                        object.kind = "boolValue";
                }
                if (message.structValue != null && message.hasOwnProperty("structValue")) {
                    object.structValue = $root.google.protobuf.Struct.toObject(message.structValue, options);
                    if (options.oneofs)
                        object.kind = "structValue";
                }
                if (message.listValue != null && message.hasOwnProperty("listValue")) {
                    object.listValue = $root.google.protobuf.ListValue.toObject(message.listValue, options);
                    if (options.oneofs)
                        object.kind = "listValue";
                }
                return object;
            };

            /**
             * Converts this Value to JSON.
             * @function toJSON
             * @memberof google.protobuf.Value
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Value.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Value;
        })();

        /**
         * NullValue enum.
         * @name google.protobuf.NullValue
         * @enum {number}
         * @property {number} NULL_VALUE=0 NULL_VALUE value
         */
        protobuf.NullValue = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "NULL_VALUE"] = 0;
            return values;
        })();

        protobuf.ListValue = (function() {

            /**
             * Properties of a ListValue.
             * @memberof google.protobuf
             * @interface IListValue
             * @property {Array.<google.protobuf.IValue>|null} [values] ListValue values
             */

            /**
             * Constructs a new ListValue.
             * @memberof google.protobuf
             * @classdesc Represents a ListValue.
             * @implements IListValue
             * @constructor
             * @param {google.protobuf.IListValue=} [properties] Properties to set
             */
            function ListValue(properties) {
                this.values = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ListValue values.
             * @member {Array.<google.protobuf.IValue>} values
             * @memberof google.protobuf.ListValue
             * @instance
             */
            ListValue.prototype.values = $util.emptyArray;

            /**
             * Creates a new ListValue instance using the specified properties.
             * @function create
             * @memberof google.protobuf.ListValue
             * @static
             * @param {google.protobuf.IListValue=} [properties] Properties to set
             * @returns {google.protobuf.ListValue} ListValue instance
             */
            ListValue.create = function create(properties) {
                return new ListValue(properties);
            };

            /**
             * Encodes the specified ListValue message. Does not implicitly {@link google.protobuf.ListValue.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.ListValue
             * @static
             * @param {google.protobuf.IListValue} message ListValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListValue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.values != null && message.values.length)
                    for (let i = 0; i < message.values.length; ++i)
                        $root.google.protobuf.Value.encode(message.values[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ListValue message, length delimited. Does not implicitly {@link google.protobuf.ListValue.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.ListValue
             * @static
             * @param {google.protobuf.IListValue} message ListValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListValue.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ListValue message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.ListValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.ListValue} ListValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListValue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ListValue();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.values && message.values.length))
                            message.values = [];
                        message.values.push($root.google.protobuf.Value.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ListValue message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.ListValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.ListValue} ListValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListValue.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ListValue message.
             * @function verify
             * @memberof google.protobuf.ListValue
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ListValue.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.values != null && message.hasOwnProperty("values")) {
                    if (!Array.isArray(message.values))
                        return "values: array expected";
                    for (let i = 0; i < message.values.length; ++i) {
                        let error = $root.google.protobuf.Value.verify(message.values[i]);
                        if (error)
                            return "values." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ListValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.ListValue
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.ListValue} ListValue
             */
            ListValue.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.ListValue)
                    return object;
                let message = new $root.google.protobuf.ListValue();
                if (object.values) {
                    if (!Array.isArray(object.values))
                        throw TypeError(".google.protobuf.ListValue.values: array expected");
                    message.values = [];
                    for (let i = 0; i < object.values.length; ++i) {
                        if (typeof object.values[i] !== "object")
                            throw TypeError(".google.protobuf.ListValue.values: object expected");
                        message.values[i] = $root.google.protobuf.Value.fromObject(object.values[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a ListValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.ListValue
             * @static
             * @param {google.protobuf.ListValue} message ListValue
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListValue.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.values = [];
                if (message.values && message.values.length) {
                    object.values = [];
                    for (let j = 0; j < message.values.length; ++j)
                        object.values[j] = $root.google.protobuf.Value.toObject(message.values[j], options);
                }
                return object;
            };

            /**
             * Converts this ListValue to JSON.
             * @function toJSON
             * @memberof google.protobuf.ListValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ListValue;
        })();

        return protobuf;
    })();

    return google;
})();

export { $root as default };
