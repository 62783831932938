import * as d3 from "d3";
import ReactGA from "react-ga";
import simplify from 'simplify-js';
import { BoundKonvaBoard } from "../components/BoundKonvaBoard";
import { WChart } from "../helpers/WChart";
import {  store } from "../store/store";
import { Tool, uiStore } from "../store/UIState";
import { undoredo } from "../store/undoredo";
import { controller } from "./controller";
import { CodeSticker } from "../model/CodeSticker";
import { Shape, StrokeShape, FigureShape, StickerShape, StickerContentType, ShapeType, TextShape, TextAreaShape, ChartShape, FigureShapeType } from "../model/shape";
import { simplifyCurve } from "../helpers/simplify";
import { logger } from "../logging/logger";
import Long from "long"
export enum LayerType {
    Static = 0,
    Dynamic,
    Selection
}

export class LayerController {
    newStorke?: StrokeShape
    newFigure?: FigureShape
    isDraggingTransformer: boolean
    dragHandleIdx: number

    constructor(public board: BoundKonvaBoard) {
        this.isDraggingTransformer = false
        this.dragHandleIdx = -1
    }

    setTransform(transform: d3.ZoomTransform) {
        logger.info("setTransform")
        d3.select(this.board.dynLayer.canvas).call(this.board.zoom.transform, transform)
    }


    dragStart(x: number, y: number) {
        if (this.dragStartTransformer(x, y)) return true
        if (this.dragStartStroke(x, y)) return true
        if (this.dragStartFigure(x, y)) return true
        return false
    }

    drag(x: number, y: number) {
        if (this.dragErase(x, y)) return true;
        if (this.dragTransformer(x, y)) return true
        if (this.dragStroke(x, y)) return true
        if (this.dragFigure(x, y)) return true

        return false

    }
    dragRender() {
        if ([Tool.Pen, Tool.Marker, Tool.MultiLine, Tool.Highlight].includes(uiStore.ui.tool) && (this.newStorke)) {
            this.board.dynLayer.render([this.newStorke])
        }
        if ([Tool.Segment].includes(uiStore.ui.tool) && (this.newStorke)) {
            this.renderDynamicShapes()
        }

        if ([Tool.Ellipse, Tool.Rectangle, Tool.TextArea].includes(uiStore.ui.tool) && this.newFigure) {
            this.renderDynamicShapes()
        }

        if (this.isDraggingTransformer) {
            this.renderDynamicShapes()
        }
    }


    dragEnd(x: number, y: number) {
        if (this.dragEndTransformer(x, y)) return true
        if (this.dragEndStroke()) return true
        if (this.dragEndFigure()) return true
        if (this.dragEndTextArea()) return true
        return false
    }

    click(x: number, y: number) {
        if (this.clickTransformer(x, y)) return true
        if (this.clickText(x, y)) return true
        if (this.clickTextArea(x, y)) return true
        if (this.clickChart(x, y)) return true

        if (this.clickSticker(x, y)) return true
        this.setSelection(undefined)

        //this.board.dynLayer.hideTransformer()
        return false
    }

    clickTransformer(x: number, y: number) {

        if ([Tool.Select, Tool.TextArea].includes(uiStore.ui.tool)) {
            const shape = this.board.selLayer.getShape({ x, y })

            if (shape) {
                this.setSelection(shape)
                return true
            }
        }
        return false


        // return this.board.dynLayer.clickTransformer(x,y)
    }

    clickSticker(x: number, y: number) {
        console.log("clickSticker")
        if ([Tool.Sticker, Tool.StickerVideo, Tool.StickerCode].includes(uiStore.ui.tool)) {
            let contentType = "markdown"
            switch (uiStore.ui.tool) {
                case Tool.StickerVideo:
                    contentType = "video"
                    break
                case Tool.StickerCode:
                    contentType = "code"
                    break
            }

            ReactGA.event({
                category: 'Sticker',
                action: 'Add sticker',
            });
            let sticker: StickerShape = {
                OwnerID : store.user?.ID || Long.NEG_ONE,
                ID : Long.NEG_ONE, 
                transform : {sx : 1, sy : 1, tx : x, ty : y, r : 0},
                Width: 300,
                Height: 300,
                Content: "",
                StickerContentType : StickerContentType.Markdown,
                FontSize: 14,
                Color: "lightyellow",
                Type : ShapeType.Sticker

            }
            if (contentType === 'code') {
                sticker = new CodeSticker(sticker)
            }

            controller.socketController.addSticker(sticker, (id)=> {
                sticker.ID = id
                controller.socketController.callbacks.addSticker(sticker)
                store.boardUI.editedSticker =  store.board.stickers.find(sticker=>sticker.ID.equals( id))
            })
            return true
        }
        return false
    }
    clickText(x: number, y: number) {
        if (uiStore.ui.tool === Tool.Text) {
            ReactGA.event({
                category: 'Text',
                action: 'Add text',
            });
            logger.info("LayerController::clickText")

            let text: TextShape = {
                OwnerID : store.user?.ID || Long.NEG_ONE,
                ID : Long.NEG_ONE, 
                Family: store.defaultStrokeParam.font,
                Size: 24,
                Color: store.defaultStrokeParam.color,
                Content :"",
                transform: { sx: 2, sy: 2, tx: x,ty: y, r: 0 },
                Type : ShapeType.Text, 
            }


            store.addShape(text)
            // controller.notifyAddShape(text)
            controller.socketController.addText(text, (id)=> {text.ID = id})
            this.setSelection(text)
            undoredo.addShape(text)
            return true
        }
        return false

    }

    clickTextArea(x: number, y: number) {
        if (uiStore.ui.tool === Tool.TextArea) {
            ReactGA.event({
                category: 'TextArea',
                action: 'Add text area',
            });

            let textArea: TextAreaShape = {
                OwnerID : store.user?.ID || Long.NEG_ONE,
                ID : Long.NEG_ONE,
                Family: store.defaultStrokeParam.font,
                Size: 24,
                Color: store.defaultStrokeParam.color,
                Style: "bold",
                Content: "",
                transform: { sx: 1, sy: 1, tx: x, ty: y, r: 0 },
                Type : ShapeType.TextArea,
                Width: 300,
                Height: 300
            }
            store.addShape(textArea)
            controller.socketController.addTextArea(textArea, (id)=> {textArea.ID = id})
            this.setSelection(textArea)
            undoredo.addShape(textArea)
            return true
        }
        return false

    }

    clickChart(x: number, y: number) {
        if (uiStore.ui.tool === Tool.Chart) {
            ReactGA.event({
                category: 'Chart',
                action: 'Add chart',
            });

            const chart: ChartShape = {
                OwnerID : store.user?.ID || Long.NEG_ONE,
                ID : Long.NEG_ONE, 
                Color: store.defaultStrokeParam.color,
                LineWidth: store.defaultStrokeParam.strokeWidth,
                Opacity: 1,
                Definition: "x=sin(range(100)/10);plot(x)",
                transform: { sx: 1, sy: 1, tx: x, ty: y, r: 0 },
                Type: ShapeType.Chart,
                //userId: uiStore.user?.id || 0,
                // timestamp: new Date(),
                Width: 300,
                Height: 300
            }

            const wchart = new WChart(chart)
            store.addShape(wchart)
            controller.socketController.addWChart(wchart.chart(), (id)=>wchart.ID=id)
            this.setSelection(wchart)
            undoredo.addShape(wchart)
            return true
        }
        return false

    }


    dragStartTransformer(x: number, y: number) {
        if (this.board.dynLayer.dragStartTransformer(x, y)) {
            this.isDraggingTransformer = true
            return true
        } else {
            this.isDraggingTransformer = false
            return false
        }


    }



    dragTransformer(x: number, y: number) {
        if (this.board.dynLayer.dragTransformer(x, y)) {
            this.redraw([LayerType.Dynamic])
            return true
        } else
            return false

    }
    dragEndTransformer(x: number, y: number) {
        return this.board.dynLayer.dragEndTransformer(x, y)

    }

    dragStartFigure(x: number, y: number) {
        if ([Tool.Ellipse, Tool.Rectangle, Tool.TextArea].includes(uiStore.ui.tool)) {

            let shapeType: FigureShapeType;
            switch (uiStore.ui.tool) {
                case Tool.Ellipse:
                    shapeType = FigureShapeType.Ellipse
                    break
                case Tool.Rectangle:
                    shapeType = FigureShapeType.Rectangle
                    break
                default:
                    shapeType = FigureShapeType.Rectangle
            }

            this.newFigure = {
                OwnerID : store.user?.ID || Long.NEG_ONE,
                ID : Long.NEG_ONE, 
                Type : ShapeType.Figure,
                //timestamp: new Date(),
                Color: store.defaultStrokeParam.color,
                Opacity: uiStore.ui.tool === Tool.Marker ? 0.5 : 1,
                transform: { sx: 1, sy: 1, tx: x, ty: y, r: 0 },
                Fill: false,
                StrokeWidth: store.defaultStrokeParam.strokeWidth,
                Height: 0,
                Width: 0,
                ShapeType: shapeType
                // type: "figure",
                // userId: uiStore.user?.id
                    // || 0
            }
            this.board.dynLayer.render([this.newFigure])
            return true
        }
        return false
    }
    dragStartStroke(x: number, y: number) {
        if ([Tool.Pen, Tool.Marker, Tool.MultiLine, Tool.Segment, Tool.Highlight].includes(uiStore.ui.tool)) {

            if (uiStore.ui.tool === Tool.Segment && store.defaultStrokeParam.snap) {
                x = Math.round(x / 20) * 20
                y = Math.round(y / 20) * 20
            }
            this.newStorke = {
                OwnerID : store.user?.ID || Long.NEG_ONE,
                ID : Long.NEG_ONE, 
                // timestamp: new Date(),
                PointArray: [],
                Color: store.defaultStrokeParam.color,
                Opacity: [Tool.Marker, Tool.MultiLine].includes(uiStore.ui.tool)? store.defaultStrokeParam.opacity : 1,
                Smooth: store.defaultStrokeParam.smoothFactor > 0,
                transform: { sx: 1, sy: 1, tx: 0, ty: 0, r: 0 },
                Type: uiStore.ui.tool === Tool.Highlight ? ShapeType.Highlight : ShapeType.Stroke,
                Width: uiStore.ui.tool === Tool.Pen ? store.defaultStrokeParam.strokeWidth : store.defaultStrokeParam.strokeWidth * 2,
                Closed: false,
                // userId: uiStore.user?.id || 0
            }

            if (uiStore.ui.tool === Tool.Highlight) this.newStorke.Width *= 2
            this.newStorke.PointArray.push([ x, y ])
            this.board.dynLayer.render([this.newStorke])
            return true
        }
        return false
    }

    private dragErase(x: number, y: number) {
        if (uiStore.ui.tool === Tool.Erase) {
            const shape = this.board.selLayer.getShape({ x, y })


            if (shape) {
                return controller.deleteShape(shape)

            }
        }
        return false
    }


    private dragFigure(x: number, y: number) {
        if ([Tool.Ellipse, Tool.Rectangle, Tool.TextArea].includes(uiStore.ui.tool) && this.newFigure) {
            const width = x - this.newFigure.transform.tx;
            const height = y - this.newFigure.transform.ty;
            this.newFigure.Width = width;
            this.newFigure.Height = height;
            return true
        }
        return false
    }

    private dragStroke(x: number, y: number) {
        if ([Tool.Pen, Tool.Marker, Tool.MultiLine, Tool.Highlight].includes(uiStore.ui.tool) && this.newStorke) {
            this.newStorke.PointArray.push([x, y ]);
            return true
        }
        else if (uiStore.ui.tool === Tool.Segment && this.newStorke) {
            if (store.defaultStrokeParam.snap) {
                x = Math.round(x / 20) * 20;
                y = Math.round(y / 20) * 20;
            }
            this.newStorke.PointArray[1] = ( [x, y] );
            return true
        }
        return false
    }
    private dragEndTextArea() {
        if (uiStore.ui.tool === Tool.TextArea && this.newFigure) {
            const newFigure = this.newFigure;
            this.addTextArea(newFigure);
            this.newFigure = undefined;
            return true
        }
        return false
    }

    private dragEndFigure() {
        if ([Tool.Ellipse, Tool.Rectangle].includes(uiStore.ui.tool) && this.newFigure) {
            this.addFigure(this.newFigure);
            return true
        }
        return false
    }


    private dragEndStroke() {
        if ([Tool.Pen, Tool.Marker, Tool.MultiLine, Tool.Segment].includes(uiStore.ui.tool) && this.newStorke) {
            store.board.dynamicStrokes.push(this.newStorke)
            const stroke = this.newStorke;
            this.addStroke(stroke);

            this.newStorke = undefined;
            if ([Tool.Pen, Tool.Marker, Tool.MultiLine].includes(uiStore.ui.tool) && store.defaultStrokeParam.closedShape) {
                this.updateRenderedFigures()
            }

            if (store.defaultStrokeParam.smoothFactor > 0) this.renderDynamicShapes()


            return true
        }
        if (uiStore.ui.tool === Tool.Highlight && this.newStorke) {
            const stroke = this.newStorke
            stroke.PointArray = simplifyCurve(stroke.PointArray);
            stroke.Smooth = true

            this.newStorke = undefined
            store.board.highlighStrokes.push(stroke)
            controller.socketController.addHighlight(stroke, store.highlightFocusOthers)
            this.renderHighlight()

        }

        return false
    }
    intervalHandler?: number

    renderHighlight() { 
        if (!this.intervalHandler) {
            this.intervalHandler = setInterval(() => this.renderHighlight(), 30)
        } else {
            store.board.highlighStrokes.forEach(stroke => stroke.Opacity -= 0.02)
            store.board.highlighStrokes = store.board.highlighStrokes.filter(stroke => stroke.Opacity > 0)
            this.renderDynamicShapes()
            if (store.board.highlighStrokes.length === 0) {
                clearInterval(this.intervalHandler)
                this.intervalHandler = undefined
            }


        }
    }

    renderDynamicShapes() {
        const dynamicShapes: Shape[] = [...store.board.highlighStrokes, ...store.board.dynamicStrokes]
        if (this.newFigure) dynamicShapes.push(this.newFigure)
        if (this.newStorke) dynamicShapes.push(this.newStorke)
        if (store.board.editedShape) dynamicShapes.push(store.board.editedShape)
        this.board.dynLayer.clear()
        this.board.dynLayer.render(dynamicShapes)
    }


    private addTextArea(newFigure: FigureShape) {
        if (newFigure.Width > 5 && newFigure.Height > 5) {
            const ta: TextAreaShape = {
                OwnerID : store.user?.ID || Long.NEG_ONE,
                ID : Long.NEG_ONE, 
                Color: newFigure.Color,
                Family: store.defaultStrokeParam.font,
                Height: newFigure.Height,
                Width: newFigure.Width,
                Size: 24,
                Style: "",
                transform: newFigure.transform,
                Type: ShapeType.TextArea,
                //userId: newFigure.userId,
                Content: "",
            };
            store.addShape(ta);

            controller.socketController.addTextArea(ta, (id)=> ta.ID =id)
            undoredo.addShape(ta)
            this.setSelection(ta)

            this.redraw()

        }
    }


    redraw(which: LayerType[] = []) {
        if (which.length === 0) {
            this.board.baseLayer.redraw()
            this.board.selLayer.redraw()
            this.board.dynLayer.redraw()
        } else {
            for (let layer of which) {
                switch (layer) {
                    case LayerType.Dynamic:
                        this.board.dynLayer.redraw()
                        break
                    case LayerType.Selection:
                        this.board.selLayer.redraw()
                        break
                    case LayerType.Static:
                        this.board.baseLayer.redraw()
                        break
                }
            }
        }
    }

    private addFigure(figure: FigureShape) {
        ReactGA.event({
            category: 'Figure',
            action: 'Add figure',
        });

        store.addShape(figure);

        controller.socketController.addFigure(figure, (id)=>figure.ID = id)
        undoredo.addShape(figure)
        this.updateRenderedFigures()
    }

    private addStroke(stroke: StrokeShape) {
        ReactGA.event({
            category: 'Stroke',
            action: 'Add stroke',
        });
        if (store.defaultStrokeParam.smoothFactor > 0 && (uiStore.ui.tool === Tool.Pen || uiStore.ui.tool === Tool.Marker)) {
            let C = store.defaultStrokeParam.smoothFactor * 3;
            stroke.PointArray = simplifyCurve(stroke.PointArray, C, C > 10 ? true : false);
            let p1 = stroke.PointArray[0];
            let p2 = stroke.PointArray[stroke.PointArray.length - 1];
            if ((p2[0] - p1[0]) * (p2[0] - p1[0]) + (p2[1] - p1[1]) * (p2[1] - p1[1]) < C * C && stroke.PointArray.length > 2) {
                stroke.PointArray.pop();
            }
        }
        else if (uiStore.ui.tool === Tool.MultiLine) {
            stroke.PointArray = simplifyCurve(stroke.PointArray, 30, true);
            stroke.Smooth = false;
            if (store.defaultStrokeParam.snap) {
                stroke.PointArray = stroke.PointArray.map((point) => {
                    return [Math.round(point[0] / 20) * 20, Math.round(point[1] / 20) * 20]
                });
            }
        }
        stroke.Closed = store.defaultStrokeParam.closedShape;
        if (stroke.PointArray.length === 2)
            stroke.Closed = false;

        store.addShape(stroke);
        controller.socketController.addStroke(stroke, (id )=> stroke.ID = id)
        undoredo.addShape(stroke)
        if (uiStore.ui.tool !== Tool.Pen) this.updateRenderedFigures()
    }



    updateRenderedFigures() {
        const allShapes = store.shapes()
        logger.info({message: "updateRenderedFigures", data: {shapeLength : allShapes.length}})
        this.board.baseLayer.clear()
        this.board.dynLayer.clear()
        this.board.selLayer.clear()
        store.board.dynamicStrokes = []

        this.board.baseLayer.render(store.shapes())
        this.board.selLayer.render(store.shapes(false))

        if (store.board.editedShape) {
            //this.board.dynLayer.updateTransformer()
            this.renderDynamicShapes()

        }
        else
            this.board.dynLayer.render([])
    }



    setSelection(shape?: Shape) {
        if (shape) {
            // if (["text", "textarea", "image", "chart", "sticker"].includes(shape.type)) {
                if ([ShapeType.Text, ShapeType.TextArea, ShapeType.Image, ShapeType.Chart, ShapeType.Sticker].includes(shape.Type)) {
                this.board.dynLayer.showTransformer(shape)
                store.setEditedShape(shape)
            } else {
                store.clearEditedShape()

            }
        } else {
            this.board.dynLayer.hideTransformer()
            store.clearEditedShape()


        }
        this.updateRenderedFigures()

    }


    updateShape(shape : Shape) {
        if (this.board.dynLayer.transformedShape?.ID.equals(shape.ID)){
            this.board.dynLayer.updateTransformerShape(shape)
            this.redraw([LayerType.Dynamic])
        }
        this.updateRenderedFigures()
    }
}