import { observer } from "mobx-react"
import React from "react"
import Button from "react-bootstrap/Button"
import ButtonGroup from "react-bootstrap/ButtonGroup"
import Col from "react-bootstrap/Col"
import Dropdown from "react-bootstrap/esm/Dropdown"
import Row from "react-bootstrap/Row"
import { controller } from "../controller/controller"
import { store } from "../store/store"
import { uiStore } from "../store/UIState"
import "./ToolComponent.css"
import { Toolparam } from "./Toolparam"
import { ButtonId, tools } from "./Tools"




export const ToolComponent: React.FC = observer(() => {

    function setCurrentTool(buttonID: ButtonId) {
        
        const tool = tools.get(buttonID)?.tool
        if (tool) controller.setTool(tool)
        tools.get(buttonID)?.onClick()
    }

    const items = store.toolbarConfig.toolLines.map((toolLine) => {
        if (toolLine.buttonIds.length > 1)
            return <Dropdown drop="right"   >
                <Dropdown.Toggle variant={uiStore.ui.tool == tools.get(toolLine.currentButtonId)?.tool ? "info" : "light"} id="dropdown-basic" style={{ width: "70px" }} >
                    {tools.get(toolLine.currentButtonId)?.iconElement}
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ maxWidth: "30px", minWidth: "10px" }} >
                    {toolLine.buttonIds.map((toolID) => <Dropdown.Item onClick={() => {
                        console.log("SetCurrentTool", toolID)
                        toolLine.currentButtonId = toolID
                        setCurrentTool(toolID)
                    }}>{tools.get(toolID)?.iconElement}</Dropdown.Item>)}
                </Dropdown.Menu>

            </Dropdown>
        else return <Button variant={uiStore.ui.tool == tools.get(toolLine.currentButtonId)?.tool ? "info" : "light"} style={{ width: "70px" }} onClick={() => {
            setCurrentTool(toolLine.currentButtonId)
        }}>
            {tools.get(toolLine.currentButtonId)?.iconElement}
        </Button>


    }

    )


    return <>

        <div className="position-absolute w-100" style={{ top: "0px" }}>
            <Row>
                <Col>
                    <Toolparam />
                </Col>

            </Row>

        </div>
        <ButtonGroup vertical className="position-absolute" style={{ top: "50px" }}>
            {items}
            {/* <Button><FontAwesomeIcon icon={faArrowsAlt} size="2x" /></Button>
            <Button>Button</Button>
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                <FontAwesomeIcon icon={faArrowsAlt} size="2x" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown> */}
        </ButtonGroup>

    </>
})