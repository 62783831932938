import React from 'react';
import styled from 'styled-components';
import screen from './pics/screen.jpg'
import patreon from './pics/patreon.png'
import ReactGA from 'react-ga'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { Trans } from '@lingui/macro';
import facebook from './ikons/facebook.png'
import vk from './ikons/vk.png'
import instagram from './ikons/instagram.png'
import { controller } from './controller/controller';


const LineStart = styled.div` 
    display : flex;
    justify-content: flex-start;
`


const LineCenter = styled.div` 
    display : flex;
    justify-content: space-around;
`

const Line = styled.div` 
    display : flex;
`

const Line80 = styled(LineCenter)`
 width :80%;
 margin : auto;
`
const ImgParent = styled.div`
    width: 30%;
`

const AutoImg = styled.img`
    display:block;
    
    width:100%;
    height:auto;
`

const GreenGreetText = styled.div`
    font-size:36px;
    font-family:InkFree;
    color: #067a17;
    margin : 0px;
    p {
        margin:0px;
    }

`

const GreetText = styled.div`
    font-size:36px;
    font-family:Slimamif;

`

const Heading = styled.p`
    font-size:48px;
    font-family:InkFree;
    text-align : center;
    color: #067a17;
    font-weight:bold;
    margin :10px;
`


const CreateBoard = styled.a`
    margin-left: 10px;
    font-size : 36px;
    font-family:Arial, Helvetica, sans-serif;
    background-color: orange;
    padding : 20px;
    border-radius : 20px;
    text-decoration : none;
    color : white;
    display:block;

`

const Email = styled.a`
    font-size : 24px;
    font-family:Arial, Helvetica, sans-serif;
    background-color: #7cb424;
    padding : 20px;
    border-radius : 20px;
    text-decoration : none;
    color : white;

`

const Style3 = styled.p`

    font-size : 36px;
    font-family:Arial, Helvetica, sans-serif;
    background-color: #2ec2b9;
    padding : 20px;
    border-radius : 20px;
    text-decoration : none;
    color : white;
`

const InfoText = styled.p`
font-family:Arial, Helvetica, sans-serif;
color: #067a17;

    font-size : 24px;
    width : 80%;

`
const LinkComment = styled.p`
    font-family:Slimamif;
    font-weight:bold;
    font-size : 24px;
    margin : 20px;
    text-align: justify;
    color:orange;
    width:60%;
    display:block;

`

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
        return v.toString(16);
    });
}

export const GreetApp = () => {
    ReactGA.pageview("/");
    controller.setBoardName(undefined)
    return <div style={{ overflowY: "auto", height: "100%" }}>


        <LineStart>
            <a style={{ textAlign: "right" }} href="https://www.patreon.com/bePatron?u=5534322" data-patreon-widget-type="become-patron-button"><img src={patreon} alt="Become a patreon" width="220px" /></a>
        </LineStart>

        <LineCenter>

            <script async src="https://c6.patreon.com/becomePatronButton.bundle.js"></script>
            <Heading>O-Whiteboard</Heading>
        </LineCenter>
        <LineCenter>
            <GreenGreetText>
                <p><Trans id='online whiteboard'>Online whiteboard</Trans></p>
                <p style={{ textAlign: "center" }}><Trans>Quick & Easy</Trans></p>

            </GreenGreetText>
        </LineCenter>

        <LineCenter >
            {/* <ImgParent><AutoImg src={screen} alt="whiteboard screen" /></ImgParent> */}
            <GreetText >
                <ul>
                    <li><Trans>Co-working </Trans></li>
                    <li><Trans>Collaboration</Trans></li>
                    <li><Trans>Education</Trans></li>
                </ul>
                <div>
                    <LineStart><FontAwesomeIcon icon={faArrowCircleRight} size={"2x"} color={"orange"} /> <CreateBoard href={`/board/${uuidv4()}`}><Trans>Create your board</Trans></CreateBoard></LineStart>
                    {/* <LineStart><LinkComment ><Trans id="unique_link"></Trans></LinkComment></LineStart> */}
                </div>
                <ul>
                    <li><Trans>write</Trans></li>
                    <li><Trans>draw</Trans></li>
                    <li><Trans>share</Trans></li>
                </ul>

            </GreetText>
        </LineCenter>

        <LineCenter>

            <Style3><Trans id="work_learn">Work and learn together from any point of the world</Trans></Style3>
        </LineCenter>
        <Line80>
            <InfoText>
                <Trans id="info1">
                    O-whiteboard  - free online whiteboard for education, co-working, webinars all over the world.
                    Here you can write and draw with different tools on an infinite whiteboard.
                </Trans>
            </InfoText>
        </Line80>
        <Line80>
            <InfoText>
                <Trans id="info2">
                    O-whiteboard is quick and easy. All you need for working is an access to web browsers on any supported devices, including PCs, tablets, smartphones.
                    It's speed allows comfortable stylus use in many applications.
                </Trans>
            </InfoText>
        </Line80>

        <Line80>
            <Email href="mailto:owhiteboard.com@gmail.com">Email: owhiteboard.com@gmail.com</Email>
        </Line80>

        <Line>
            <div style={{ alignSelf: "flex-start", height: "31px" }} >
                <a href="https://vk.com/public193168091"><img src={vk} height="31" alt="vk" /></a>
                <a href="https://www.instagram.com/o_whiteboard/"><img src={instagram} height="31" alt="instagram" /></a>
                <a href="https://www.facebook.com/o.whiteboard/?view_public_for=106114141021816"><img src={facebook} height="31" alt="facebook" /></a>

            </div>

            <div style={{ marginLeft:"auto", alignSelf: "flex-end", height: "31px" }} >
                <a href="//www.liveinternet.ru/click" target="_blank">
                    <img src="//counter.yadro.ru/hit?t44.6;rescape(document.referrer)+((typeof(screen)=='undefined')?'':
';s'+screen.width+'*'+screen.height+'*'+(screen.colorDepth?
screen.colorDepth:screen.pixelDepth))+';u'+escape(document.URL)+
';h'+escape(document.title.substring(0,150))+';'+Math.random()+
'" alt="" title="LiveInternet" width="31" height="31"></img></a>

            </div>
        </Line>

    </div>
}