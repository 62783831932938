import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import log from 'loglevel'
import './fonts/InkFree.ttf'
import './fonts/homoarak.ttf'
import './fonts/Slimamif.ttf'
import './fonts/Freestyle.ttf'
import 'bootstrap/dist/css/bootstrap.min.css';

import { I18nProvider } from '@lingui/react'
import catalogEn from './locales/en/messages.js'
import catalogRu from './locales/ru/messages.js'
import { LocaleResolver, DETECTORS, TRANSFORMERS } from 'locales-detector';


let locales = (new LocaleResolver([new DETECTORS.NavigatorDetector()], [new TRANSFORMERS.LanguageOnlyTransformer()])).getLocales();

let language = 'en'
if (locales.findIndex(l => l === 'ru') > -1) language = 'ru'
const catalogs = { en: catalogEn, ru: catalogRu };

log.enableAll()


ReactDOM.render(

    <BrowserRouter>
        <I18nProvider language={language} catalogs={catalogs}>

            <App />
        </I18nProvider>

    </BrowserRouter>
    ,

    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
