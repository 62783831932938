import { add, multiply, norm, subtract } from 'mathjs';
import { AffineTransform } from "../math/AffineTransform";
import { Rect } from "../math/Rect";
import { Transform } from '../model/shape';


export enum TransformerMode {
    Rect = 0,
    Transform = 1,
    Sticker = 2
}


function isPointInside(cp: [number, number][], P: [number, number]) {
    const isLeft = (P0: [number, number], P1: [number, number], P2: [number, number]) => {
        return ((P1[0] - P0[0]) * (P2[1] - P0[1]) - (P2[0] - P0[0]) * (P1[1] - P0[1]));
    }
    const b1 = isLeft(cp[0], cp[2], P) > 0
    const b2 = isLeft(cp[2], cp[4], P) > 0
    const b3 = isLeft(cp[4], cp[6], P) > 0
    const b4 = isLeft(cp[6], cp[0], P) > 0
    return b1 && b2 && b3 && b4


}


export class TransformerTool {

    rect: Rect
    transform: Transform
    mode: TransformerMode
    dragPosition: [number, number] = [0,0];

    constructor(rect: Rect, transform: Transform, mode: TransformerMode) {
        this.rect = rect
        this.transform = transform
        this.mode = mode

    }
    D = 20;
    R = 10

    getControlPoints(rect: Rect) {

        const m1 = AffineTransform.transform2Matrix(this.transform)


        const D = this.D

        const fm = m1
        //const fm = multiply(m2, m1);
        let pt = AffineTransform.apply(fm, rect.points()) //Image rect points after transform


        let v1 = subtract(pt[1], pt[0]) as number[];
        let v2 = subtract(pt[3], pt[0]) as number[];
        v1 = multiply(v1, 1. / (norm(v1) as number))
        v2 = multiply(v2, 1. / (norm(v2) as number))
        pt[0] = add(pt[0], multiply(v1, -D)) as [number, number]
        pt[0] = add(pt[0], multiply(v2, -D)) as [number, number]

        pt[1] = add(pt[1], multiply(v1, D)) as [number, number]
        pt[1] = add(pt[1], multiply(v2, -D)) as [number, number]

        pt[2] = add(pt[2], multiply(v1, D)) as [number, number]
        pt[2] = add(pt[2], multiply(v2, D)) as [number, number]

        pt[3] = add(pt[3], multiply(v1, -D)) as [number, number]
        pt[3] = add(pt[3], multiply(v2, D)) as [number, number]

        const res: [number, number][] = [
            pt[0], multiply(add(pt[0], pt[1]), 0.5) as [number, number],
            pt[1], multiply(add(pt[1], pt[2]), 0.5) as [number, number],
            pt[2], multiply(add(pt[2], pt[3]), 0.5) as [number, number],
            pt[3], multiply(add(pt[3], pt[0]), 0.5) as [number, number]
            

        ]
        if (this.mode !== TransformerMode.Sticker)
            res.push(add(multiply(add(pt[0], pt[1]), 0.5), multiply(v2, -2 * this.D)) as [number, number])
        return res
    }


    render = (context: CanvasRenderingContext2D) => {
        context.beginPath()


        context.fillStyle = "gray"
        context.strokeStyle = "gray"
        context.globalAlpha = 0.6
        context.lineWidth = 5
        context.lineJoin = 'round'



        const rc = this.rect;




        const cp = this.getControlPoints(rc)
        context.strokeStyle = "green"
        context.lineWidth = 3
        context.setLineDash([3, 6])



        context.beginPath()
        context.moveTo(cp[0][0], cp[0][1]);
        for (let i = 1; i < 4; ++i)
            context.lineTo(cp[i * 2][0], cp[i * 2][1])
        context.closePath()
        context.stroke() //Stroke path

        if (this.mode !== TransformerMode.Sticker) {
        context.beginPath()
        context.moveTo(cp[1][0], cp[1][1]);
        context.lineTo(cp[8][0], cp[8][1])
        context.stroke() //Stroke path
        }

        const R = this.R

        let points = [[-R, -R], [R, -R], [R, R], [-R, R]]
        points = AffineTransform.apply(AffineTransform.rotate(this.transform.r), points);

        for (let i = 0; i < cp.length; ++i) {
            context.setLineDash([1, 0])

            context.save()
            context.translate(cp[i][0], cp[i][1])
            context.beginPath()
            context.moveTo(points[0][0], points[0][1]);
            for (let i = 1; i < 4; ++i)
                context.lineTo(points[i][0], points[i][1])
            context.closePath()
            context.stroke() //Stroke path
            context.restore()
        }

    }

    isDrag(pos: { x: number, y: number }) {

        const ppos = [pos.x, pos.y] as [number, number]
        const rc = this.rect;
        const cp = this.getControlPoints(rc)
        const isDrag = isPointInside(cp, ppos)
        if (isDrag) {
             this.dragPosition = ppos

        }
        return isDrag
    }
    setDragPosition(pos: { x: number; y: number; }) {
        const ppos = [pos.x, pos.y] as [number, number]
        const delta = subtract(ppos, this.dragPosition) as [number, number]

        this.transform.tx += delta[0]
        this.transform.ty += delta[1]
        this.dragPosition = ppos
        
    }

    getHandleIdx(pos: { x: number, y: number }) {
        //let ppos = this._transform.invert([pos.x, pos.y])
        const ppos = [pos.x, pos.y]
        const rc = this.rect;
        const cp = this.getControlPoints(rc)
        for (let i = 0; i < cp.length; ++i) {
            const dist = norm(subtract(cp[i], ppos) as number[]) as number
            if (dist < this.R + 10) {
                return i
            }
        }
        return -1
    }


    setDragHandlePosition(dragIdx: number, pos: { x: number; y: number; }) {
        if (dragIdx < 8) {
            const transform = this.transform
            const rc = this.rect;
            const cp = this.getControlPoints(rc)





            const traslateHandle = (idx: number, kx: 1 | 0 | -1, ky: 1 | 0 | -1) => {
                const oidx = (idx + 4) % 8;
                const cp1 = AffineTransform.apply(
                    AffineTransform.multiply(
                        AffineTransform.rotate(-transform.r),
                        AffineTransform.translate(-cp[oidx][0], -cp[oidx][1])
                    )
                    , cp
                )

                //let ppos = this._transform.invert([pos.x, pos.y]);
                let ppos = ([pos.x, pos.y]);

                ppos = AffineTransform.apply(
                    AffineTransform.multiply(
                        AffineTransform.rotate(-transform.r),
                        AffineTransform.translate(-cp[oidx][0], -cp[oidx][1])
                    )
                    , [ppos]
                )[0]

                const w = cp1[2][0] - cp1[0][0]
                const h = cp1[6][1] - cp1[0][1]

                const W = w + kx * (ppos[0] - cp1[idx][0])
                const H = h + ky * (ppos[1] - cp1[idx][1])

                const cx = W / w
                const cy = H / h

                const M = AffineTransform.multiply(
                    AffineTransform.translate(cp[oidx][0], cp[oidx][1]),
                    AffineTransform.rotate(transform.r),
                    AffineTransform.scale(cx, cy),
                    AffineTransform.rotate(-transform.r),
                    AffineTransform.translate(-cp[oidx][0], -cp[oidx][1]),
                )
                const resTransform =  AffineTransform.matrix2Transform(
                    AffineTransform.multiply(
                        M,
                        AffineTransform.transform2Matrix(transform)
                    )
                )
                if (this.mode === TransformerMode.Rect || this.mode === TransformerMode.Sticker) {
                    this.rect.width *= resTransform.sx
                    this.rect.height *= resTransform.sy
                    resTransform.sx = 1
                    resTransform.sy = 1

                }

                return resTransform

            }





            switch (dragIdx) {
                case 0:
                    {
                        this.transform = traslateHandle(dragIdx, -1, -1)
                        break
                    }
                case 1:
                    {
                        this.transform = traslateHandle(dragIdx, 0, -1)
                        break
                    }

                case 2:
                    {
                        this.transform = traslateHandle(dragIdx, 1, -1)
                        break
                    }
                case 3:
                    {
                        this.transform = traslateHandle(dragIdx, 1, 0)
                        break

                    }


                case 4:
                    {
                        this.transform = traslateHandle(dragIdx, 1, 1)
                        break


                    }

                case 5:
                    {
                        this.transform = traslateHandle(dragIdx, 0, 1)


                        break

                    }


                case 6:
                    {
                        this.transform = traslateHandle(dragIdx, -1, 1)

                        break

                    }

                case 7:
                    {
                        this.transform = traslateHandle(dragIdx, -1, 0)
                        break


                    }


            }
            // console.log({ x0, y0, x1, y1 }, { x0n, y0n, x1n, y1n })

            // let dx = (x0n - x0) * boardStore.editedShape.transform.sx
            // let dy = (y0n - y0) * boardStore.editedShape.transform.sy
            // boardStore.editedShape.transform.tx += dx
            // boardStore.editedShape.transform.ty += dy

            // let cx = (x1n - x0n) / (x1 - x0)
            // let cy = (y1n - y0n) / (y1 - y0)
            // boardStore.editedShape.transform.sx = Math.max(0.1, boardStore.editedShape.transform.sx * cx)
            // boardStore.editedShape.transform.sy = Math.max(0.1, boardStore.editedShape.transform.sy * cy)



        } else {
            const cp = this.getControlPoints(this.rect)
            //const ppos = this._transform.invert([pos.x, pos.y]);
            const ppos = [pos.x, pos.y]
            let v = subtract(cp[0], cp[6]) as [number, number]
            //v = multiply(v, -1) as [number, number]
            const center = multiply(add(cp[0], cp[4]), 0.5) as [number, number];
            const v1 = subtract(ppos, center) as [number, number]
            let alpha0 = Math.atan2(v[1], v[0]);
            let alpha1 = Math.atan2(v1[1], v1[0]);
            const da = alpha1 - alpha0;

            const nt = AffineTransform.multiply(
                AffineTransform.translate(center[0], center[1]),
                AffineTransform.rotate(da),
                AffineTransform.translate(-center[0], -center[1]),
                AffineTransform.transform2Matrix(this.transform)
            )

            this.transform = AffineTransform.matrix2Transform(nt)
        }


    }
}
