
import log from "loglevel";
import { controller } from '../controller/controller';
import { WChart } from "../helpers/WChart";
import { logger } from "../logging/logger";
import { AccessMode } from "../model/board";
import { ConnectionState } from "../model/connectionState";
import { Session } from "../model/session";
import { ChartShape, ImageShape, ShapeType, StickerShape, StrokeShape, TextAreaShape, TextShape } from "../model/shape";
import { WImage } from "../model/WImage";
import { wjite } from "../proto/request";
import { store, User } from "../store/store";
import { uiStore } from '../store/UIState';
import { message2modelShape, message2modelTransform } from "./message2model";
import { AddStrokeNotify, UpdateTextNotify } from "./socketReponse";


export class SocketCallbacks {
    setConnectionState(connectionState: ConnectionState, session: Session) {
        logger.info({ message: "setConnectionState", data: { connectionStateID: connectionState.ConnectionID, SessionID: session.SessionID, userID: session.User.ID } })
        store.connectionState = connectionState
        localStorage.setItem('sessionID', session.SessionID)
    }
    setBoardList = (boardList: { _id: string, boardName: string }[]) => {
        log.debug("Controller::setBoardList", boardList)
        uiStore.boards = boardList
    }
    setBoardUsers(users: User[]) {
        log.info('<= setBoardUsers', users);
        uiStore.boardUsers = users
        // rpcController.setBoardUsers(users)
    }
    addShape(addShape: wjite.message.IAddShape) {

        if (addShape.shape) {
            logger.info({ message: "SocketCallbacks::addShape notify", data: { ID: addShape.shape.ID } })
            const shape = message2modelShape(addShape.shape)
            if (shape?.Type === ShapeType.Image) {
                const img = new WImage(shape as ImageShape)
                img.load().then(() => {
                    store.board.shapes.push(img)
                    controller.layerController?.updateRenderedFigures()
                })
            } else if (shape?.Type === ShapeType.Sticker) {
                store.board.stickers.push(shape as StickerShape)
                store.board.shapes.push(shape)
                controller.layerController?.updateRenderedFigures()


            } else if (shape?.Type === ShapeType.Chart) {
                const wshape = new WChart(shape as ChartShape)
                wshape.calcDefinition()
                store.addShape(wshape)
                controller.layerController?.updateRenderedFigures()


            } else if (shape) {
                store.board.shapes.push(shape)
                controller.layerController?.updateRenderedFigures()

            }

        }



    }
    addHighlight(msg: wjite.message.IAddHighlight) {
    // addHighlight(msg: { stroke: StrokeShape, transform?: { k: number, x: number, y: number } }) {
        if (!msg.shape) throw new Error("Highlight shape not defined")
        const shape = message2modelShape(msg.shape)

        log.info("=>onAddHighligh", msg)
        controller.addHighlight(shape as StrokeShape, msg.focus as boolean )
    }


    removeShape(msg: wjite.message.IRemoveShape) {
        log.info('<= removeShape', msg);
        const shape = store.board.shapes.find(shape => shape.ID.equals(msg.shapeID as Long.Long))
        if (shape) {
            store.removeShape(shape)
            controller.layerController?.updateRenderedFigures()
        } else {
            logger.error({ message: "Shape id not found", data: msg.shapeID })
        }
        // }
    }

    updateShape(updateShape: wjite.message.IUpdateShape) {
        logger.info("socketCallbacks::updateShape")
        if (updateShape.shape && updateShape.shape.ID) {
            const shape = store.getShape(updateShape.shape.ID)
            shape.transform = message2modelTransform(updateShape.shape.transform)
            if (updateShape.shape.text && shape.Type === ShapeType.Text) {
                const textShape = shape as TextShape
                textShape.Color = updateShape.shape.text.color || "black"
                textShape.Content = updateShape.shape.text.content || ""
                textShape.Family = updateShape.shape.text.family || ""
                textShape.Size = updateShape.shape.text.size || 12

            }
            if (updateShape.shape.textArea && shape.Type === ShapeType.TextArea) {
                const textAreaShape = shape as TextAreaShape
                textAreaShape.Color = updateShape.shape.textArea.color || "black"
                textAreaShape.Content = updateShape.shape.textArea.content || ""
                textAreaShape.Family = updateShape.shape.textArea.family || ""
                textAreaShape.Size = updateShape.shape.textArea.size || 12
                textAreaShape.Width = updateShape.shape.textArea.width || 100
                textAreaShape.Height = updateShape.shape.textArea.height || 100
            }
            if (updateShape.shape.sticker && shape.Type === ShapeType.Sticker) {

                const stickerShape = shape as StickerShape
                stickerShape.Color = updateShape.shape.sticker.color || "black"
                stickerShape.Content = updateShape.shape.sticker.content || ""
                stickerShape.Width = updateShape.shape.sticker.width || 100
                stickerShape.Height = updateShape.shape.sticker.height || 100
                stickerShape.FontSize = updateShape.shape.sticker.fontSize || 12

                let idx = store.board.stickers.findIndex(s => s.ID.equals(stickerShape.ID))
                if (idx > -1) store.board.stickers[idx] = stickerShape

            }

            controller.layerController?.updateShape(shape)
            if (store.board.editedShape && store.board.editedShape.ID.equals(shape.ID)) {
                store.setEditedShape(shape)
                controller.board?.dynLayer.hideTransformer()
                controller.board?.dynLayer.showTransformer(shape)
                controller.layerController?.updateRenderedFigures()

            }



        }
        //controller.updateShape(shape)
    }
    addSticker(sticker: StickerShape) {
        store.board.stickers.push(sticker)

    }

    addStroke(strokeNotify: AddStrokeNotify) {
        logger.info({ message: "SocketCallbacks::addStroke", data: strokeNotify.Stroke.ID })
        store.board.shapes.push(strokeNotify.Stroke)
        // controller.layerController?.board.baseLayer.clear()
        controller.layerController?.updateRenderedFigures()

    }

    updateText(textNotify: UpdateTextNotify) {
        logger.info({ message: "SocketCallbacks::updateText", data: textNotify.Text.Content })
        controller.updateShape(textNotify.Text)
    }





    setAccessMode(msg: { accessMode: AccessMode }) {
        controller.setAccessMode(msg.accessMode)

    }

    clear() {
        logger.info('SocketCallbacks::clear');
        store.clear()
        controller.layerController?.updateRenderedFigures()
        // controller.layerController?.redraw()

    }
}