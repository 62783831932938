import grammer from "../grammers/chart"
import nearley from "nearley"
import { multiply, divide, add, subtract, sin, cos, pow, tan } from 'mathjs'
import { ChartShape, ShapeType, Transform } from "../model/shape"

type Arg = number | Op | string

interface Op {
    "op": string,
    "args": Arg[]
}


class Vars extends Map<string, number | number[]>{ }
export class WChart implements ChartShape {
    ID: Long.Long 
    OwnerID : Long.Long
    transform: Transform
    Color: string
    Type :  ShapeType.Chart = ShapeType.Chart
    Definition: string
    Height: number
    Width: number
    LineWidth: number
    Opacity: number
    //FIXME
    // Timestamp: Date
    // userId: number
    plotValue: number | number[] | undefined;
    plotArgs: number | number[] | undefined;


    constructor(chart: ChartShape) {
        this.ID = chart.ID
        this.OwnerID = chart.OwnerID
        this.transform = chart.transform
        this.Color = chart.Color
        this.Definition = chart.Definition
        this.Height = chart.Height
        this.Width = chart.Width
        this.LineWidth = chart.LineWidth
        this.Opacity = chart.Opacity
        //FIXME
        // this.timestamp = chart.timestamp
        // this.userId = chart.userId
        if (this.Definition !== "") this.calcDefinition()
    }

    chart(): ChartShape {
        if (this.ID !== undefined) {
            throw new Error("Chart with no Id")
        }
        return {
            Color: this.Color,
            Definition: this.Definition,
            Height: this.Height,
            LineWidth: this.LineWidth,
            Opacity: this.Opacity,
            //FIXME
            // Timestamp: this.timestamp,
            // userId: this.userId,
            transform: this.transform,
            Type: this.Type,
            Width: this.Width,
            ID: this.ID,
            OwnerID : this.OwnerID

        }

    }

    setDefinition(definition: string) {
        this.Definition = definition
        this.calcDefinition()
    }

    calcDefinition() {
        const parser = new nearley.Parser(nearley.Grammar.fromCompiled(grammer));
        try {
            parser.feed(this.Definition)
        }
        catch (error) {
            this.plotValue = undefined
            this.plotArgs = undefined
            return 
        }
        const result = parser.results as Op[][]
        //console.log(this.definition,    result)
        if (result.length > 0) {
            console.log("parser result", result)
            const vars = new Vars()
            vars.set('pi', Math.PI)
            vars.set('e', Math.E)
            for (let i = 0; i < result[0].length - 1; ++i) {
                const expr = result[0][i];
                if (expr.op === "def") {
                    const varName = (expr.args[0] as Op).args[0] as string
                    const varValue = this.calc(vars, expr.args[1] as Op)
                    vars.set(varName, varValue)
                }
            }

            const expr = result[0][result[0].length - 1]
            if (expr.op === 'plot' && expr.args.length > 0) {
                if (expr.args.length === 1) {
                    const value = this.calc(vars, expr.args[0] as Op)
                    this.plotArgs = undefined
                    this.plotValue = value

                } else {
                    this.plotArgs = this.calc(vars, expr.args[0] as Op)

                    this.plotValue = this.calc(vars, expr.args[1] as Op)

                }
            }
            else {
                this.plotValue = undefined
                this.plotArgs = undefined
            }

        } else {
            this.plotValue = undefined
        }


    }



    sin(val: number | number[]): number | number[] {
        if (typeof val === 'number')
            return sin(val)
        else
            return sin(val as number[]) as number[]
    }

    cos(val: number | number[]): number | number[] {
        if (typeof val === 'number')
            return cos(val)
        else
            return cos(val as number[]) as number[]
    }
    tan(val: number | number[]): number | number[] {
        if (typeof val === 'number')
            return tan(val)
        else
            return tan(val as number[]) as number[]
    }
    pow(val: number[], number: number) {
        let res: number[] = []
        for (let i = 0; i < val.length; ++i) {
            res.push(Math.pow(val[i], number))
        }

        return res
    }

    calc(vars: Vars, op: Op): number[] | number {
        switch (op.op) {
            case "m1": {
                const a = this.calc(vars, op.args[0] as Op);
                if (Array.isArray(a)) return a.map(n => -n)
                else return -a
            }
            case "var":
                return vars.get(op.args[0] as string) || NaN
            case "val":
                return op.args[0] as number
            case "range":
                return this.range(vars, op) as number[]
            case "add":
                const a = this.calc(vars, op.args[0] as Op) as number[]
                const b = this.calc(vars, op.args[1] as Op) as number[]
                return add(a, b) as number | number[]
            case "sub":
                return subtract(this.calc(vars, op.args[0] as Op), this.calc(vars, op.args[1] as Op)) as number | number[]
            case 'pow':
                return this.pow(this.calc(vars, op.args[0] as Op) as number[], this.calc(vars, op.args[1] as Op) as number) as number[]
            case "mul":
                {
                    const a = this.calc(vars, op.args[0] as Op) as number[]
                    const b = this.calc(vars, op.args[1] as Op) as number[]
                    if (a.length && a.length === b.length) {
                        const res: number[] = []
                        for (let i = 0; i < a.length; i++) {
                            res.push(a[i] * b[i])
                        }
                        return res
                    }
                    else
                        return multiply(a, b) as number | number[]
                }
            case "div":
                {
                    const a = this.calc(vars, op.args[0] as Op)
                    const b = this.calc(vars, op.args[1] as Op)
                    return divide(a, b) as number | number[]
                }
            case "sin":
                return this.sin(this.calc(vars, op.args[0] as Op)) as number | number[]
            case "cos":
                return this.cos(this.calc(vars, op.args[0] as Op)) as number | number[]
            case "tan":
                return this.tan(this.calc(vars, op.args[0] as Op)) as number | number[]
            default:
                return NaN

        }
    }
    range(vars: Vars, op: Op): number[] {
        if (op.args.length === 1) {
            const res = []
            for (let i = 0; i < op.args[0]; ++i) res.push(i)
            return res
        } else if (op.args.length === 3) {
            const a = this.calc(vars, op.args[0] as Op)
            const b = this.calc(vars, op.args[1] as Op)
            const n = op.args[2] as number
            if (Array.isArray(a) || (Array.isArray(b)) || !Number.isInteger(n)) return [NaN]
            const res = []
            for (let i = 0; i < n; ++i) {
                res.push(a + (i) / (n - 1) * (b - a))
            }
            return res


        } else return [NaN]
    }
}