
export enum ShapeType {
    Stroke = "Stroke",
    Text = "Text",
    Sticker = "Sticker",
    Figure = "Figure",
    TextArea = "TextArea",
    Chart = "Chart",
    Image = "Image",
    Highlight = "Highlight"
}


export interface Transform {
    sx: number
    sy: number
    tx: number
    ty: number
    r: number
  }
  

export interface Shape  {
    ID : Long.Long
    OwnerID : Long.Long
    Type: ShapeType
    transform : Transform
}

export interface StrokeShape extends Shape {
    Type : ShapeType.Stroke | ShapeType.Highlight
    PointArray : number[][]
    Color : string
    Width : number
    Opacity : number
    Smooth : boolean
    Closed : boolean
}

export interface TextShape extends Shape {
    Type : ShapeType.Text
    Content : string
    Color : string
    Family : string
    Size : number
}

export enum FigureShapeType {
    Rectangle = 0,
    Ellipse = 1
}

export interface FigureShape extends Shape {
    Type : ShapeType.Figure
    ShapeType: FigureShapeType;
    Color: string;
    Opacity: number;
    Width: number;
    Height: number;
    StrokeWidth: number;
    Fill: boolean;
}


export interface TextAreaShape extends Shape {
    Type : ShapeType.TextArea
    
    Content: string;
    Family: string;
    Size: number;
    Style: string;
    Color: string;
    Width: number;
    Height: number;
}


export interface ChartShape extends Shape {
    Type: ShapeType.Chart;
    Definition: string;
    LineWidth: number;
    Color: string;
    Opacity: number;
    Width: number;
    Height: number;
}


export enum StickerContentType {
    Markdown = 0,
    Code = 1,
    Video = 2
}


export interface StickerShape extends Shape {
    Type: ShapeType.Sticker;
    Width: number;
    Height: number;
    Content: string;
    FontSize: number;
    Color: string;
    StickerContentType: StickerContentType;
}


export interface ImageShape extends Shape {
    Type : ShapeType.Image
    Src : string
}
