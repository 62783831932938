import React, { useState } from "react"

import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { controller } from "../controller/controller"
import { Redirect } from "react-router-dom"
import { User } from "../store/store"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Container from "react-bootstrap/Container"

// import { User } from "../model/user"

const FormRow = styled.div`
    width: 600px;
    display:flex;
    margin : auto;
`

export const Login = () => {
    const { register, handleSubmit, errors } = useForm(); // initialise the hook
    const [redirect, setRedirect] = useState(false)
    const onSubmit = (data: any) => {
        const login = data as { email: string, password: string }
        controller.socketController.login(login, (user: User | undefined) => {
            console.log("Login result", user)
            // if (user?.PasswordHash) {
            //     localStorage['token'] = user.PasswordHash
            // }
            setRedirect(true)

        })

    };
    if (redirect) {
        return <Redirect to="/" from="/login" />
    } else
        return <Container>
            <Row>
                <Col>
                    <a href="/login/auth/google">Login with google</a>


                </Col>
            </Row>
            <Row>
                <Col>
                    <a href="/login/auth/yandex">Login with yandex</a>

                </Col>
            </Row>
            <Row>
                <FormRow>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h3>Login</h3>
                        <div>
                            <label>Email: <input ref={register} type="text" name="email" /></label>
                        </div>
                        <div>
                            <label>Password: <input ref={register} type="password" name="password" /></label>
                        </div>
                        <div>
                            <button>Submit</button>
                        </div>
                    </form>
                </FormRow>
            </Row></Container>

}