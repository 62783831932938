// Generated automatically by nearley, version 2.19.2
// http://github.com/Hardmath123/nearley
// Bypasses TS6133. Allow declared but unused functions.
// @ts-ignore
function id(d: any[]): any { return d[0]; }

  const op = (name : string , ...args: any[]) => {
    return {
      "op" : name,
      args
    }
  }

interface NearleyToken {  value: any;
  [key: string]: any;
};

interface NearleyLexer {
  reset: (chunk: string, info: any) => void;
  next: () => NearleyToken | undefined;
  save: () => any;
  formatError: (token: NearleyToken) => string;
  has: (tokenType: string) => boolean;
};

interface NearleyRule {
  name: string;
  symbols: NearleySymbol[];
  postprocess?: (d: any[], loc?: number, reject?: {}) => any;
};

type NearleySymbol = string | { literal: any } | { test: (token: any) => boolean };

interface Grammar {
  Lexer: NearleyLexer | undefined;
  ParserRules: NearleyRule[];
  ParserStart: string;
};

const grammar: Grammar = {
  Lexer: undefined,
  ParserRules: [
    {"name": "main", "symbols": ["plotexpr"], "postprocess": d=>[d[0]]},
    {"name": "main", "symbols": ["def", "sep", "plotexpr"], "postprocess": (d)=>[...d[0],d[2]]},
    {"name": "def", "symbols": ["definition"], "postprocess": (d)=>[d[0]]},
    {"name": "def", "symbols": ["def", "sep", "definition"], "postprocess": (d)=>[...d[0], d[2]]},
    {"name": "definition", "symbols": ["var", {"literal":"="}, "expr"], "postprocess": (d)=>op("def", d[0], d[2])},
    {"name": "plotexpr$string$1", "symbols": [{"literal":"p"}, {"literal":"l"}, {"literal":"o"}, {"literal":"t"}], "postprocess": (d) => d.join('')},
    {"name": "plotexpr", "symbols": ["plotexpr$string$1", "_", {"literal":"("}, "expr", {"literal":")"}], "postprocess": (d)=>op("plot", d[3])},
    {"name": "plotexpr$string$2", "symbols": [{"literal":"p"}, {"literal":"l"}, {"literal":"o"}, {"literal":"t"}], "postprocess": (d) => d.join('')},
    {"name": "plotexpr", "symbols": ["plotexpr$string$2", "_", {"literal":"("}, "expr", {"literal":","}, "expr", {"literal":")"}], "postprocess": (d)=>op("plot", d[3], d[5])},
    {"name": "expr", "symbols": ["_", "AS", "_"], "postprocess": function(d) {return d[1]; }},
    {"name": "P", "symbols": [{"literal":"("}, "_", "AS", "_", {"literal":")"}], "postprocess": function(d) {return d[2]; }},
    {"name": "P", "symbols": [{"literal":"-"}, "_", {"literal":"("}, "_", "AS", "_", {"literal":")"}], "postprocess": function(d) {return op("m1", d[4]); }},
    {"name": "P", "symbols": ["N"], "postprocess": id},
    {"name": "P", "symbols": [{"literal":"-"}, "_", "N"], "postprocess": function(d) {return op("m1", d[2]); }},
    {"name": "E", "symbols": ["P", "_", {"literal":"^"}, "_", "E"], "postprocess": function(d) {return op("pow", d[0], d[4]); }},
    {"name": "E", "symbols": ["P"], "postprocess": id},
    {"name": "MD", "symbols": ["MD", "_", {"literal":"*"}, "_", "E"], "postprocess": function(d) {return op("mul", d[0], d[4]); }},
    {"name": "MD", "symbols": ["MD", "_", {"literal":"/"}, "_", "E"], "postprocess": function(d) {return op("div", d[0], d[4]); }},
    {"name": "MD", "symbols": ["E"], "postprocess": id},
    {"name": "AS", "symbols": ["AS", "_", {"literal":"+"}, "_", "MD"], "postprocess": function(d) {return op("add", d[0], d[4]); }},
    {"name": "AS", "symbols": ["AS", "_", {"literal":"-"}, "_", "MD"], "postprocess": function(d) {return op("sub", d[0], d[4]); }},
    {"name": "AS", "symbols": ["MD"], "postprocess": id},
    {"name": "N", "symbols": ["part"], "postprocess": id},
    {"name": "N$string$1", "symbols": [{"literal":"s"}, {"literal":"i"}, {"literal":"n"}], "postprocess": (d) => d.join('')},
    {"name": "N", "symbols": ["N$string$1", "_", "P"], "postprocess": function(d) {return op("sin", d[2]); }},
    {"name": "N$string$2", "symbols": [{"literal":"c"}, {"literal":"o"}, {"literal":"s"}], "postprocess": (d) => d.join('')},
    {"name": "N", "symbols": ["N$string$2", "_", "P"], "postprocess": function(d) {return op("cos", d[2]); }},
    {"name": "N$string$3", "symbols": [{"literal":"t"}, {"literal":"a"}, {"literal":"n"}], "postprocess": (d) => d.join('')},
    {"name": "N", "symbols": ["N$string$3", "_", "P"], "postprocess": function(d) {return op("tan", d[2]); }},
    {"name": "N$string$4", "symbols": [{"literal":"a"}, {"literal":"s"}, {"literal":"i"}, {"literal":"n"}], "postprocess": (d) => d.join('')},
    {"name": "N", "symbols": ["N$string$4", "_", "P"], "postprocess": function(d) {return op("asin",d[2]); }},
    {"name": "N$string$5", "symbols": [{"literal":"a"}, {"literal":"c"}, {"literal":"o"}, {"literal":"s"}], "postprocess": (d) => d.join('')},
    {"name": "N", "symbols": ["N$string$5", "_", "P"], "postprocess": function(d) {return op("acos",d[2]); }},
    {"name": "N$string$6", "symbols": [{"literal":"a"}, {"literal":"t"}, {"literal":"a"}, {"literal":"n"}], "postprocess": (d) => d.join('')},
    {"name": "N", "symbols": ["N$string$6", "_", "P"], "postprocess": function(d) {return op("atan",d[2]); }},
    {"name": "N$string$7", "symbols": [{"literal":"s"}, {"literal":"q"}, {"literal":"r"}, {"literal":"t"}], "postprocess": (d) => d.join('')},
    {"name": "N", "symbols": ["N$string$7", "_", "P"], "postprocess": function(d) {return op("sqrt", d[2]); }},
    {"name": "N$string$8", "symbols": [{"literal":"l"}, {"literal":"n"}], "postprocess": (d) => d.join('')},
    {"name": "N", "symbols": ["N$string$8", "_", "P"], "postprocess": function(d) {return op("log", d[2]); }},
    {"name": "N$string$9", "symbols": [{"literal":"r"}, {"literal":"a"}, {"literal":"n"}, {"literal":"g"}, {"literal":"e"}], "postprocess": (d) => d.join('')},
    {"name": "N", "symbols": ["N$string$9", "_", {"literal":"("}, "_", "int", "_", {"literal":")"}], "postprocess": function(d) {return op("range", parseInt(d[4])); }},
    {"name": "N$string$10", "symbols": [{"literal":"r"}, {"literal":"a"}, {"literal":"n"}, {"literal":"g"}, {"literal":"e"}], "postprocess": (d) => d.join('')},
    {"name": "N", "symbols": ["N$string$10", "_", {"literal":"("}, "expr", {"literal":","}, "expr", {"literal":","}, "_", "int", "_", {"literal":")"}], "postprocess": function(d) {return op("range",d[3], d[5], parseInt(d[8])); }},
    {"name": "part", "symbols": ["float"], "postprocess": id},
    {"name": "part", "symbols": ["var"], "postprocess": id},
    {"name": "float", "symbols": ["int", {"literal":"."}, "int"], "postprocess": function(d) {return op("val", parseFloat(d[0] + d[1] + d[2]))}},
    {"name": "float", "symbols": ["int"], "postprocess": function(d) {return op("val", parseInt(d[0]))}},
    {"name": "int$ebnf$1", "symbols": [/[0-9]/]},
    {"name": "int$ebnf$1", "symbols": ["int$ebnf$1", /[0-9]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "int", "symbols": ["int$ebnf$1"], "postprocess": function(d) { return d[0].join(""); }},
    {"name": "_$ebnf$1", "symbols": []},
    {"name": "_$ebnf$1", "symbols": ["_$ebnf$1", /[\s]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "_", "symbols": ["_$ebnf$1"], "postprocess": function(d) {return null; }},
    {"name": "var$ebnf$1", "symbols": []},
    {"name": "var$ebnf$1", "symbols": ["var$ebnf$1", /[a-zA-Z0-9]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "var", "symbols": [/[a-zA-Z]/, "var$ebnf$1"], "postprocess": function(d) {return op("var", d[0] + d[1].join("")); }},
    {"name": "sep", "symbols": [/[:;\n]/]}
  ],
  ParserStart: "main",
};

export default grammar;
