import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "github-markdown-css";
import 'katex/dist/katex.min.css';
import { observer } from "mobx-react";
import React from "react";
import "react-mde/lib/styles/css/react-mde-all.css";
import styled from "styled-components";
import { controller } from "../../controller/controller";
import { store } from "../../store/store";
import { Tool, uiStore } from "../../store/UIState";
//import { MarkdownRender } from "./markdown/MarkdownRenderer";
import "./stickerComponent.css";
import { StickerMarkdown } from "./stickerMarkdown";
import { StickerVideo } from "./stickerVideo";
import { StickerCode } from "./stickerCode";
import { CodeSticker } from "../../model/CodeSticker";
import { StickerShape, StickerContentType } from "../../model/shape";

const SelectType: React.FC<{ sticker: StickerShape }> = observer((props) => {
    switch (props.sticker.StickerContentType) {
        case StickerContentType.Markdown:
            return <StickerMarkdown sticker={props.sticker} />
        case StickerContentType.Video:
            return <StickerVideo sticker={props.sticker} />
        case StickerContentType.Code:
            return <StickerCode sticker={props.sticker as CodeSticker} />

        default:
            return null
    }


})


export const ToolbarDiv = styled.div`
    background-color: lightgray;
    display: flex;
    justify-content: flex-end;
    height: 25px ;
    div {
        padding : 2px;
    };
`
const StickerPositionDiv = observer(styled.div<{ width: number, height: number, x: number, y: number }>`
position : absolute;
left : -1px;
top: -1px;
display : inline-block ;
width : ${props => props.width}px;
height: ${props => props.height}px;
transform : ${props => `translate(${props.x}px, ${props.y}px)`};
margin :0;
padding : 0;
outline:0;
background:white;
z-index:30;
line-height: normal;
border : solid;
border-color:gray;
border-width:1px;
transform-origin:0% 0%;
caret-color: gray;
-webkit-appearance: none;
border-radius: 0;
box-sizing:border-box;
`)

export const StickerComponent: React.FC<{ sticker: StickerShape }> = observer((props) => {
    const [x, y] = store.board.layerTransform.apply([props.sticker.transform.tx, props.sticker.transform.ty])
    const width = props.sticker.Width * store.board.layerTransform.k
    const height = props.sticker.Height * store.board.layerTransform.k

    return <StickerPositionDiv
        width={width}
        height={height}
        x={x}
        y={y}

    >




        <div
            onClick={() => {

                if (uiStore.ui.tool === Tool.Erase && store.userCanErase(props.sticker.OwnerID)) {
                    store.removeSticker(props.sticker.ID)
                    controller.socketController.removeShape(props.sticker.ID, "sticker")
                }

                controller.layerController?.setSelection(props.sticker)

            }}

            onTouchEnd={() => {
                if (uiStore.ui.tool === Tool.Erase && store.userCanErase(props.sticker.OwnerID)) {
                    store.removeSticker(props.sticker.ID || 0)
                    controller.socketController.removeShape(props.sticker.ID || 0, "sticker")
                }

            }}

            style={{
                pointerEvents: "all"
            }}>

            <div className="scaleDiv"
                style={{
                    width: props.sticker.Width,
                    height: props.sticker.Height,
                    background: props.sticker.Color,
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: `translate(-50%, -50%) scale(${store.board.layerTransform.k})`,
                    transformOrigin: "center center",
                    display: "flex",
                    flexDirection: "column"

                }} >

                <ToolbarDiv className="handle">
                    <div >
                        {uiStore.ui.tool !== Tool.Erase &&
                            <FontAwesomeIcon icon={faEdit}
                                onClick={(evt) => {
                                    store.boardUI.editedSticker = props.sticker; evt.stopPropagation()
                                }}
                                onTouchEnd={(evt) => { store.boardUI.editedSticker = props.sticker; evt.stopPropagation() }}
                            />}
                        {/* <FontAwesomeIcon icon={faArrowsAlt} /> */}
                    </div>
                </ToolbarDiv>

                {/* <div style={{ overflow: "auto", cursor: "default", flexGrow: 2, display : "flex", flexDirection:"column"  }}> */}

                <div style={{ flexGrow: 2, display:"flex", flexDirection:"column" }}>
                    <SelectType sticker={props.sticker} />
                </div>
                {/* </div> */}

            </div>
        </div>

    </StickerPositionDiv >
})

