import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import { controller } from './controller/controller';
import { BoardApp } from './components/BoardApp';
import { GreetApp } from './GreetApp';
import {Login} from './components/Login'

import ReactGA from "react-ga"
import { DynamicPage } from './components/dynamic/DynamicPage';
 
{/* <script async src="https://www.googletagmanager.com/gtag/js?id=UA-130955518-2"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag() { dataLayer.push(arguments); }
  gtag('js', new Date());

  gtag('config', 'UA-130955518-2');
</script> */}


function App() {
  ReactGA.initialize('UA-130955518-2')
  controller.init()

  
  return (
    

    <div className="position-absolute w-100 h-100">
      <Switch>
        <Route path="/" component={GreetApp} exact />

        {/* <Route path="/:componentPath" component={DynamicPage} exact  /> */}
        <Route path="/board/:boardName" component={BoardApp}  />
        <Route path="/login" component={Login}/>

      </Switch>

    </div>
  );
}

export default App;

