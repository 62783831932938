import { FigureShape, FigureShapeType, ImageShape, Shape, ShapeType, StickerContentType, StickerShape, StrokeShape, TextAreaShape, TextShape, Transform } from "../model/shape"
import { wjite } from "../proto/request"
import Long from "long"

export function message2modelTransform(transform: wjite.message.ITransform | null | undefined): Transform {
    return {
        r: transform?.r || 0,
        sx: transform?.sx || 1,
        sy: transform?.sy || 1,
        tx: transform?.tx || 0,
        ty: transform?.ty || 0,

    }
}

export function message2modelShape(shape : wjite.message.IShape) : Shape {
    if (shape.stroke) {

        const PointArray = (shape.stroke.points || []).map(pnt => [pnt.x as number, pnt.y as number])
        const s: StrokeShape = {
            Closed: shape.stroke.closed || false,
            Color: shape.stroke.color || "black",
            ID: shape.ID || Long.ZERO,
            Opacity: shape.stroke.opacity || 1,
            OwnerID: shape.ownerID || Long.ZERO,
            Smooth: shape.stroke.smooth || false,
            Type: ShapeType.Stroke,
            Width: shape.stroke.width || 1,
            transform: message2modelTransform(shape.transform),
            PointArray


        }
        return s

    }else if (shape.figure) {
        const s: FigureShape = {
            ID: shape.ID || Long.ZERO,
            OwnerID: shape.ownerID || Long.ZERO,
            transform: message2modelTransform(shape.transform),
            Type: ShapeType.Figure,
            Color: shape.figure.color || "black",
            Fill: shape.figure.fill || false,
            Width : shape.figure.width || 100,
            Height : shape.figure.height || 100,
            Opacity : shape.figure.opacity || 1,
            ShapeType : shape.figure.figureType as number as FigureShapeType || FigureShapeType.Rectangle,
            StrokeWidth : shape.figure.strokeWidth || 2


        }
        return s

    } else if (shape.text) {
        const s: TextShape = {
            ID: shape.ID || Long.ZERO,
            OwnerID: shape.ownerID || Long.ZERO,
            transform: message2modelTransform(shape.transform),
            Type: ShapeType.Text,
            Color: shape.text.color || "black",
            Content: shape.text.content || "",
            Family: shape.text.family || "",
            Size: shape.text.size || 12,


        }
        return s
    } else if (shape.textArea) {
        const s: TextAreaShape = {
            ID: shape.ID || Long.ZERO,
            OwnerID: shape.ownerID || Long.ZERO,
            transform: message2modelTransform(shape.transform),
            Type: ShapeType.TextArea,
            Color: shape.textArea.color || "black",
            Content: shape.textArea.content || "",
            Family: shape.textArea.family || "",
            Size: shape.textArea.size || 12,
            Height: shape.textArea.height || 100,
            Width: shape.textArea.width || 100,
            Style: shape.textArea.style || ""


        }
        return s
    } else if (shape.sticker) {
        let contentType : StickerContentType = StickerContentType.Markdown
        if (shape.sticker.contentType) {
            contentType = shape.sticker.contentType as number as  StickerContentType
        }
        const s: StickerShape = {
            ID: shape.ID || Long.ZERO,
            OwnerID: shape.ownerID || Long.ZERO,
            transform: message2modelTransform(shape.transform),
            Type: ShapeType.Sticker,
            Color : "lightyellow",
            Content : shape.sticker.content || "",
            FontSize : shape.sticker.fontSize || 12,
            Height : shape.sticker.height || 100,
            Width : shape.sticker.width || 100,
            StickerContentType : contentType
        }
        return s
        
    } else if (shape.image) {
        const s : ImageShape = {
            ID: shape.ID || Long.ZERO,
            OwnerID: shape.ownerID || Long.ZERO,
            transform: message2modelTransform(shape.transform),
            Type: ShapeType.Image,
            Src : shape.image.src || ""
        }
        return s
        
    } else throw new Error("Converting unknown shape")


}
