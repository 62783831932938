import Long from "long";
import protobuf from 'protobufjs/minimal';
import { socketConnection } from '../connection/socketConnection';
import { logger } from "../logging/logger";
import { AccessMode } from "../model/board";
import { ChartShape, FigureShape, ImageShape, Shape, ShapeType, StickerShape, StrokeShape, TextAreaShape, TextShape } from "../model/shape";
import { wjite } from '../proto/request';
import { message2modelShape } from "../socket/message2model";
import { SocketCallbacks } from "../socket/socketCallbacks";
import { store, User } from '../store/store';
import { Controller } from "./controller";
protobuf.util.Long = Long
protobuf.configure()

function getUserToken() {
    return localStorage['token']
}


export class SocketController {
    controller: Controller;
    tempShapes: Map<string, Shape>;
    updateIdCallbacks: Map<string, (id : Long.Long)=>void>;
    
    addShape(shape: Shape, arg1: (id: any) => any) {
        throw new Error("Method not implemented.");
    }
    addWChart(arg0: ChartShape, arg1: (id: any) => any) {
        throw new Error("Method not implemented.");
    }

    websocket?: WebSocket;
    nextTempShapeID: Long.Long = Long.ONE
    callbacks: SocketCallbacks;
    reconnectTimeout: number = 0;
    replyTimeout: number = 0;
    pingTimeout: number = 0

    REPLY_TIMEOUT = 5000
    PING_TIMEOUT = 5000


    constructor(controller: Controller) {
        this.callbacks = new SocketCallbacks()
        socketConnection.addEventListener("connect", this.onConnect)
        socketConnection.addMessageListener(this.onMessage)
        this.controller= controller
        this.tempShapes = new Map()
        this.updateIdCallbacks = new Map()
    }

    connect() {
        socketConnection.requestConnection()
    }

    onConnect = () => {
        if (store.board.name != "")
            this.requestBoardContent(store.board.name)

        // clearTimeout(this.replyTimeout); this.replyTimeout = 0;
        // logger.info({ message: "SocketController:...:onopen" })
        //establishing connection

        // socketConnection.send({
        //     connect: message.Connect.create({
        //         boardName: store.connectionState.BoardName,
        //         sessionID: localStorage['sessionID']
        //     })
        // })

        //this.websocket?.send(msg)
        //this.replyTimeout = setTimeout(this.noReplyTimeoutHandler, this.REPLY_TIMEOUT)   
    }

    onMessage = (message: wjite.message.IToClient) => {
        console.log("^^Receive message", message);
        // clearTimeout(this.replyTimeout); this.replyTimeout = 0;
        // clearTimeout(this.pingTimeout); this.pingTimeout = 0;
        // this.pingTimeout = setTimeout(this.ping, this.PING_TIMEOUT);
        if (message.sessionId) {
            localStorage.setItem('SessionID', message.sessionId)
        }
        if (message.boardContent) {
            const shapes : Shape[] = []

           const stickers : StickerShape[] = []
            for (let shape of message.boardContent.shapes || []) {
                const modelShape = message2modelShape(shape)
                if (modelShape) shapes.push(modelShape)
                if (modelShape?.Type === ShapeType.Sticker)
                    stickers.push(modelShape as StickerShape)
    
            }
            this.controller.setBoard(Long.ZERO, shapes, stickers)
    
        }

        if (message.updateShapeId) {
            this.updateShapeId(message.updateShapeId.tempId as Long.Long, message.updateShapeId.newId as Long.Long)
        }

        if (message.addShape) {
            this.callbacks.addShape(message.addShape)
        }

        if (message.addHighlight) {
            this.callbacks.addHighlight(message.addHighlight)
        }
        if (message.updateShape) {
            this.callbacks.updateShape(message.updateShape)
        }
        if (message.removeShape) {
            this.callbacks.removeShape(message.removeShape)
        }
        if (message.clear) {
            this.callbacks.clear()
        }
    }


    setAccessMode(accessMode: AccessMode) {
        // this.socket.emit("setAccessMode", { accessMode })
    }
    async addImage(image: ImageShape, imageFile: Blob, fn: (id: Long.Long, src: string) => void) {
        logger.info({ message: "SocketController::addIamge", data: imageFile.size })
        const array = new Uint8Array(await imageFile.arrayBuffer())
        const tempId = this.getTempShapeID(image)
        socketConnection.send({
            addShape: wjite.message.AddShape.create({
                shape: wjite.message.Shape.create({
                    transform: wjite.message.Transform.fromObject(image.transform),
                    image: wjite.message.Image.create({
                        blob: array
                    })
                }),
                tempId
                
            }),

        })


        // this.addRequest(new AddImageRequest(image, array, this.getCallbackId(), (id) => fn(id, "")))

    }

    updateShape(shape: Shape) {
        logger.info({ message: "SocketController::updateShape", data: shape.transform })
        if (shape.Type === ShapeType.Sticker)
            this.updateSticker(shape as StickerShape)
        else if (shape.ID > Long.ZERO) {
            //TODO check update shape
            socketConnection.send({
                updateShape: wjite.message.UpdateShape.create({

                    shape: {
                        ID: shape.ID,
                        transform: shape.transform
                    }
                })
            })
        }



    }

    clear() {
        logger.info("SocketController::clear")
        socketConnection.send({
            clear: {}
        })
        // this.addRequest(new ClearRequest(this.getCallbackId(),))
    }

    removeShape(shapeId: Long.Long, type: string) {
        logger.info({ message: "SocketController::removeShape", data: shapeId })

        socketConnection.send({
            removeShape: { shapeID: shapeId }
        })
        // this.addRequest(new RemoveShapeRequest(shapeId, this.getCallbackId(),))
    }

    // setBoardName(boardName?: string) {

    //     store.connectionState.BoardName = boardName || ""

    // }

    requestBoardContent(boardName : string) {
        logger.info("SocketController::getBoardContent")
        socketConnection.send({
            requestBoard : boardName, 
            sessionId :  localStorage['sessionID']
        })
        // this.addRequest(new GetBoardContentRequest(this.getCallbackId(),))
        // this.send()
    }


    addStroke(stroke: StrokeShape, updateId: (id: Long.Long) => void) {
        const tempId = this.getTempShapeID(stroke)

        socketConnection.send({
            addShape: wjite.message.AddShape.create({
                shape: wjite.message.Shape.create({
                    transform: wjite.message.Transform.fromObject(stroke.transform),
                    stroke: wjite.message.Stroke.create({
                        color: stroke.Color,
                        points: stroke.PointArray.map(p => { return { x: p[0], y: p[1] } }),
                        width: stroke.Width,
                        closed: stroke.Closed,
                        opacity: stroke.Opacity,
                        smooth: stroke.Smooth

                    })
                }),
                tempId
            }),
        })
        // this.requests.push(new AddStrokeRequest(stroke, this.getCallbackId(), updateId))
        //this.sendRequest(new AddStrokeRequest(stroke, updateId))
    }

    addFigure(figure: FigureShape, updateId: (id: Long.Long) => void) {
        // this.requests.push(new AddFigureRequest(figure, this.getCallbackId(), updateId))
        const tempId = this.getTempShapeID(figure, updateId)

        socketConnection.send({
            addShape: wjite.message.AddShape.create({
                shape: wjite.message.Shape.create({
                    transform: wjite.message.Transform.fromObject(figure.transform),
                    figure: wjite.message.Figure.create({
                        color: figure.Color,
                        width: figure.Width,
                        height: figure.Height,
                        figureType: figure.ShapeType as number as wjite.message.Figure.Type,
                        fill: figure.Fill,
                        opacity: figure.Opacity,
                        strokeWidth: figure.StrokeWidth


                    })
                }),
                tempId

            }),
        })

    }


    addText(text: TextShape, updateId: (id: Long.Long) => void) {
        logger.info("SocketController::addText")
        const tempId = this.getTempShapeID(text, updateId)

        const serverMessage = wjite.message.ToServer.create({
            addShape: wjite.message.AddShape.create({
                shape: wjite.message.Shape.create({
                    transform: wjite.message.Transform.fromObject(text.transform),
                    text: wjite.message.Text.create({
                        color: text.Color,
                        content: text.Content,
                        family: text.Family,
                        size: text.Size


                    })
                }),
                tempId
            }),
        })
        socketConnection.send(serverMessage)


        // this.requests.push(new AddTextRequest(text, this.getCallbackId(), updateId))
        // this.send()
    }

    updateText(text: TextShape) {
        if (text.ID === Long.NEG_ONE) return
        
        logger.info(`SocketController::updateText ${text.ID}`)
        const serverMessage = wjite.message.ToServer.create({
            updateShape: wjite.message.UpdateShape.create({
                shape: wjite.message.Shape.create({
                    ID: text.ID,
                    transform: wjite.message.Transform.fromObject(text.transform),
                    text: wjite.message.Text.create({
                        color: text.Color,
                        content: text.Content,
                        family: text.Family,
                        size: text.Size,


                    })
                })
            }),

        })
        socketConnection.send(serverMessage)

        // this.requests.push(new UpdateTextRequest(text, this.getCallbackId()))
        // this.send()

    }
    addTextArea(textArea: TextAreaShape, updateId: (id: Long.Long) => void) {
        logger.info("SocketController::addTextArea")
        const tempId = this.getTempShapeID(textArea, updateId)

        const serverMessage = wjite.message.ToServer.create({
            addShape: wjite.message.AddShape.create({
                shape: wjite.message.Shape.create({
                    transform: wjite.message.Transform.fromObject(textArea.transform),
                    textArea: wjite.message.TextArea.create({
                        color: textArea.Color,
                        content: textArea.Content,
                        family: textArea.Family,
                        size: textArea.Size,
                        height: textArea.Height,
                        width: textArea.Width,
                        style: textArea.Style


                    })
                }),
                tempId
            }),
            // callbackId: this.requestId
        })
        socketConnection.send(serverMessage)

        // this.requests.push(new AddTextAreaRequest(textArea, this.getCallbackId(), updateId))
        // this.send()
    }

    updateTextArea(textArea: TextAreaShape) {
        if (textArea.ID === Long.NEG_ONE) return

        logger.info(`SocketController::updateTextArea ${textArea.ID}`)
        const serverMessage = wjite.message.ToServer.create({
            updateShape: wjite.message.UpdateShape.create({
                shape: wjite.message.Shape.create({
                    ID: textArea.ID,

                    transform: wjite.message.Transform.fromObject(textArea.transform),
                    textArea: wjite.message.TextArea.create({
                        color: textArea.Color,
                        content: textArea.Content,
                        family: textArea.Family,
                        size: textArea.Size,
                        height: textArea.Height,
                        width: textArea.Width,
                        style: textArea.Style


                    })
                })
            }),
            
        })

        socketConnection.send(serverMessage)
        // this.send()

        // if (textArea.ID > 0)
        //     this.sendRequest(new UpdateTextAreaRequest(textArea))
    }


    getTempShapeID(shape : Shape, updateId? : (id : Long.Long)=>void): Long.Long {
        this.nextTempShapeID = this.nextTempShapeID.add(Long.ONE);
        this.tempShapes.set(this.nextTempShapeID.toString(),shape )
        if (updateId)
            this.updateIdCallbacks.set(this.nextTempShapeID.toString(), updateId)
        return this.nextTempShapeID
    }

    updateShapeId(tempId  : Long.Long, newId  : Long.Long) {
        console.log("Update shape ID", tempId, newId)
        const shape = this.tempShapes.get(tempId.toString())
        if (shape) {
            shape.ID = newId
            this.tempShapes.delete(tempId.toString())
        } else {
            throw new Error("Error updating shape id")
        }

        const updateId = this.updateIdCallbacks.get(tempId.toString())
        if (updateId)  {
            updateId(newId)
            this.updateIdCallbacks.delete(tempId.toString())
        }
        
    }


    // addShape(shape: Shape, updateId: (id: number) => void) {
    //     logger.info(`SocketController::addShape ${shape.ID}, ${shape.Type}`)
    //     this.addRequest(new AddShapeRequest(shape,this.getCallbackId(), updateId))
    // }

    addHighlight(stroke: StrokeShape, focus : boolean) {
        socketConnection.send({
            addHighlight: wjite.message.AddHighlight.create({
                shape: wjite.message.Shape.create({
                    transform: wjite.message.Transform.fromObject(stroke.transform),
                    stroke: wjite.message.Stroke.create({
                        color: stroke.Color,
                        points: stroke.PointArray.map(p => { return { x: p[0], y: p[1] } }),
                        width: stroke.Width,
                        closed: stroke.Closed,
                        opacity: stroke.Opacity,
                        smooth: stroke.Smooth

                    })
                }),
                focus 
            })
        })
             
     
    }

    addSticker(sticker: StickerShape, updateId: (id: Long.Long) => void) {
        logger.info(`SocketController::addSticker ${sticker.ID}`)
        const tempId = this.getTempShapeID(sticker, updateId)

        const serverMessage = wjite.message.ToServer.create({
            addShape: wjite.message.AddShape.create({
                shape: wjite.message.Shape.create({
                    transform: wjite.message.Transform.fromObject(sticker.transform),
                    sticker: wjite.message.Sticker.create({
                        color: sticker.Color,
                        content: sticker.Content,
                        height: sticker.Height,
                        width: sticker.Width,
                        contentType: sticker.StickerContentType as number as wjite.message.Sticker.Type,
                        fontSize: sticker.FontSize


                    })
                }),
                tempId

            }),
            // tempId : t/his.getTempShapeID()
        })
        socketConnection.send(serverMessage)

        // this.requests.push(new AddStickerRequest(sticker, this.getCallbackId(), updateId))
        // this.send()

    }
    updateSticker(sticker: StickerShape) {
        if (sticker.ID === Long.NEG_ONE) return

        logger.info(`SocketController::updateStikcer ${sticker.ID}`)
        const serverMessage = wjite.message.ToServer.create({
            updateShape: wjite.message.UpdateShape.create({
                shape: wjite.message.Shape.create({
                    ID: sticker.ID,
                    transform: wjite.message.Transform.fromObject(sticker.transform),
                    sticker: wjite.message.Sticker.create({
                        color: sticker.Color,
                        content: sticker.Content,
                        height: sticker.Height,
                        width: sticker.Width,


                    })
                })
            }),
            // callbackId: this.requestId
        })
        socketConnection.send(serverMessage)


    }




    login(login: { email: string; password: string; }, callback: (user: User | undefined) => void) {
        // this.socket.emit('login', login, callback)
    }

   
    

}