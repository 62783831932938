import React, { useRef, useEffect, useState } from "react"
import { observer } from "mobx-react";
import styled from "styled-components";
import { undoredo } from "../store/undoredo";
import { controller } from "../controller/controller";

import Select from 'react-select'
import { Trans } from "@lingui/macro";
import { Shape } from "../model/shape";

interface TextAreaProps {
    width: number
    height: number
    transform: string
    fontSize: number
}


const TextAreaDiv = observer(styled.div.attrs<TextAreaProps>(({width, height, transform, fontSize })=> ({
    width : `${width + 5}px`,
    height:`${height + 5}px`,
    transform,
    fontSize : fontSize
}))<TextAreaProps>`
position : absolute;
left : -1px;
top: -1px;
display : inline-block ;
/* -webkit-transform: ${props => props.transform}; */
transform: ${props => props.transform};
/* transform: translate(100px,100px); */
margin :0;
padding : 0;
/* overflow : hidden; */
outline:0;
background:white;
z-index:30;
line-height: normal;
font-size : ${props => props.fontSize}px;
border : solid;
border-color:gray;
border-width:1px;
transform-origin:0% 0%;
caret-color: gray;
-webkit-appearance: none;
border-radius: 0;
box-sizing:border-box;
`)


export const TextAreaEdit: React.FC<{
    value: string,
    onChange: (value: string) => void,
    width: number,
    height: number,
    transform: string,
    fontSize: number,
    shape: Shape
}> = observer(((props) => {
    const ref = useRef<HTMLTextAreaElement>(null);
    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
            ref.current.select()
            undoredo.beginUpdateShape(props.shape)
        }
        return () => {
            undoredo.endUpdateShape(props.shape)
        }

    }, [ref, props.shape])

    let height = props.height



    const [value, setValue] = useState(props.value)

    return <TextAreaDiv
        fontSize={props.fontSize} width={props.width} height={height} transform={props.transform}
    >
        <textarea ref={ref}
            id="input-area"
            autoCapitalize={"off"}
            spellCheck={false}



            value={value}
            onChange={(evt) => {
                setValue(evt.target.value)
                props.onChange(evt.target.value)

            }}

            style={{ width: props.width, height: height }}

        />
    </TextAreaDiv>
}))
