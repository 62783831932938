import { faAlignLeft, faArrowsAlt, faChartArea, faCircle, faCog, faEraser, faFont, faHighlighter, faImage, faLaptopCode, faMousePointer, faPen, faPencilAlt, faPlayCircle, faRedo, faShareSquare, faSquare, faStickyNote, faTrash, faUndo, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "@lingui/macro";
import * as d3 from "d3";
import React from "react";
import { ReactComponent as L3 } from "../ikons/l3.svg";
import { ReactComponent as Segment } from "../ikons/segment.svg";
import { Tool, uiStore } from "../store/UIState";
import { undoredo } from "../store/undoredo";
import { ToolConfig } from "./ToolbarConfig";




export enum ButtonId {
    SelectTool = 0,
    TranslateTool,
    PenTool,
    MarkerTool,
    MultilineTool,
    SegmentTool,
    EllipseTool,
    RectagleTool,
    EraseTool,
    ImageTool,
    TextTool,
    TextStickerTool,
    OptsTool,
    UndoTool,
    RedoTool,
    ClearTool,
    PlayTool,
    ShareTool,
    VideoStickerTool,
    TextAreaTool,
    GraphTool,
    HighlightTool,
    CodeStickerTool
}


export const tools = new Map<ButtonId, ToolConfig>()
tools.set(ButtonId.SelectTool, {
    iconElement: (<FontAwesomeIcon icon={faMousePointer} size="2x" />),
    onClick: () => { },

    textElement: <div><Trans>Select</Trans></div>,
    tool: Tool.Select
})



tools.set(ButtonId.TranslateTool, {
    iconElement: (<FontAwesomeIcon icon={faArrowsAlt} size="2x" />),
    onClick: () => { },

    textElement: <div><Trans>Move</Trans></div>,
    tool: Tool.Translate
})


tools.set(ButtonId.PenTool, {
    iconElement: (<FontAwesomeIcon icon={faPen} size="2x" />),
    onClick: () => { },

    textElement: <div><Trans>Pen</Trans></div>,
    tool: Tool.Pen

})

tools.set(ButtonId.HighlightTool, {
    iconElement: (<FontAwesomeIcon icon={faHighlighter} size="2x" />),
    onClick: () => { },

    textElement: <div><Trans>Highlight</Trans></div>,
    tool: Tool.Highlight

})

tools.set(ButtonId.MarkerTool, {
    iconElement: (<FontAwesomeIcon icon={faPencilAlt} size="2x" />),
    onClick: () => { },

    textElement: <div><Trans>Marker</Trans></div>,
    tool: Tool.Marker
})
tools.set(ButtonId.MultilineTool, {
    iconElement: (<L3 style={{ width: "25px" }} />),
    onClick: () => { },

    textElement: <div><Trans>Line</Trans></div>,
    tool: Tool.MultiLine
})

tools.set(ButtonId.SegmentTool, {
    iconElement: (<Segment style={{ width: "25px" }} />),
    onClick: () => { },

    textElement: <div><Trans>Segment</Trans></div>,
    tool: Tool.Segment
})


tools.set(ButtonId.EraseTool, {
    iconElement: (<FontAwesomeIcon icon={faEraser} size="2x" />),
    onClick: () => { },

    textElement: <div><Trans>Erase</Trans></div>,
    tool: Tool.Erase

})

tools.set(ButtonId.ImageTool, {
    iconElement: (<FontAwesomeIcon icon={faImage} size="2x" />),
    onClick: () => {
        let elem = d3.select("#imageUploadPlaceholder").node() as HTMLInputElement;
        if (elem) elem.click()
        
        uiStore.ui.tool = Tool.Pen
    },

    textElement: <div><Trans>Image</Trans></div>,
    //    tool: Tool.Image

})


tools.set(ButtonId.TextAreaTool, {
    iconElement: (<FontAwesomeIcon icon={faAlignLeft} size="2x" />),
    onClick: () => { },

    textElement: <div><Trans>Textarea</Trans></div>,
    tool: Tool.TextArea
})

tools.set(ButtonId.TextTool, {
    iconElement: (<FontAwesomeIcon icon={faFont} size="2x" />),
    onClick: () => { },

    textElement: <div><Trans>Text</Trans></div>,
    tool: Tool.Text
})


tools.set(ButtonId.TextStickerTool, {
    iconElement: (<FontAwesomeIcon icon={faStickyNote} size="2x" />),
    onClick: () => { },

    textElement: <div><Trans>Sticker</Trans></div>,
    tool: Tool.Sticker
})

tools.set(ButtonId.VideoStickerTool, {
    iconElement: (<FontAwesomeIcon icon={faVideo} size="2x" />),
    onClick: () => { },

    textElement: <div><Trans>Video</Trans></div>,
    tool: Tool.StickerVideo
})


tools.set(ButtonId.CodeStickerTool, {
    iconElement: (<FontAwesomeIcon icon={faLaptopCode} size="2x" />),
    onClick: () => { },

    textElement: <div><Trans>Code</Trans></div>,
    tool: Tool.StickerCode
})


tools.set(ButtonId.OptsTool, {
    iconElement: (<FontAwesomeIcon icon={faCog} size="2x" />),
    onClick: () => { },

    textElement: <div><Trans>Opts</Trans></div>,

})

tools.set(ButtonId.UndoTool, {
    iconElement: (<FontAwesomeIcon icon={faUndo} size="2x" />),
    onClick: () => { undoredo.undo() },

    textElement: <div><Trans>Undo</Trans></div>,

})
tools.set(ButtonId.RedoTool, {
    iconElement: (<FontAwesomeIcon icon={faRedo} size="2x" />),
    onClick: () => { undoredo.redo() },

    textElement: <div><Trans>Redo</Trans></div>,

})




tools.set(ButtonId.ClearTool, {

    iconElement: (<FontAwesomeIcon icon={faTrash} size="2x" />),
    onClick: () => { uiStore.ui.showClearDialog = true },

    textElement: <div>
        <Trans>Clear</Trans>
    </div>,
})



tools.set(ButtonId.PlayTool, {

    iconElement: (<FontAwesomeIcon icon={faPlayCircle} size="2x" />),
    onClick: () => { /*controller.playBoard()*/ },

    textElement: <div>
        <Trans>Play</Trans>
    </div>,
    tool: Tool.Play


})





tools.set(ButtonId.ShareTool, {

    iconElement: (<FontAwesomeIcon icon={faShareSquare} size="2x" />),
    onClick: () => { uiStore.ui.showShareDlg = true },
    textElement: <Trans>Share</Trans>,


})

tools.set(ButtonId.EllipseTool, {
    iconElement: (<FontAwesomeIcon icon={faCircle} size="2x" />),
    onClick: () => { },

    textElement: <Trans>Circle</Trans>,
    tool: Tool.Ellipse
})


tools.set(ButtonId.RectagleTool, {
    iconElement: (<FontAwesomeIcon icon={faSquare} size="2x" />),
    onClick: () => { },

    textElement: <Trans>Rectangle</Trans>,
    tool: Tool.Rectangle
})


tools.set(ButtonId.GraphTool, {
    iconElement: (<FontAwesomeIcon icon={faChartArea} size="2x" />),
    onClick: () => { },

    textElement: <Trans>Chart</Trans>,
    tool: Tool.Chart
})




