import { Trans } from "@lingui/macro";
import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import { controller } from "../controller/controller";
import { store } from "../store/store";
import { fonts, Tool, uiStore } from "../store/UIState";
import { ColorPalette } from "./ColorPalette";
import { TextShape, ShapeType } from "../model/shape";
import Form from "react-bootstrap/Form"

import Col from "react-bootstrap/Col"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"

import { I18n } from "@lingui/react"
import { t } from "@lingui/macro"

const SliderC = styled.div`
    width : 100px;
    margin : 7px;
    padding-left:5px;
    display : flex;
`



const ClosedCheckBox = observer(() => {
    return <label><Trans>Fill</Trans> <input type="checkbox" checked={store.defaultStrokeParam.closedShape} onChange={() => { store.defaultStrokeParam.closedShape = !store.defaultStrokeParam.closedShape }} /></label>
})


const FocusCheckBox = observer(() => {
    return <label><Trans>Focus others</Trans> <input type="checkbox" checked={store.highlightFocusOthers} onChange={() => { store.highlightFocusOthers = !store.highlightFocusOthers }} /></label>
})



const SnapCheckBox = observer(() => {
    return <label><Trans>Snap</Trans> <input type="checkbox" checked={store.defaultStrokeParam.snap} onChange={() => { store.defaultStrokeParam.snap = !store.defaultStrokeParam.snap }} /></label>
})


const FontSelect: React.FC<{ value: string, onChange: (value: string) => void }> = observer((props) => {
    return <select value={props.value}
        onChange={evt => { props.onChange(evt.target.value) }}>
        {fonts.map(font =>
            <option key={font} value={font}>{font}</option>
        )}
    </select>
})


const ColorSelector: React.FC<{ value: string, onChange: (color: string) => void }> = observer((props) => {

    return <ColorPalette color={props.value} onColorChange={props.onChange} />

})


const ParamsC = styled.div`
   display : flex;
   flex-wrap : wrap;

`
const CommentC = styled.div`
    display : flex;
    padding-left:10px;
    p {
        background-color:white;
        padding:0px;
        margin:0px;
        line-height:30px;
    }

    label {
        line-height:30px;

    }
`
const TCloseBox = observer(() => {
    const closedShape = store.defaultStrokeParam.closedShape
    return <I18n>
        {({ i18n }) => (
            <Form.Check
                checked={closedShape}
                onClick={() => store.defaultStrokeParam.closedShape = !store.defaultStrokeParam.closedShape}
                type={'checkbox'}
                label={i18n._(t`Closed`)}
            />
        )}
    </I18n>
})

const TFocusBox = observer(() => {
    const highlight = store.highlightFocusOthers
    return <I18n>
        {({ i18n }) => (
            <Form.Check
                checked={highlight}
                onClick={() => store.highlightFocusOthers = !store.highlightFocusOthers}
                type={'checkbox'}
                label={i18n._(t`Focus others`)}
            />
        )}
    </I18n>
})

const TSnapBox = observer(() => {
    const snap = store.defaultStrokeParam.snap
    return <I18n>
        {({ i18n }) => (
            <Form.Check
                checked={snap}
                onClick={() => store.defaultStrokeParam.snap = !store.defaultStrokeParam.snap}
                type={'checkbox'}
                label={i18n._(t`Focus others`)}
            />
        )}
    </I18n>
})

export const PenToolParam = observer(() => {

    return <Navbar bg="light" expand={true}>
        <Nav className="mr-auto justify-content-center">
            <Nav.Item><ColorSelector value={store.defaultStrokeParam.color} onChange={(color) => store.defaultStrokeParam.color = color} /></Nav.Item>
            <Form inline>
                <Form.Row className="align-items-center">
                    <Col><Form.Label><Trans>Width</Trans></Form.Label></Col>
                    <Col><Form.Control type="range" min={1} max={10} value={store.defaultStrokeParam.strokeWidth} onChange={(evt) => store.defaultStrokeParam.strokeWidth = parseInt(evt.target.value)} /></Col>

                    <Col><Form.Label ><Trans>Smooth</Trans></Form.Label></Col>
                    <Col><Form.Control type="range" min={0} max={5} value={store.defaultStrokeParam.smoothFactor} onChange={(evt) => store.defaultStrokeParam.smoothFactor = parseInt(evt.target.value)} /></Col>

                    {uiStore.ui.tool === Tool.Marker && <>
                        <Col><Trans>Opacity</Trans></Col>
                        <Col><Form.Control type="range" min={1} max={100} value={store.defaultStrokeParam.opacity * 100} onChange={(evt) => store.defaultStrokeParam.opacity = parseInt(evt.target.value) / 100} /></Col>
                    </>}

                    <Col><TCloseBox /></Col>

                </Form.Row>
            </Form>
        </Nav>
    </Navbar>

    // return <ParamsC>
    //     
    //     <CommentC><ClosedCheckBox /></CommentC>
    //     <CommentC><p><Trans>Width</Trans></p><SliderC><input type="range" value={store.defaultStrokeParam.strokeWidth} min={1} max={10} onChange={(evt) => store.defaultStrokeParam.strokeWidth = parseInt(evt.target.value) } /></SliderC></CommentC>
    //     <CommentC><p><Trans>Smooth</Trans></p><SliderC><input type="range" value={store.defaultStrokeParam.smoothFactor} min={0} max={5} onChange={(evt) => store.defaultStrokeParam.smoothFactor = parseInt(evt.target.value)} /></SliderC></CommentC>
    //     
    // </ParamsC>
})

const HighlightToolParam = observer(() => {
    return <Navbar bg="light" expand={true}>
        <Nav className="mr-auto justify-content-center">
            <Nav.Item><ColorSelector value={store.defaultStrokeParam.color} onChange={(color) => store.defaultStrokeParam.color = color} /></Nav.Item>
            <Form inline>
                <Form.Row className="align-items-center">
                    <Col><Form.Label><Trans>Width</Trans></Form.Label></Col>
                    <Col><Form.Control type="range" min={1} max={10} value={store.defaultStrokeParam.strokeWidth} onChange={(evt) => store.defaultStrokeParam.strokeWidth = parseInt(evt.target.value)} /></Col>


                    <Col><TFocusBox /></Col>

                </Form.Row>
            </Form>

        </Nav>
    </Navbar>
})

const FigureToolParam = observer(() => {
    return <Navbar bg="light" expand={true}>
        <Nav className="mr-auto justify-content-center">
            <Nav.Item><ColorSelector value={store.defaultStrokeParam.color} onChange={(color) => store.defaultStrokeParam.color = color} /></Nav.Item>
            <Form inline>
                <Form.Row className="align-items-center">
                    <Col><Form.Label><Trans>Width</Trans></Form.Label></Col>
                    <Col><Form.Control type="range" min={1} max={10} value={store.defaultStrokeParam.strokeWidth} onChange={(evt) => store.defaultStrokeParam.strokeWidth = parseInt(evt.target.value)} /></Col>
                </Form.Row>
            </Form>

        </Nav>
    </Navbar>

    // return <ParamsC>
    //         <ColorSelector value={store.defaultStrokeParam.color} onChange={(color) => store.defaultStrokeParam.color = color} />
    //         {/* <CommentC><p><Trans>Width</Trans></p><SliderC><Slider value={store.defaultStrokeParam.strokeWidth} min={1} max={10} onChange={(value) => store.defaultStrokeParam.strokeWidth = value} /></SliderC></CommentC> */}
    //     </ParamsC>
})

const SegmentParam = observer(() => {
    return <Navbar bg="light" expand={true}>
        <Nav className="mr-auto justify-content-center">
            <Nav.Item><ColorSelector value={store.defaultStrokeParam.color} onChange={(color) => store.defaultStrokeParam.color = color} /></Nav.Item>
            <Form inline>
                <Form.Row className="align-items-center">
                    <Col><Form.Label><Trans>Width</Trans></Form.Label></Col>
                    <Col><Form.Control type="range" min={1} max={10} value={store.defaultStrokeParam.strokeWidth} onChange={(evt) => store.defaultStrokeParam.strokeWidth = parseInt(evt.target.value)} /></Col>

                    <Col><TSnapBox /></Col>

                </Form.Row>
            </Form>

        </Nav>
    </Navbar>

    // return <ParamsC>
    //         <ColorSelector value={store.defaultStrokeParam.color} onChange={(color) => store.defaultStrokeParam.color = color} />
    //         {/* <CommentC><p><Trans>Width</Trans></p><SliderC><Slider value={store.defaultStrokeParam.strokeWidth} min={1} max={10} onChange={(value) => store.defaultStrokeParam.strokeWidth = value} /></SliderC></CommentC> */}
    //         <CommentC><TSnapBox /></CommentC>
    //     </ParamsC>
})

const MultilineParam = observer(() => {
    return <Navbar bg="light" expand={true}>
        <Nav className="mr-auto justify-content-center">
            <Nav.Item><ColorSelector value={store.defaultStrokeParam.color} onChange={(color) => store.defaultStrokeParam.color = color} /></Nav.Item>
            <Form inline>
                <Form.Row className="align-items-center">
                    <Col><Form.Label><Trans>Width</Trans></Form.Label></Col>
                    <Col><Form.Control type="range" min={1} max={10} value={store.defaultStrokeParam.strokeWidth} onChange={(evt) => store.defaultStrokeParam.strokeWidth = parseInt(evt.target.value)} /></Col>
                    <Col><Form.Label><Trans>Opacity</Trans></Form.Label></Col>
                    <Col><Form.Control type="range" min={1} max={100} value={store.defaultStrokeParam.opacity * 100} onChange={(evt) => store.defaultStrokeParam.opacity = parseInt(evt.target.value) / 100} /></Col>
                    <Col><TSnapBox /></Col>
                </Form.Row>
            </Form>

        </Nav>
    </Navbar>

})



const TextParam = observer(() => {
    return <Navbar bg="light" expand={true}>
        <Nav className="mr-auto justify-content-center">
            <Nav.Item><ColorSelector value={store.defaultStrokeParam.color} onChange={(color) => store.defaultStrokeParam.color = color} /></Nav.Item>
            <Nav.Item><FontSelect value={store.defaultStrokeParam.font} onChange={(value) => store.defaultStrokeParam.font = value} /></Nav.Item>

        </Nav>
    </Navbar>

})

const EditTextParam = observer(() => {
    if (store.boardUI.editedShape) {
        const text = store.boardUI.editedShape as TextShape
        return <Navbar bg="light" expand={true}>
            <Nav className="mr-auto justify-content-center">
                <Nav.Item><ColorSelector value={text.Color} onChange={(color) => {
                    text.Color = color;

                    controller.layerController?.redraw()
                    controller.socketController?.updateShape(text)

                }} /></Nav.Item>
                <Nav.Item><FontSelect value={text.Family} onChange={
                    (value) => {
                        if (text) {

                            (text.Family) = value;
                            controller.layerController?.redraw()

                            controller.socketController?.updateShape(text)

                        }

                    }
                } /></Nav.Item>
                {/* {uiStore.edit && <LineEdit edit={uiStore.edit} />} */}
            </Nav>
        </Navbar>
       
    } else return null;
})

export const Toolparam: React.FC = observer(() => {
    switch (uiStore.ui.tool) {
        case Tool.Pen:
        case Tool.Marker:
            return <PenToolParam />
        case Tool.Highlight:
            return <HighlightToolParam />

        case Tool.Ellipse:
        case Tool.Rectangle:

            return <FigureToolParam />

        case Tool.Segment:
            return <SegmentParam />
        case Tool.MultiLine:
            return <MultilineParam />
        case Tool.Text:
            if (store.boardUI.editedShape && [ShapeType.Text, ShapeType.TextArea].includes(store.boardUI.editedShape.Type)) {
                return <EditTextParam />
            } else {
                return <TextParam />
            }
        case Tool.Select:
            if (store.boardUI.editedShape && [ShapeType.Text, ShapeType.TextArea].includes(store.boardUI.editedShape.Type)) {
                return <EditTextParam />
            }
            else {
                return null;
            }

        case Tool.TextArea:
            if (store.boardUI.editedShape && [ShapeType.TextArea].includes(store.boardUI.editedShape.Type)) {
                return <EditTextParam />
            }
            else {
                return null;
            }

        default:
            return null
    }

})

