import { controller } from "../controller/controller";
import { undoredo } from "../store/undoredo";
import { TransformerMode, TransformerTool } from "../tools/TransformerTool";
import { LayerBase } from "./LayerBase";
import { Shape, ShapeType, TextAreaShape, ChartShape, StickerShape } from "../model/shape";

export class TransformerLayer extends LayerBase {
    transformerTool?: TransformerTool
    transformedShape?: Shape;
    dragHandleIdx: number = -1;
    isDrag: boolean = false;



    showTransformer(shape: Shape) {
        this.transformedShape = shape

        let rect = this.getRect(shape)
        if (shape.Type === ShapeType.Text)
            rect = rect.extend(0, 0, 5, 5)
        let mode: TransformerMode
        if ([ShapeType.Text, ShapeType.Image].includes(shape.Type))
            mode = TransformerMode.Transform
        else if (shape.Type === ShapeType.Sticker) {
            mode = TransformerMode.Sticker
        } else 
            mode = TransformerMode.Rect
        this.transformerTool = new TransformerTool(rect, shape.transform, mode)

    }

    hideTransformer() {
        this.transformedShape = undefined
        this.transformerTool = undefined
    }

    updateTransformerShape(shape: Shape) {
        this.transformedShape = shape
        this.updateTransformerRect()
    }


    updateTransformerRect() {
        if (this.transformedShape && this.transformerTool) {
            this.transformerTool.rect = this.getRect(this.transformedShape)
            this.transformerTool.transform = this.transformedShape.transform
        }
    }

    render(shapes: Shape[]) {

        super.render(shapes)


        if (this.transformerTool) {
            this.context.save()
            this.context.translate(this.transform.x, this.transform.y);
            this.context.scale(this.transform.k, this.transform.k);

            this.transformerTool.render(this.context)
            this.context.restore()
        }

    }

    dragRender() {
        if (this.isDrag || this.dragHandleIdx > -1) return true
        else return false
    }

    dragStartTransformer(x: number, y: number) {
        // const ppos = this.transform.invert([x, y])
        const ppos = [x, y]
        if (this.transformerTool && this.transformedShape) {
            this.dragHandleIdx = this.transformerTool.getHandleIdx({ x: ppos[0], y: ppos[1] })
            if (this.dragHandleIdx > -1) {
                undoredo.beginUpdateShape(this.transformedShape)
                return true
            }

            this.isDrag = this.transformerTool.isDrag({ x: ppos[0], y: ppos[1] })
            if (this.isDrag) {
                undoredo.beginUpdateShape(this.transformedShape)

                return true
            }
        }
        return false
    }

    dragTransformer(x: number, y: number) {
        const ppos = [x, y]
        if (this.transformerTool && this.transformedShape) {
            if (this.dragHandleIdx > -1) {
                //this.transformedShape.rect = this.transformerTool.transform

                this.transformerTool.setDragHandlePosition(this.dragHandleIdx, { x: ppos[0], y: ppos[1] })
                this.transformedShape.transform = this.transformerTool.transform
                if (this.transformerTool.mode === TransformerMode.Rect || this.transformerTool.mode === TransformerMode.Sticker) {
                    if (this.transformedShape.Type === ShapeType.TextArea) {
                        const ta = this.transformedShape as TextAreaShape
                        ta.Width = this.transformerTool.rect.width
                        ta.Height = this.transformerTool.rect.height
                    } 
                    if (this.transformedShape.Type === ShapeType.Chart) {
                        const chart = this.transformedShape as ChartShape
                        chart.Width = this.transformerTool.rect.width
                        chart.Height = this.transformerTool.rect.height
                    }

                    if (this.transformedShape.Type === ShapeType.Sticker) {
                        const sticker = this.transformedShape as StickerShape
                        sticker.Width = this.transformerTool.rect.width
                        sticker.Height = this.transformerTool.rect.height
                    }

                }
                if ([ShapeType.Text, ShapeType.TextArea, ShapeType.Chart].includes(this.transformedShape.Type)) controller.board?.updateInputPosition()

                return true
            }

            if (this.isDrag) {
                this.transformerTool.setDragPosition({ x: ppos[0], y: ppos[1] })
                this.transformedShape.transform = this.transformerTool.transform
                if ([ShapeType.Text, ShapeType.TextArea, ShapeType.Chart].includes(this.transformedShape.Type)) controller.board?.updateInputPosition()


                return true
            }
        }
        return false

    }

    dragEndTransformer(x: number, y: number) {
        // const ppos = this.transform.invert([x, y])
        const ppos = [x, y]

        if (this.transformerTool && this.transformedShape) {
            if (this.dragHandleIdx > -1) {
                this.transformerTool.setDragHandlePosition(this.dragHandleIdx, { x: ppos[0], y: ppos[1] })
                controller.socketController?.updateShape(this.transformedShape)
                undoredo.endUpdateShape(this.transformedShape)
                return true
            }

            if (this.isDrag) {
                this.transformerTool.setDragPosition({ x: ppos[0], y: ppos[1] })
                controller.socketController?.updateShape(this.transformedShape)
                undoredo.endUpdateShape(this.transformedShape)
                return true
            }
        }
        return false


    }
}
