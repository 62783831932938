import { Trans } from "@lingui/macro";
import { observer } from "mobx-react";
import React, { useState } from "react";
import YouTube from "react-youtube";
import { controller } from "../../controller/controller";
import { store } from "../../store/store";
import { StickerShape } from "../../model/shape";



const VideoStickerEditor: React.FC<{ sticker: StickerShape }> = (props) => {

    const [value, changeValue] = useState(props.sticker.Content)
    return <div style={{ display: 'flex', flexDirection: "column", alignItems: "center" }}>
        <p><Trans>Youtube page url</Trans> </p>

        <input style={{
            width: props.sticker.Width - 30
        }}
            value={value}
            onChange={(evt) => {

                changeValue(evt.target.value)

            }} />

        <button onClick={() => {
            store.boardUI.editedSticker = undefined;
            props.sticker.Content = value
            controller.socketController.updateShape(props.sticker)
            // controller.setStickerContent(props.sticker.id || 0, value);
        }}><Trans>Save</Trans></button>
    </div>
}

const VideoSticker: React.FC<{ sticker: StickerShape }> = observer((props) => {
    function getId(url: string) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return (match && match[2].length === 11)
            ? match[2]
            : null;
    }
    const videoId = getId(props.sticker.Content)
    const opts = {
        height: (props.sticker.Height - 30).toString(),
        width: (props.sticker.Width - 5).toString(),
    };

    if (videoId)
        return <YouTube videoId={videoId} opts={opts} />
    else
        return <div><Trans id="uriNotValie">Not valid YouTube URL:  <b>{props.sticker.Content}</b></Trans></div>
})


export const StickerVideo: React.FC<{ sticker: StickerShape }> = (props) => {
    if (store.boardUI.editedSticker === props.sticker)
        return <VideoStickerEditor sticker={props.sticker} />
    else {
        return <VideoSticker sticker={props.sticker} />
    }


}