import * as d3 from "d3";
import { inv, multiply } from 'mathjs';
import { AffineTransform, matrix2css } from "../math/AffineTransform";
import { Rect } from "../math/Rect";
import { store } from "../store/store";
import { LayerBase } from "./LayerBase";
import { Shape, ShapeType, StrokeShape, FigureShape } from "../model/shape";

export class SelectionLayer extends LayerBase {
    updateInputPosition() {
        if (store.board.editedShape) {
            const rc = this.getRect(store.board.editedShape)
            const width = rc.width
            const height = rc.height
            store.boardUI.position.width = width
            store.boardUI.position.height = height
            const m1 = AffineTransform.transform2Matrix(store.board.editedShape.transform)
            const m2 = AffineTransform.transform2Matrix({
                sx: this.transform.k,
                sy: this.transform.k,
                tx: this.transform.x,
                ty: this.transform.y,
                r: 0
            })
            store.boardUI.position.transform = matrix2css(multiply(m2, m1));
        }

    }
    nextCol = 1
    col2id = new Map<string, Shape>()

    rects: [Rect, Shape][] = []

    constructor(parent: HTMLDivElement, rect: Rect) {
        super(parent, rect)
        this.context = this.canvas.getContext('2d', { alpha: true }) as CanvasRenderingContext2D
        d3.select(this.canvas).classed("displayHidden", true)

    }

    getNextColor() {
        if (this.nextCol < 16777215) {
            let r = this.nextCol & 0xff;
            let g = (this.nextCol & 0xff00) >> 8
            let b = (this.nextCol & 0xff0000) >> 16
            this.nextCol += 1
            return { r, g, b }

        }
        else {
            throw new Error("Too many objects in scene for selection  (>16777215) ")
        }


    }

    clear() {
        super.clear()
        this.nextCol = 1
        this.col2id = new Map()
        this.rects = []
    }

    getColorKey(color: { r: number, g: number, b: number }) {
        return `rgb(${color.r},${color.g},${color.b})`
    }

    render(shapes: Shape[]) {
        this.context.save()
        this.shapes = shapes
        this.nextCol = 1
        this.col2id = new Map()
        this.rects = []
        this.context.translate(this.transform.x, this.transform.y);
        this.context.scale(this.transform.k, this.transform.k);

        for (let shape of shapes) {
            if (shape.Type === ShapeType.Stroke) {
                let stroke = shape as StrokeShape
                let col = this.getColorKey(this.getNextColor())
                if (stroke.ID)
                    this.col2id.set(col, stroke)
                this.renderStroke(stroke.PointArray, col, (stroke.Width + 10) / this.transform.k, 1, stroke.Smooth, stroke.Closed)
            }
            if (shape.Type === ShapeType.Figure) {
                let figure = shape as FigureShape
                let col = this.getColorKey(this.getNextColor())

                if (figure.ID)
                    this.col2id.set(col, figure)
                this.renderFigure(figure, col, (figure.StrokeWidth + 10) / this.transform.k)
                //                this.rects.push([this.getRect(shape), shape])
            }

            if ([ShapeType.Text, ShapeType.TextArea, ShapeType.Image, ShapeType.Chart].includes(shape.Type)) {
            //if (["text", "textarea", "image", "chart"].includes(shape.Type)) {
                this.rects.push([this.getRect(shape), shape])

            }
        }

        this.context.restore()
    }



    getShape(pos: { x: number, y: number }): Shape | undefined {

        let posTransform = [pos.x, pos.y]

        for (let rect2shape of this.rects) {
            let [rect, shape] = rect2shape;
            let m = AffineTransform.transform2Matrix(shape.transform)
            m = inv(m)
            let pt = AffineTransform.apply(m, [posTransform])[0]
            if (rect.inside({ x: pt[0], y: pt[1] })) return shape
        }

        let [x,y] = this.transform.apply([pos.x,pos.y])

        let data = this.context.getImageData(x, y, 1, 1).data


        if (data[3] === 255) {
            let key = this.getColorKey({ r: data[0], g: data[1], b: data[2] })
            return this.col2id.get(key)
        }
    }

    // click(x: number, y: number) {
    //     console.log("click!!")

    //     if ([Tool.Select, Tool.TextArea].includes(uiStore.ui.tool)) {
    //         const shape = this.getShape({ x, y })

    //         if (shape) {
    //             store.setEditedShape(shape)
    //             controller.updateSelection()
    //             // store.on("render", ()=> {
    //             //     console.log("###render", (shape as TextArea).value, this.shapes)
    //             //     //this.redraw()
    //             // })
    //             return true
    //         }
    //         else if (store.board.editedShape) {
    //             console.log("clear!!!")
    //             store.clearEditedShape()
    //             controller.updateSelection()
    //             return true
    //         }
    //     }
    //     return false

    // }

    // drag(x: number, y: number) {
    //     if (uiStore.ui.tool === Tool.Erase) {
    //         const shape = this.getShape({ x, y })
    //         if (shape) {
    //             store.removeShape(shape)
    //             controller.updateSelection()
    //             controller.notifyRemoveShape(shape)
    //             undoredo.removeShape(shape)

    //         }


    //     }
    //     return false
    // }

}