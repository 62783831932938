import { Trans } from "@lingui/macro";
import { observable, makeObservable } from "mobx";
import { observer } from "mobx-react";
import React, { createRef, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';

import { RouteComponentProps, withRouter } from "react-router-dom";
import 'react-splitter-layout/lib/index.css';
import styled from "styled-components";
import { ReactComponent as Logo } from '../drawing.svg';
import { store } from "../store/store";
import { uiStore } from "../store/UIState";
import { BoundKonvaBoard } from "./BoundKonvaBoard";
import { StickerComponent } from "./sticker/stickerComponent";
import { controller } from "../controller/controller";




const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',

    }
};





const AbsolutePositionedDiv = styled.div<{ visible: boolean, top: number, left: number }>`
position:absolute;
display:${props => props.visible ? "block" : "none"};
top: ${props => props.top + "px"};
left: ${props => props.left + "px"};
`;

const AutoSelect: React.FC<{ value: string }> = (props) => {
    const ref = useRef<HTMLInputElement>(null)
    useEffect(() => {
        if (ref.current) {
            ref.current.focus()
            ref.current.select()

        }
    })
    return <input style={{ width: "100%" }} ref={ref} value={props.value} />

}

const VCenterDiv = styled.div`
    display : flex;
    flex-direction: column;
    align-items: center;
`

const CopyButton = styled.button`
    font-size : 20px;
    padding : 10px;
    margin : 10px;
`


export const BoardComponent = observer(class BoardComponent extends React.Component {
    private canvasRef = createRef<HTMLDivElement>();
    private outerRef = createRef<HTMLDivElement>();
    private linkRef = createRef<HTMLDivElement>();

    konvaBoard?: BoundKonvaBoard;
    linkVisible = false;
    top = 20;
    left = 20;
    socialTop = 100;
    socialLeft = 100;
    inIframe = false;
    width = 0;
    constructor(props: any) {
        super(props)

        makeObservable(this, {
            linkVisible: observable,
            top: observable,
            left: observable,
            socialTop: observable,
            socialLeft: observable,
            inIframe: observable,
            width: observable
        });

        if (window.location !== window.parent.location) {
            // if (true) {
            this.inIframe = true;
            this.linkVisible = true
            window.setTimeout(() => {
                this.linkVisible = false;
            }, 1500)
        }
    }
    onResize = () => {
        if (this.canvasRef.current && this.outerRef.current && this.linkRef.current) {
            let canvasRect = this.canvasRef.current.getBoundingClientRect()
            this.socialLeft = 55
            this.socialTop = canvasRect.height - 30

        }
    }

    componentDidMount() {
        if (this.canvasRef.current && this.outerRef.current && this.linkRef.current) {
            this.konvaBoard = new BoundKonvaBoard(this.canvasRef.current)

            let canvasRect = this.canvasRef.current.getBoundingClientRect()
            if (this.linkVisible) {
                let linkRect = this.linkRef.current.getBoundingClientRect()
                this.top = 0;
                this.left = (canvasRect.width - linkRect.width) * 5 / 6;
            }
            this.socialLeft = 55
            this.socialTop = canvasRect.height - 30

            window.addEventListener('resize', this.onResize)
            this.width = this.outerRef.current.getBoundingClientRect().width
        }
    }

    onLoadLink() {
    }


    render() {

        return (
            <div ref={this.outerRef} className="outer" style={{ width: "100%", height: "100%", backgroundColor: "white", overflow: "hidden", position: "relative", left: "0px" }}  >

                <Modal
                    show={uiStore.ui.showShareDlg}
                    onHide={() => { uiStore.ui.showShareDlg = false }}
                    style={customStyles}
                    contentLabel="Shareable link"
                >
                    <Modal.Header closeButton>
                        <Modal.Title><Trans>Invite</Trans></Modal.Title>

                    </Modal.Header>
                    <Modal.Body>
                        <VCenterDiv>

                            <Trans>Link to this whiteboard</Trans>

                            <CopyButton onClick={() => { document.execCommand('copy'); uiStore.ui.showShareDlg = false; }}><Trans>Copy</Trans></CopyButton><AutoSelect value={"https://o-whiteboard.com/board/" + store.board.name} />
                        </VCenterDiv>
                    </Modal.Body>
                </Modal>

                <div ref={this.canvasRef} style={{ width: "100%", height: "100%", position: "relative", left: "00px" }} onContextMenu={(evt) => false} />
                
                {store.board.stickers.map((sticker) => {
                    return <StickerComponent key={sticker.ID.toString()} sticker={sticker} />
                })}

                <AbsolutePositionedDiv visible={this.linkVisible} ref={this.linkRef} top={this.top} left={this.left}> <a target="_parent" href="https://o-whiteboard.com"> <Logo /></a></AbsolutePositionedDiv>
                <div style={{ position: 'absolute', opacity: "0.5", top: `${this.socialTop}px`, left: `${this.socialLeft}px` }} className="ya-share2" data-services="collections,vkontakte,facebook"></div>
            </div>

        )
    }
    //  else {
    //     return <div  style={{ width: "200px", height: "100%", backgroundColor: "white", overflow: "hidden", position: "relative", left: "120px", top:"120px", margin:"20px" }}  >
    //         <Alert variant={"primary"}>Connecting</Alert>
    //         </div>
    // }
});


