
import { observable, makeObservable } from "mobx";
import { Shape } from "../model/shape"
import { Message } from "../model/message"
import { User } from "./store";


export const fonts = [
    "Arial",
    "Roboto",
    "Times New Roman",
    "Times",
    "Courier New",
    "Courier",
    "Verdana",
    "Georgia",
    "Comic Sans MS",
    "Arial Black"
]

export enum Tool {
    Pen = 1,
    Marker,
    MultiLine,
    Segment,
    Erase,
    Translate,
    Select,
    Text,
    EditText,
    Image,
    Sticker,
    Play,
    Ellipse,
    Rectangle,
    TextArea,
    Chart,
    StickerVideo,
    Highlight,
    StickerCode
}


export enum DrawType {
    Draw = 0,
    Highlight,
    MultiLine
}



export enum TextDisplayType {
    None = 0, 
    Line,
    Area 

}



export class UIState {
    boards: { boardName: string }[] = [];
    boardUsers: User[] = [];
    editedShape?: Shape;

    ui = {
        videoCallId: undefined as string | undefined,
        newStickerType: 'markdown',
        showChat: false,
        lineActiveButtons: [] as string[],
        tool: Tool.Pen,
        openToolLineIdx: undefined as number | undefined,
        showClearDialog: false,
        showShareDlg: false,
        messages: [] as Message[],


    };



    constructor() {
        makeObservable(this, {
            boards: observable,
            boardUsers: observable,
            editedShape: observable,
            ui: observable
        });
    }
}

export const uiStore = new UIState()