import { ControlledEditor } from '@monaco-editor/react';
import "github-markdown-css";
import 'katex/dist/katex.min.css';
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import Loadable from 'react-loadable';
import "react-mde/lib/styles/css/react-mde-all.css";
import styled from "styled-components";
import { controller } from "../../controller/controller";
import { StickerShape } from "../../model/shape";
import { store } from "../../store/store";
import { Tool, uiStore } from "../../store/UIState";
import { undoredo } from "../../store/undoredo";
//import { MarkdownRender } from "./markdown/MarkdownRenderer";
import "./stickerComponent.css";




const Editor: React.FC<{ definition: string, onChange: (definition: string) => void }> = (props) => {
    const [definition, setDefinition] = useState(props.definition)

    useEffect(()=> {
        setDefinition(props.definition)
    }, [props.definition])

    return <div style={{ flexGrow: 2 }}>
        <ControlledEditor

            value={definition}
            onChange={(ev, value) => {
                if (value) {
                    setDefinition(value)
                    props.onChange(value)
                }

            }}

            language="markdown"
        />
    </div>

}



function Loading(props: any) {
    if (props.isLoading) {
        if (props.timedOut) {
            return <div>Loader timed out!</div>;
        } else if (props.pastDelay) {
            return <div>Loading...</div>;
        } else {
            return null;
        }
    } else if (props.error) {
        return <div>Error! Component failed to load</div>;
    } else {
        return null;
    }
}
const LoadableMarkdownRenderer = Loadable({
    loader: () => import('./markdown/MarkdownRenderer'),
    loading: Loading,
});


const Markdown = styled.div`
    flex-grow:1;
    padding : 10px;
    overflow : auto;
    p {
        text-align:justify;
    }
    input {
        margin-right:5px;
    }
`


const MDEDiv = styled.div`
    display : flex;
    flex-direction:column;
    flex-grow:1;    
    border: 2px solid red;
    .react-mde { 
        flex-grow: 1;
        display:flex;
        flex-direction:column;
    };
    .rde div[class=""] {
        display:flex;
        flex-direction:column;
        flex-grow:1;    
    };
    .mde-textarea-wrapper {
        flex-grow:1;
        display:flex;
        border : 3px;
        border-style:solid;
        flex-direction:column;
    }; 
    .mde-textarea-wrapper textarea{
        flex-grow:1;
    };

    .grip {
        display : none;
    };
    .mde-tabs {
        display:none;
    };
`

const MDEInputDiv = styled.div`
    display : flex;
`




// @observer
// class Editor extends React.Component<{ sticker: StickerShape, height: number }> {
//     editorDiv = createRef<HTMLDivElement>()
//     @observable height = 0
//     @observable width = 0

//     componentDidMount() {
//         if (this.editorDiv.current) {
//             let elem = (d3.select(this.editorDiv.current).select('.mde-text').node() as HTMLTextAreaElement);
//             this.height = (d3.select(this.editorDiv.current).node() as HTMLDivElement).getBoundingClientRect().height
//             this.width = (d3.select(this.editorDiv.current).node() as HTMLDivElement).getBoundingClientRect().width

//             elem.select()
//             elem.focus()
//             undoredo.beginUpdateSticker(this.props.sticker)
//         }
//     }
//     componentWillUnmount() {
//         undoredo.endUpdateSticker(this.props.sticker)

//     }

//     render() {
//         let listCommands = [
//             {
//                 commands: [
//                     commands.headerCommand,
//                     commands.boldCommand,
//                     commands.italicCommand
//                 ]
//             },

//             {
//                 commands: [
//                     commands.orderedListCommand,
//                     commands.unorderedListCommand,
//                     commands.checkedListCommand
//                 ]
//             },
//             {
//                 commands: [
//                     commands.linkCommand,
//                     commands.imageCommand,
//                     commands.quoteCommand
//                 ]
//             }

//         ]



//         return <MDEDiv>
//             <MDEInputDiv><InputGroup>
//                 <InputGroup.Prepend>
//                     <InputGroup.Text>Font size</InputGroup.Text>
//                 </InputGroup.Prepend>
//                 <FormControl placeholder="Font size" aria-label="Font size" value={this.props.sticker.FontSize} type="number"
//                     onChange={(evt) => {
//                         this.props.sticker.FontSize = parseInt(evt.target.value);
//                         controller.socketController.updateShape(this.props.sticker)
//                     }} />
//             </InputGroup>
//                 <InputGroup>
//                     <InputGroup.Prepend>
//                         <InputGroup.Text>Color</InputGroup.Text>
//                     </InputGroup.Prepend>
//                     <div style={{ paddingLeft: 10, display: "flex", alignContent: "center" }}>
//                         <ColorPalette color={this.props.sticker.Color} onColorChange={val => { this.props.sticker.Color = val; controller.socketController.updateShape(this.props.sticker) }} />
//                     </div>
//                 </InputGroup>
//             </MDEInputDiv>
//             <div style={{ flexGrow: 2,  display: "flex", flexDirection : "column" }} ref={this.editorDiv}>

//                 <ReactMde
//                     classes={{
//                         textArea: "rta",
//                         reactMde: "rde",
//                         grip: "g",
//                         preview: "p",
//                         suggestionsDropdown: "sd",
//                         toolbar: 'tb'

//                     }}
//                     minEditorHeight={this.height}

//                     value={this.props.sticker.Content}
//                     commands={listCommands}
//                     onChange={(value) => {
//                         this.props.sticker.Content = value;
//                         controller.socketController.updateShape(this.props.sticker)
//                     }}

//                 />
//             </div>
//         </MDEDiv>

//     }
// }


export const StickerMarkdown: React.FC<{ sticker: StickerShape }> = observer( (props) => {
    if (store.boardUI.editedSticker && store.boardUI.editedSticker === props.sticker) {
        return <Editor definition={props.sticker.Content} onChange={definition=>{
            props.sticker.Content=definition
            controller.socketController.updateSticker(props.sticker)
        }} />
    }
        
    else {
        return <Markdown className="stickerBody" onClick={
        () => {
            if (uiStore.ui.tool !== Tool.Erase) {
                store.boardUI.editedSticker = props.sticker;
            }
            else if (store.userCanErase(props.sticker.OwnerID)) {
                store.removeSticker(props.sticker.ID )
                controller.socketController.removeShape(props.sticker.ID, "sticker");
                undoredo.removeSticker(props.sticker)
            }
        }}> <div className="markdown-body"> <LoadableMarkdownRenderer source={props.sticker.Content} fontSize={props.sticker.FontSize} escapeHtml={true} /></div></Markdown>
    }

})