import React, { useRef, useEffect } from "react"
import { observer } from "mobx-react";
import styled from "styled-components";
import { undoredo } from "../store/undoredo";
import { TextShape } from "../model/shape";


export const InputDiv = observer(styled.input<{ width: number, height: number, transform: string, fontSize: number }>`
    position : absolute;
    left : -1px;
    top: -1px;
    display:'inline-block';
    width : ${props => props.width + 5}px;
    height: ${props => props.height + 5}px;
    /* -webkit-transform: ${props => props.transform}; */
    transform: ${props => props.transform};
    /* transform: translate(100px,100px); */
    margin :0;
    padding : 0;
    /* overflow : hidden; */
    outline:0;
    background:white;
    z-index:30;
    line-height: normal;
    font-size : ${props => props.fontSize}px;
    border : solid;
    border-color:gray;
    border-width:1px;
    transform-origin:0% 0%;
    caret-color: gray;
    -webkit-appearance: none;
    border-radius: 0;
    box-sizing:border-box;
`)


export const LineEdit: React.FC<{
    value: string,
    onChange: (value: string) => void,
    width: number,
    height: number,
    transform: string,
    fontSize: number,
    shape: TextShape
}> = observer(((props) => {
    const ref = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
            ref.current.select()
            undoredo.beginUpdateShape(props.shape)
        }
        return () => {
            undoredo.endUpdateShape(props.shape)


        }

    }, [ref, props.shape])

    return <InputDiv ref={ref}
        id="input-line"
        autoCapitalize={"off"}
        spellCheck={false}

        value={props.value}
        onChange={(evt) => props.onChange(evt.target.value)}
        fontSize={props.fontSize} width={props.width} height={props.height} transform={props.transform} />
}))
