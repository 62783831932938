import { Rect } from "../math/Rect";
import { ImageShape, Transform, ShapeType } from "./shape";
import { logger } from "../logging/logger";

export class WImage implements ImageShape {
    // userId: number
    OwnerID : Long.Long
    ID: Long.Long 
    Loaded  = false
    // timestamp: Date
    transform: Transform
    Src: string
    Type : ShapeType.Image
    htmlImg?: HTMLImageElement;
    constructor(img: ImageShape, htmlImg? : HTMLImageElement) {
        this.ID = img.ID
        this.OwnerID = img.OwnerID
        // this.userId = img.userId
        // this.timestamp = img.timestamp
        this.Src = img.Src
        this.Type = ShapeType.Image
        this.transform = img.transform
        this.htmlImg = htmlImg
        if (htmlImg) this.Loaded = true
        

    }

    async load() {
        return new Promise((resolve, reject) => {
            this.htmlImg = new Image()
            this.htmlImg.onload = () => {
                this.Loaded = true
                resolve(true)
            }
            logger.info({message:"Requesting image file...", data: this.Src})
            this.htmlImg.src = this.Src
        })
    }

    rect() {
        if (this.Loaded && this.htmlImg) {
            return new Rect({ x: 0, y: 0, width: this.htmlImg.width, height: this.htmlImg.height })
        } else {
            return new Rect({ x: 0, y: 0, width: 0, height: 0 })
        }
    }



}