import * as d3 from "d3";
import { WImage } from "../model/WImage";
import { AffineTransform, applyTransform } from "../math/AffineTransform";
import { Rect } from "../math/Rect";
import { WChart } from "../helpers/WChart";
import { Shape, FigureShape, FigureShapeType, TextShape, ShapeType, TextAreaShape, StickerShape, ChartShape, StrokeShape } from "../model/shape";
import { logger } from "../logging/logger";


function transpose(matrix: number[][]) {
    return matrix[0].map((col, i) => matrix.map(row => row[i]));
}


export class LayerBase {
    canvas: HTMLCanvasElement
    context: CanvasRenderingContext2D;
    curve: d3.CurveGenerator;
    linear: d3.CurveGenerator;
    hasGrid = false
    transform = d3.zoomIdentity
    curveClosed: d3.CurveGenerator;
    linearClosed: d3.CurveGenerator;
    shapes: Shape[] = []

    constructor(parent: HTMLDivElement, public rect: Rect) {
        this.canvas = d3.select(parent).append("canvas")
            .attr("width", rect.width)
            .attr("height", rect.height)
            .style("position", "absolute")
            .style('top', `0px`)
            .style("left", `0px`)
            .node() as HTMLCanvasElement
        this.context = this.canvas.getContext('2d') as CanvasRenderingContext2D
        if (!this.context) { throw new Error("Error creating 2d context for HTML canvas") }
        this.curve = d3.curveCatmullRom(this.context)
        this.curveClosed = d3.curveCatmullRomClosed(this.context)
        this.linear = d3.curveLinear(this.context)
        this.linearClosed = d3.curveLinearClosed(this.context)
    }

    drawGrid() {
        this.context.save()
        this.hasGrid = true
        let x0 = this.transform.invertX(0)
        let y0 = this.transform.invertY(0)
        let x1 = this.transform.invertX(this.rect.width)
        let y1 = this.transform.invertY(this.rect.height)
        this.context.translate(this.transform.x, this.transform.y);
        this.context.scale(this.transform.k, this.transform.k);

        this.context.fillStyle = 'white'
        //this.context.fillRect(x0, y0, x1-x0, y1-y0)
        this.context.beginPath()
        let d = 20
        let D = 200

        this.context.strokeStyle = 'blue'
        this.context.globalAlpha = 0.1



        const addHLines = (p: number, w: number) => {
            if ((y1 - y0) * 3 / p < 300) {
                for (let i = Math.floor(y0 / p); i <= Math.ceil(y1 / p); ++i) {
                    this.context.moveTo(x0, i * p);
                    this.context.lineTo(x1, i * p);
                }

            }
        }

        const addVLines = (p: number, w: number) => {
            if ((x1 - x0) * 3 / p < 300) {
                for (let i = Math.floor(x0 / p); i <= Math.ceil(x1 / p); ++i) {

                    this.context.moveTo(i * p, y0)
                    this.context.lineTo(i * p, y1)
                }

            }

        }

        this.context.lineWidth = 1
        addHLines(d, 1)
        addVLines(d, 1)
        this.context.stroke()

        this.context.beginPath()
        this.context.lineWidth = 4
        addHLines(D, 2)

        addVLines(D, 2)
        this.context.stroke()

        //this.context.resetTransform()
        this.context.restore()

    }
    remove() {
        d3.select(this.canvas).remove()
    }
    clear() {
        this.context.clearRect(0, 0, this.rect.width, this.rect.height)

        if (this.hasGrid)
            this.drawGrid()
    }
    click(x: number, y: number) {
        return false
    }



    renderStroke(points: number[][], color: string, width: number, opacity: number, smooth: boolean, closed: boolean) {
        let line = smooth ? this.curve : this.linear
        if (closed) {
            line = smooth ? this.curveClosed : this.linearClosed
        }
        this.context.lineWidth = width
        this.context.lineJoin = "round"
        this.context.strokeStyle = color
        this.context.globalAlpha = opacity
        this.context.beginPath()
        this.context.fillStyle = color

        if (points.length === 1) {
            this.context.ellipse(points[0][0], points[0][1], width / 1.5, width / 1.5, 0, 0, 360)

            this.context.fill()
        } else {

            line.lineStart()
            for (const point of points) {
                line.point(point[0], point[1])
            }

            line.lineEnd()
            if (closed)
                this.context.fill()
            else
                this.context.stroke()

        }

    }


    renderFigure(figure: FigureShape, col: string | undefined = undefined, lineWidth: number | undefined = undefined) {
        if (figure.ShapeType === FigureShapeType.Ellipse) {
            this.context.save()
            //this.context.translate(figure.Transform.Tx, figure.Transform.Ty)
            this.context.lineWidth = lineWidth || figure.StrokeWidth
            this.context.strokeStyle = col || figure.Color
            this.context.globalAlpha = figure.Opacity

            this.context.beginPath()

            const x0 = figure.transform.tx
            const x1 = figure.transform.tx + figure.Width;

            const y0 = figure.transform.ty
            const y1 = figure.transform.ty + figure.Height;
            const cx = (x0 + x1) / 2
            const cy = (y0 + y1) / 2
            const width = Math.abs(figure.Width) / 2
            const height = Math.abs(figure.Height) / 2




            this.context.ellipse(cx, cy, width, height, 0, 0, 360)
            this.context.stroke()



            this.context.restore()
        }

        if (figure.ShapeType === FigureShapeType.Rectangle) {
            this.context.save()
            //this.context.translate(figure.Transform.Tx, figure.Transform.Ty)
            this.context.lineWidth = lineWidth || figure.StrokeWidth
            this.context.strokeStyle = col || figure.Color

            this.context.globalAlpha = figure.Opacity

            this.context.beginPath()

            const x0 = figure.transform.tx
            const x1 = figure.transform.tx + figure.Width;

            const y0 = figure.transform.ty
            const y1 = figure.transform.ty + figure.Height;
            const width = Math.abs(figure.Width)
            const height = Math.abs(figure.Height)

            this.context.rect(Math.min(x0, x1), Math.min(y0, y1), width, height)
            this.context.stroke()



            this.context.restore()
        }

    }

    renderChart(chart: WChart) {
        this.context.save()
        applyTransform(this.context, chart.transform)


        if (chart.plotValue && (chart.plotValue as number[]).length) {
            const yAxis = () => {
                var tickCount = 10,
                    tickSize = 6,
                    tickPadding = 3,
                    ticks = y.ticks(tickCount),
                    tickFormat = y.tickFormat(tickCount);

                this.context.beginPath();
                ticks.forEach((d) => {
                    this.context.moveTo(0, y(d) as number);
                    this.context.lineTo(-6, y(d)as number);
                });
                this.context.strokeStyle = "black";
                this.context.stroke();

                this.context.beginPath();
                this.context.moveTo(-tickSize, 0);
                this.context.lineTo(0.5, 0);
                this.context.lineTo(0.5, height);
                this.context.lineTo(-tickSize, height);
                this.context.strokeStyle = "black";
                this.context.stroke();

                this.context.textAlign = "right";
                this.context.textBaseline = "middle";
                ticks.forEach((d) => {
                    this.context.fillText(tickFormat(d), -tickSize - tickPadding, y(d) as number);
                });
            }

            const xAxis = () => {
                var tickCount = 10,
                    tickSize = 6,
                    ticks = x.ticks(tickCount),
                    tickFormat = x.tickFormat();

                this.context.beginPath();
                ticks.forEach((d) => {
                    this.context.moveTo(x(d) as number, height);
                    this.context.lineTo(x(d)as number, height + tickSize);
                });
                this.context.strokeStyle = "black";
                this.context.stroke();

                this.context.textAlign = "center";
                this.context.textBaseline = "top";
                ticks.forEach((d) => {
                    this.context.fillText(tickFormat(d), x(d) as number, height + tickSize);
                });

                this.context.beginPath();
                this.context.moveTo(0, height + tickSize);
                this.context.lineTo(0, height);
                this.context.lineTo(width, height);
                this.context.lineTo(width, height + tickSize);
                this.context.strokeStyle = "black";
                this.context.stroke();

            }

            const nelem = (chart.plotValue as number[]).length
            let xdata: number[];
            if (chart.plotArgs && (chart.plotArgs as number[]).length)
                xdata = chart.plotArgs as number[]
            else
                xdata = [...Array(nelem).keys()]


            const margin = { top: 5, right: 5, bottom: 5, left: 5 },
                width = chart.Width - margin.left - margin.right,
                height = chart.Height - margin.top - margin.bottom;




            const x = d3.scaleLinear()
                .range([0, width]);
            const y = d3.scaleLinear()
                .range([height, 0]);

            x.domain(d3.extent(xdata) as [number, number])
            y.domain(d3.extent(chart.plotValue as number[]) as [number, number])

            const line = d3.line()
                .x(function (d) { return x(d[0])as number; })
                .y(function (d) { return y(d[1])as number; })
                .curve(d3.curveLinear)
                .context(this.context);
            this.context.translate(margin.left, margin.top);


            const matrix = transpose([xdata, chart.plotValue as number[]])

            this.context.beginPath()
            line(matrix as [number, number][])
            this.context.lineWidth = 2.5;
            this.context.strokeStyle = "steelblue";
            this.context.stroke()
            xAxis()
            yAxis()




        } else {
            this.context.beginPath()
            this.context.moveTo(0, 0)
            this.context.lineTo(chart.Width, chart.Height)
            this.context.moveTo(0, chart.Height)
            this.context.lineTo(chart.Width, 0)
            this.context.strokeStyle = "red"
            this.context.lineWidth = 2
            this.context.stroke()

        }
        this.context.restore()
    }


    renderImage(image: WImage) {

        if (image.Loaded) {


            this.context.save()

            this.context.translate(image.transform.tx, image.transform.ty)

            //this.context.translate(image.img.Width / 2, image.img.Height / 2)
            this.context.rotate(image.transform.r)
            this.context.scale(image.transform.sx, image.transform.sy)
            this.context.drawImage(image.htmlImg as HTMLImageElement, 0, 0)
            this.context.restore()
        }
    }


    renderText(text: TextShape) {
        this.context.save()
        this.context.globalAlpha = 1


        const font = `${text.Size}px ${text.Family}`
        this.context.font = font;

        let measure = this.context.measureText(text.Content)

        applyTransform(this.context, text.transform)
        let value = text.Content;
        this.context.translate(0, measure.actualBoundingBoxAscent)

        this.context.font = font;

        this.context.fillStyle = text.Color
        this.context.fillText(value, 0, 0)

        this.context.restore()
    }


    getRect(shape: Shape): Rect {
        switch (shape.Type) {

            case ShapeType.Text:
                {
                    const text = shape as TextShape
                    this.context.font = `${text.Size}px ${text.Family}`
                    let mes = this.context.measureText(text.Content)
                    let rect = new Rect({ x: 0, y: 0, width: mes.width, height: mes.actualBoundingBoxAscent + mes.actualBoundingBoxDescent })
                    if (rect.width < 20) rect.width = 20
                    if (rect.height < 10) rect.height = 10

                    return rect
                }
            case ShapeType.Image:
                {
                    const image = shape as WImage
                    return image.rect()
                }
            case ShapeType.TextArea:
                {
                    const textarea = shape as TextAreaShape
                    return new Rect({ x: 0, y: 0, width: textarea.Width, height: textarea.Height })

                }
            case ShapeType.Sticker:
                {
                    const sticker = shape as StickerShape
                    return new Rect({ x: 0, y: 0, width: sticker.Width, height: sticker.Height })

                }

            case ShapeType.Chart:
                {
                    const chart = shape as ChartShape
                    return new Rect({ x: 0, y: 0, width: chart.Width, height: chart.Height })

                }

            case ShapeType.Figure:
                {
                    const figure = shape as FigureShape
                    return new Rect({ x: 0, y: 0, width: figure.Width, height: figure.Height })
                }
            default:
                return new Rect({ x: 0, y: 0, width: 0, height: 0 })

        }
    }

    getClientTextPoints(text: TextShape) {
        let rect = this.getRect(text)
        const m = new AffineTransform(text.transform).getRectTransformMatrix(rect.width, rect.height)
        let points = AffineTransform.apply(m, rect.points())
        points = points.map(p => this.transform.apply(p))
        return points
    }
    getClientTextRect(text: TextShape) {
        let rect = this.getRect(text)
        let p0 = this.transform.apply([rect.x, rect.y])
        let p1 = this.transform.apply([rect.x + rect.width, rect.y + rect.height])
        return new Rect({ x: p0[0] + this.rect.x, y: p0[1] + this.rect.y, width: p1[0] - p0[0], height: p1[1] - p0[1] })
    }

    renderTextArea(ta: TextAreaShape) {
        this.context.save()
        this.context.beginPath()
        const x0 = ta.transform.tx
        const x1 = ta.transform.tx + ta.Width;
        const y0 = ta.transform.ty
        const y1 = ta.transform.ty + ta.Height;
        const width = Math.abs(ta.Width)
        // const height = Math.abs(ta.Height)
        let xs = Math.min(x0, x1)
        let ys = Math.min(y0, y1)

        this.context.translate(xs, ys)
        this.context.rotate(ta.transform.r)

        this.context.fillStyle = ta.Color

        // this.context.rect(0, 0, width, height)
        // this.context.stroke()

        this.context.font = `${ta.Size}px ${ta.Family}`
        const lines = ta.Content.split('\n')
        xs = 0; ys = 0;

        for (let line of lines) {
            const m1 = this.context.measureText(line)
            let xc = xs
            for (let word of line.split(' ')) {
                const m = this.context.measureText(word)
                if (xc + m.width > xs + width) {
                    xc = xs
                    ys += m1.actualBoundingBoxAscent + m1.actualBoundingBoxDescent + 5;
                }
                this.context.fillText(word, xc, ys + m1.actualBoundingBoxAscent)
                xc += m.width + 5

            }


            ys += m1.actualBoundingBoxAscent + m1.actualBoundingBoxDescent + 5;


            // let words = line.split(' ')
            // for (let word of words) {
            //     const m = this.context.measureText(word)
            // }
        }
        this.context.restore()

    }



    render(shapes: Shape[]) {
        this.shapes = shapes
        for (let shape of shapes) {
            this.renderShape(shape)
        }
        // this.context.resetTransform()
    }

    renderShape(shape: Shape) {
        // logger.info({message: "LayerBase::renderShape", data : shape})
        this.context.save()
        this.context.translate(this.transform.x, this.transform.y);
        this.context.scale(this.transform.k, this.transform.k);
        if (shape.Type === ShapeType.Stroke || shape.Type === ShapeType.Highlight) {
            const stroke = shape as StrokeShape
            this.renderStroke(stroke.PointArray, stroke.Color, stroke.Width, stroke.Opacity, stroke.Smooth, stroke.Closed)
        } else if (shape.Type === ShapeType.Image) {
            const image = shape as WImage
            this.renderImage(image)
        }
        else if (shape.Type === ShapeType.Text) {
            const text = shape as TextShape
            this.renderText(text)
        } else if (shape.Type === ShapeType.Figure) {
            const figure = shape as FigureShape
            this.renderFigure(figure)
        } else if (shape.Type === ShapeType.TextArea) {
            const ta = shape as TextAreaShape
            this.renderTextArea(ta)
        } else if (shape.Type === ShapeType.Chart) {
            const chart = shape as WChart
            this.renderChart(chart)
        }
        this.context.restore()

    }

    redraw() {
        this.clear()
        this.render(this.shapes)
    }

    dragEnd(x: number, y: number) { return false; }
    dragRender() { return false }
    drag(x: number, y: number) { return false; }
    dragStart(x: number, y: number) { return false; }

}